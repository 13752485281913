import axios from "axios";

const generateAndSaveInvoice = async (invoice) => {
  const { orderNumber, date, totalAmount } = invoice;
  const { data } = await axios.post(
    "https://api.cloutin.co/api/invoiceGeneration",
    {
      orderNumber,
      date,
      totalAmount,
    }
  );
  return data;
};

export default generateAndSaveInvoice;
