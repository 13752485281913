import React from "react";
import collabb from "../imgs/collabb.png";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <footer className="bg-[#052030] text-white py-12 px-4 md:px-12">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col items-center md:items-start">
            <div className="flex items-center">
              <img src={collabb} alt="collabb-logo" className="w-8 h-8 mr-2" />
              <h1 className="text-xl font-bold">Cloutin</h1>
            </div>
            <div className="flex flex-col md:flex-row gap-8 mt-4">
              <div className="company">
                <h3 className="font-semibold">Company</h3>
                <ul className="list-none">
                  <li>
                    <Link to="/about" className="hover:text-gray-300">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="hover:text-gray-300">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="legal">
                <h3 className="font-semibold">Legal</h3>
                <ul className="list-none">
                  <li>
                    <button
                      onClick={() => {
                        navigate("/terms-conditions/cloutin");
                      }}
                      className="hover:text-gray-300"
                    >
                      Terms & Conditions
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        navigate("/privacy-policy-cloutin");
                      }}
                      className="hover:text-gray-300"
                    >
                      Privacy Policy
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center md:items-start mt-8 md:mt-0">
            <div className="flex gap-4">
              <FaLinkedin className="text-2xl" />
              <FaInstagram className="text-2xl" />
              <FaFacebook className="text-2xl" />
            </div>
            <div className="flex flex-col md:flex-row mt-4">
              <input
                type="email"
                placeholder="cloutin@address.com"
                className="bg-gray-200 text-blue-500 rounded-md py-2 px-4 mb-2 md:mb-0"
              />
              <button className="bg-white text-[#0082CC] font-semibold py-2 px-6 rounded-md md:ml-4">
                Get Started
              </button>
            </div>
            <div className="flex flex-col mt-4 md:flex-row">
              <span className="mr-4">Get on Play Store</span>
              <span>Publishing Soon</span>
            </div>
            <p className="text-sm mt-4">
              © All rights are reserved by Cloutin Ltd. 2023
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
