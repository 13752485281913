import React, { useEffect, useState } from "react";
import Profile from "./Profile";
import { getAllMarketPlaceData } from "./Api";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import { useSelector } from "react-redux";
import OverlayDisableAccountPage from "../../Pages/OverlayDisableAccountPage";
import "./profile.css";
import {
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import { HiOutlineArrowsUpDown } from "react-icons/hi2";
import { HiSearch } from "react-icons/hi";
import { categories } from "../../constants/data";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";

function Marketplace() {
  const [openModal, setOpenModal] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchUserName, setSearchUserName] = useState("");
  const [loading, setLoading] = useState(true);
  const [platformType, setPlatformType] = useState({
    value: "all",
    label: "All Platforms",
  });
  const [toggleAscDesc, setToggleAscDesc] = useState(true);

  const navigate = useNavigate();
  const [link, setLink] = useState("");
  const [link2, setLink2] = useState("");

  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  const [openModalss, setOpenModalss] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllMarketPlaceData((fetchedData) => {
      setData(fetchedData);
      setFilteredData(fetchedData);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (userAccountStatus) {
      setOpenModalss(true);
    }
  }, [userAccountStatus]);

  const handleModelAction = () => {
    navigate(link);
  };

  const handleModelAction2 = () => {
    navigate(link2);
  };

  const handleSearchInfluencers = () => {
    let result = data;

    if (searchUserName) {
      result = result?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchUserName?.toLowerCase())
      );
    }

    if (selectedCategory && selectedCategory?.value !== "All Audience") {
      result = result?.filter((item) =>
        item?.categories?.includes(selectedCategory?.value)
      );
    }

    if (platformType.value !== "all") {
      result = result.filter((item) => {
        if (platformType.value === "instagram") return item.instaengagement > 0;
        if (platformType.value === "youtube") return item.ytengagement > 0;
        return true;
      });
    }

    setFilteredData(result);
  };

  useEffect(() => {
    handleSearchInfluencers();
  }, [searchUserName, selectedCategory, platformType]);

  const handleRatingSort = () => {
    setToggleAscDesc(!toggleAscDesc);
    setFilteredData((prev) =>
      [...prev].sort((a, b) =>
        toggleAscDesc
          ? a.overallRating - b.overallRating
          : b.overallRating - a.overallRating
      )
    );
  };

  let categoryOptions = [];
  categoryOptions.push({ value: "All Audience", label: "All Audience" });
  categoryOptions.push(
    ...categories.map((cat) => ({ value: cat, label: cat }))
  );
  const platformOptions = [
    { value: "all", label: "All Platforms" },
    { value: "instagram", label: "Instagram" },
    { value: "youtube", label: "YouTube" },
  ];

  const onResetFilter = () => {
    setSearchUserName("");
    setSelectedCategory(null);
    setPlatformType({ value: "all", label: "All Platforms" });
    setFilteredData(data);
  };

  return (
    <div className="h-auto max-w-7xl mx-auto px-4">
      <div className="flex flex-wrap items-center mt-4 gap-2">
        <div className="flex-grow min-w-[100px]">
          <div className="relative max-w-[300px]">
            <input
              type="search"
              className="w-full p-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded-full bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
              placeholder="Search Influencers..."
              value={searchUserName}
              onChange={(e) => setSearchUserName(e.target.value)}
            />
            <HiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>
        <div
          className={`w-52 ${filteredData.length === 0 && !loading && "z-50"}`}
        >
          <Select
            options={categoryOptions}
            value={selectedCategory}
            onChange={setSelectedCategory}
            placeholder="Select Category"
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
        <div className="w-52">
          <Select
            options={platformOptions}
            value={platformType}
            onChange={setPlatformType}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm font-bold text-gray-700">
            Sort by Rating
          </span>
          <HiOutlineArrowsUpDown
            className="text-xl text-gray-500 cursor-pointer"
            onClick={handleRatingSort}
          />
        </div>
      </div>

      {
        //reset filter if filtered data does not exist
        filteredData.length === 0 && !loading && (
          <div className="flex flex-col items-center justify-center h-[400px]   relative overflow-hidden mt-[4rem]">
            <div className="absolute inset-0 opacity-5">
              <svg
                className="w-full h-full"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="40" fill="#4A90E2" />
                <path d="M50 10 L90 90 L10 90 Z" fill="#F5A623" />
                <rect x="20" y="20" width="60" height="60" fill="#7ED321" />
              </svg>
            </div>

            {/* Content */}
            <div className="text-center z-10">
              <FaSearch className="w-16 h-16 text-gray-400 mb-4 mx-auto" />
              <h1 className="text-2xl font-bold text-gray-700 mb-2">
                No Influencers Found
              </h1>
              <p className="text-gray-500 mb-4">
                We couldn't find any influencers matching your criteria.
              </p>
              <button
                onClick={onResetFilter}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ease-in-out"
              >
                Reset Filter
              </button>
            </div>
          </div>
        )
      }

      <div className="grid md:mt-[40px] mt-[20px] flex-col xl:grid-cols-4 lg:grid-cols-3 lg:gap-8 md:grid-cols-2 md:gap-8 sm:grid-cols-2 gap-6 8k:grid-cols-5 8k:gap-6 4k:grid-cols-4 4k:gap-8 2xl:grid-cols-3 justify-center items-center">
        {loading &&
          Array.from({ length: 20 }).map((_, index) => (
            <div key={index} className="profile-container shimmer-container">
              <ShimmerThumbnail height={150} />
              <ShimmerTitle line={1} gap={10} />
              <div className="shimmer-engagement">
                <ShimmerText line={1} gap={10} />
                <ShimmerText line={1} gap={10} />
              </div>
              <div className="shimmer-rating">
                <ShimmerText line={1} gap={10} />
                <ShimmerText line={1} gap={10} />
              </div>
            </div>
          ))}

        {!loading &&
          filteredData.map((item) => {
            const hasEngagementRates =
              item?.instaengagement > 0 || item?.ytengagement > 0;
            return (
              <div
                key={item?._id}
                className={`${
                  hasEngagementRates ? "" : "hidden"
                } profilecardMarketplace`}
              >
                {hasEngagementRates && (
                  <Profile
                    image={
                      item?.profile_image_url ||
                      "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                    }
                    packageImage={
                      item?.package_image_url ||
                      "/Images/marketplace_influencer.png"
                    }
                    id={item?._id}
                    name={item?.name}
                    categories={item?.categories}
                    engagement_rate={item?.instaengagement}
                    overall_ratings={item?.overallRating}
                    igUserName={item?.name}
                    yt_engagement_rate={item?.ytengagement}
                    setOpenModal={setOpenModal}
                    setLink={setLink}
                    setLink2={setLink2}
                    handleModelAction={handleModelAction}
                    followers={item?.followers}
                    ytfollowers={item?.ytfollowers}
                    lowestPacakgePrice={item?.lowestPackagePrice}
                  />
                )}
                <Modal
                  title="Select Platforms"
                  message="Please select platform for platform wise analytics of this influencer"
                  dangerOption="Instagram"
                  dangerOption2="Youtube"
                  cancelOption="Cancel"
                  dangerAction={handleModelAction}
                  dangerAction2={handleModelAction2}
                  cancelAction={() => setOpenModal(null)}
                  showModal={openModal === item._id}
                />
              </div>
            );
          })}
      </div>
      {!loading && userAccountStatus && (
        <OverlayDisableAccountPage isOpen={openModalss} />
      )}
    </div>
  );
}

export default Marketplace;
