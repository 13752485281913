import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "./redux/Store/store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "react-oauth-google";
import { PersistGate } from "redux-persist/integration/react";
import "./setupTests.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Toaster position="bottom-center" reverseOrder={false} />
      <BrowserRouter>
        <GoogleOAuthProvider clientId="1052522319901-mqq8ekmtdvptehi8039bb9pp11og7kgh.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);