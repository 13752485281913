// import React from "react";
// import Card from "./Card";

// function Integration() {
//   return (
//     <div className="grid 2xl:grid-cols-5 md:mt-[40px] md:items-start xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 md:grid-cols-3 gap-3 overflow-y-scroll h-auto 4k:grid-cols-4 8k:grid-cols-5">
//       <Card image={"shopify.png"} title={"Shopify Store"} />
//     </div>
//   );
// }

// export default Integration;

import React from "react";
import Card from "./Card";

function Integration() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 md:mt-4k overflow-x-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
        <Card image={"shopify.png"} title={"Shopify Store"} />
        {/* Add more Card components here for additional integrations */}
      </div>
    </div>
  );
}

export default Integration;

