import axios from "axios";
import React, { useState } from "react";
// import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { initializeApp } from "firebase/app";
// import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Cookies from "js-cookie";


function GoogleReg() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [categories, setCategories] = useState("");
  const [pic, setPic] = useState("");
  const [city, setCity] = useState("");
  const [searchParams] = useSearchParams();

  const registerUser = async (e) => {
    try {
      const { data } = await axios.post(
        "https://api.cloutin.co/api/influencerAuth/googleAuth",
        {
          accessToken: searchParams.get("accessToken"),
          flag: 0,
          name,
          phone,
          city,
          categories,
          profile_image_url: pic,
        }
      );
    
      setName("");
      setCategories("");
      setPhone("");
      setCity("");
      setPic("");
      Cookies.set("username", data.username, { expires: 30, path: "/" });
      Cookies.set("name", data.name, { expires: 30, path: "/" });
      Cookies.set("token", data.token, { expires: 30, path: "/" });
      localStorage.setItem("token", data.token);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

 
  return (
    <div className="flex lg:h-screen h-[calc(100vh-100px)]  mobile:h-fit  ">
      <div
        className="bg-blue-500 lg:flex hidden items-center justify-center basis-[50%] "
        onClick={() => {
          // signInWithPopup(auth, provider).then((data) => {
          //   console.log(data.user.email);
          // });
        }}
      >
        <img
          className=" 4k:w-[90%] 4k:h-[50%] "
          src={"/Images/register.png"}
          alt="logo"
        />
      </div>

      <div className="flex items-center bg-[#F4F4F4] justify-center basis-[100%] lg:basis-[50%] ">
        <div>
          <form className="xl:w-[500px] mx-4 border md:m-8 lg:w-[450px] m-10  md:w-[550px] space-y-[10px] text-center bg-white p-[20px] 4k:w-[70%] 4k:h-[75%]">
            <h1 className="font-bold 4k:text-3xl 1440k:text-3xl">
              Sign up for an account
            </h1>
            <p className="text-[#cbcdcc] 4k:text-2xl 4k:pt-1 4k:pb-1 1440k:text-xl">
              Get latest news update instantly{" "}
            </p>
            {/* <div className="space-y-[20px]"> */}
            <input
              className="bg-[#F4F4F4] w-full hover:border hover:border-black focus:outline-none focus:border focus:border-black rounded-lg  p-3 lg:h-[40px] 4k:text-2xl 4k:h-[4vh] 1440k:text-xl 1440k:h-[5vh]"
              type="text"
              placeholder="Name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="bg-[#F4F4F4] w-full hover:border hover:border-black focus:outline-none focus:border focus:border-black rounded-lg  p-3 lg:h-[40px] 4k:text-2xl 4k:h-[4vh] 1440k:text-xl 1440k:h-[5vh]"
              type="text"
              placeholder="Categories"
              value={categories}
              required
              onChange={(e) => setCategories(e.target.value)}
            />
            <input
              className="bg-[#F4F4F4] w-full hover:border hover:border-black focus:outline-none focus:border focus:border-black rounded-lg  p-3 lg:h-[40px] 4k:text-2xl 4k:h-[4vh] 1440k:text-xl 1440k:h-[5vh]"
              type="number"
              placeholder="+91"
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              className="bg-[#F4F4F4] w-full hover:border hover:border-black focus:outline-none focus:border focus:border-black rounded-lg  p-3 lg:h-[40px] 4k:text-2xl 4k:h-[4vh] 1440k:text-xl 1440k:h-[5vh]"
              type="text"
              placeholder="city"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            />
            <input
              className="bg-[#F4F4F4] w-full hover:border hover:border-black focus:outline-none focus:border focus:border-black rounded-lg  p-3 lg:h-[40px] 4k:text-2xl 4k:h-[4vh] 1440k:text-xl 1440k:h-[5vh]"
              type="text"
              placeholder="profile image url"
              value={pic}
              required
              onChange={(e) => setPic(e.target.value)}
            />
            {/* </div> */}
            <p className="mobile:text-xs 4k:text-xl 4k:pt-2 4k:pb-4 1440k:text-base 1440k:py-5">
              By creating an account. you agreeing to our{" "}
              <span className="font-bold">Privacy Policy</span>, and{" "}
              <span className="font-bold">
                Electronics Communication Policy
              </span>
            </p>

            <input
              value="Sign Up with Google"
              className="bg-blue-500 text-center w-full cursor-pointer rounded-3xl font-bold text-white p-3 4k:text-2xl 4k:h-[4vh] 1440k:h-[6vh] 1440k:text-2xl"
              onClick={registerUser}
            />

            <p className="text-[#cbcdcc] 4k:text-2xl 1440k:text-xl">
              Already have an account?{" "}
              <span
                className="font-bold cursor-pointer text-black"
                onClick={() => navigate("/login")}
              >
                Sign In with google
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default GoogleReg;
