import React from 'react';

const WalletShimmer = () => {
  return (
    <div className="p-4">
      {/* Top Horizontal Box */}
      <div className="animate-pulse bg-gray-200 h-24 mb-4 w-[50vw]"></div>

      {/* Two Horizontal Boxes */}
      <div className="flex space-x-4 mb-4">
        <div className="animate-pulse bg-gray-200 h-24 w-1/2"></div>
        <div className="flex flex-col space-y-4 w-1/2">
          <div className="animate-pulse bg-gray-200 h-12 w-full"></div>
          <div className="animate-pulse bg-gray-200 h-12 w-full"></div>
        </div>
      </div>

      {/* Table Shimmer */}
      <div className="animate-pulse bg-gray-200 h-10 mb-2 w-full"></div>
      <div className="animate-pulse bg-gray-200 h-10 mb-2 w-full"></div>
      <div className="animate-pulse bg-gray-200 h-10 mb-2 w-full"></div>
      <div className="animate-pulse bg-gray-200 h-10 mb-2 w-full"></div>
      <div className="animate-pulse bg-gray-200 h-10 mb-2 w-full"></div>
    </div>
  );
};

export default WalletShimmer;
