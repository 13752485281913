import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CodeIcon from "@mui/icons-material/Code";

export default function ConversionCode({ category, script }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(category);
  console.log(script);

  // if (category == "Purchases") {
  // } else {
  //   console.log("false");
  // }

  const trackingCode = `
  ${script}
  `;
  const trackingCode1 = `
    <script>
let scrollEventTriggered = false;
function sendPostRequest(url, body) {
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log("Request sent successfully");
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
function handleClick(event) {
  event.preventDefault();
  const url = event.target.href;
  const body = {
    eventType: "click",
    pageCount: 1,
  };
  sendPostRequest("https://api.cloutin.co/api/lead/click-count", body);
  setTimeout(() => {
    window.location.href = url;
  }, 100);
}
function handleScroll() {
  if (scrollEventTriggered) {
    return;
  }
  const body = {
    eventType: "scroll",
    scrollCount: 1,
  };
  sendPostRequest("https://api.cloutin.co/api/lead/scroll-count");
  scrollEventTriggered = true;
}
document.addEventListener("click", function (event) {
  handleClick(event);
});
window.addEventListener("scroll", handleScroll);
</script>
  `;

  const handleCopy = () => {
    navigator.clipboard.writeText(trackingCode);
    // Optionally, you can provide some feedback to the user after copying
    alert("Tracking code copied to clipboard!");
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        <CodeIcon style={{ marginRight: "8px" }} />
        Get Code
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          width: "800px",
          maxWidth: "1000px",
          //   border: "2px solid black",
          margin: "0 auto",
        }}
      >
        <DialogTitle id="alert-dialog-title">{`Code For ${category}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="relative">
              <button
                className="bg-blue-600 px-3 py-1 text-white absolute right-0"
                onClick={handleCopy}
              >
                Copy Code
              </button>
              <pre>
                <code>{trackingCode}</code>
              </pre>
            </div>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
