import React, { useState } from "react";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from "axios";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    setError(""); // Clearing any previous errors
    setSuccessMessage(""); // Clearing any previous success messages

    try {
      const response = await axios.post(
        "https://api.cloutin.co/api/company/forgotPassword",
        {
          email,
        }
      );

      setSuccessMessage("Check Your Mail");
    
    } catch (error) {
      if (!error?.response) setError(error?.message);
      else setError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="">
        {/* color box starts */}
        <div className=" h-[35vh] bg-primary"></div>
        {/* color box ends */}
        <div className="-mt-24   df text-center">
          <div className="border bg-white border-[#c1c1c1] shadow-xl px-12   pt-10 pb-10">
            <div className="df">
              <LockResetIcon
                style={{ fontSize: "80px", textAlign: "center" }}
              />
            </div>
            <h1 className="text-center font-bold text-xl pt-[10px]">
              Forgot Password
            </h1>
            <p className="text-center py-2">
              Enter your Email and we will send you a link to reset your
              password
            </p>
            <form onSubmit={submitHandler} className="df flex-col gap-6 py-4">
              <TextField
                id="outlined-basic"
                label="Enter Your Mail"
                variant="outlined"
                style={{ width: "100%" }}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error && <p className=" text-red-500 font-bold">{error}</p>}
              {successMessage && <p className=" text-green-500 font-bold">{successMessage}</p>}
              <Button
                style={{ width: "100%", backgroundColor: "green" }}
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </form>

            <div className="pt-4">
              <Link to={"/login"} className="text-center">
                <ArrowBackIosNewIcon style={{ fontSize: "20px" }} /> Back to
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
