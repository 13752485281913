import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function BusinessCampaignSales() {
  const { id6 } = useParams();
  const [salesData, setSalesData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Month");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const fetchSalesData = async () => {
    try {
      let url = `https://api.cloutin.co/api/admin/business/particularBusiness/${id6}`;
      if (selectedOption === "Month") {
        url += `?month=${selectedMonth}&year=${selectedYear}`;
      } else {
        url += `?year=${selectedYear}`;
      }
      const res = await axios.get(url);
      setSalesData(res.data);
    } catch (error) {
      toast.error("No data available");
    }
  };

  useEffect(() => {
    fetchSalesData();
  }, [id6, selectedOption, selectedMonth, selectedYear]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  const formatChartData = () => {
    if (!salesData) return [];

    if (selectedOption === "Year") {
      return salesData.data.map((monthData) => ({
        month: new Date(selectedYear, monthData.month - 1).toLocaleString(
          "default",
          { month: "short" }
        ),
        purchases: monthData.monthlyTotalPurchases,
        likes: monthData.monthlyTotalLikes,
      }));
    } else {
      return salesData.dailyData.map((day) => ({
        date: new Date(day.date).getDate(), // Just show the day number
        purchases: day.purchases,
        likes: day.likes,
      }));
    }
  };

  const chartData = formatChartData();

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold text-gray-800">Sales Generated</h1>
        <div className="flex space-x-4">
          <select
            className="bg-gray-200 rounded-full py-2 px-4 text-sm focus:outline-none"
            value={selectedYear}
            onChange={handleYearChange}
          >
            {[...Array(5)].map((_, i) => (
              <option key={i} value={new Date().getFullYear() - i}>
                {new Date().getFullYear() - i}
              </option>
            ))}
          </select>
          {selectedOption === "Month" && (
            <select
              className="bg-gray-200 rounded-full py-2 px-4 text-sm focus:outline-none"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {[...Array(12)].map((_, i) => (
                <option key={i} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", {
                    month: "long",
                  })}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData}>
          <Line
            type="monotone"
            dataKey="purchases"
            name="Purchases"
            stroke="#8884d8"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="likes"
            name="Likes"
            stroke="#82ca9d"
            strokeWidth={2}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis
            dataKey={selectedOption === "Year" ? "month" : "date"}
            allowDuplicatedCategory={false}
          />
          <YAxis />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BusinessCampaignSales;
