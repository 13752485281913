import * as React from "react";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import BasicModal2 from "./Modal2";
import axios from "axios";
import { io } from "socket.io-client";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBusinessCampaignStatus, selectCampStatus } from "../redux/Slice/AdminSlice";
import { initializeSocket, registerUser, subscribeToNotifications, disconnectSocket } from "../socketManager";
import moment from "moment";

export default function SimplePopup({ userId }) {
  const navigate = useNavigate();

  const location = useLocation();

  const [notifications, setNotifications] = React.useState([]);

  const [count, setCount] = React.useState(0);

  const handleDelete = async (id) => {
  
    try {
      const response = await axios.delete(
        `https://api.cloutin.co/api/notifications/delete/` + id
      );
      setNotifications((currentNotifications) =>
        currentNotifications.filter((notification) => notification._id !== id)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const businessCampaignStatusSelector = useSelector(selectCampStatus);

  React.useEffect(() => {
    initializeSocket();

    registerUser(userId);

    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`https://api.cloutin.co/api/notifications/` + userId);
        
        setNotifications(response.data.reverse());
        setCount(response.data.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchNotifications();

    subscribeToNotifications(userId, (notification) => {
      const data = {
        influencerId: userId,
        notificationTitle: "Status",
        notificationDesc: notification,
        new: true,
      };
      fetchNotifications();
      if (notification) {
        const button = document.getElementById("myButton");
        button.click();
      }
    });

    return () => {
      disconnectSocket(); // Clean up the WebSocket connection when the component unmounts
    };
  }, [userId, location.pathname]);

  const [anchor, setAnchor] = React.useState(null);

  const handleClick = async (event) => {
    

    setCount(0);

    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popper" : undefined;

  const playSound = () => {
    // URL to your online audio file
    const audioUrl = "/assets/sound.wav";
    const audio = new Audio(audioUrl);
    audio.play().catch((error) => console.log("Play sound error:", error));
  };

 
  return (
    <div className="z-50">
      <button id="myButton" className=" hidden" onClick={playSound}>
        Play Notification Sound
      </button>
      <button className=" relative" onClick={handleClick}>
        <img
          className="w-10 h-10   8k:h-[5vh] 8k:w-[5vh] mobile:w-9 3xl:h-[3vw] 3xl:w-[3vw] xsm:ml-[5vw] "
          src="/Images/notification.png"
          alt="notification"
        />
        {count > 0 && (
          <span className="absolute -top-1  bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
            {count}
          </span>
        )}
      </button>
      <BasePopup id={id} open={open} anchor={anchor}>
        <PopupBody>


        <div
      className="w-[400px] flex flex-col justify-start items-start pb-3 max-h-[70vh] overflow-y-scroll bg-white shadow-md rounded-lg custom-scrollbar"
    >
      <style>
        {`
          .custom-scrollbar::-webkit-scrollbar {
            width: 8px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .custom-scrollbar::-webkit-scrollbar-button {
            display: none;
          }

          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #888 #f1f1f1;
          }
        `}
      </style>
      {notifications.length !== 0 ? (
        notifications.map((notification) => (
          <div
            key={notification._id}
            className="text-[15px] font-[400] my-1 flex items-center justify-between bg-[#e1efff] w-full py-[8px] px-3 rounded-md shadow-sm"
          >
            <div className="flex flex-col w-full">
              <div className="flex justify-between items-center">
                <span>{notification.notificationDesc}</span>
                {notification.new && (
                  <button className="bg-blue-500 p-1 text-white rounded-lg ml-2 text-sm">
                    New
                  </button>
                )}
              </div>
              <div className="text-gray-500 text-xs mt-1">
                {moment(notification.createdAt).fromNow()}
              </div>
            </div>
            {!notification.new && (
              <div onClick={() => handleDelete(notification._id)} className="ml-2">
                <CancelIcon className="cursor-pointer hover:text-red-500" />
              </div>
            )}
          </div>
        ))
      ) : (
        <h1 className="text-[15px] font-[400] my-1 flex items-center justify-center bg-[#e1efff] w-full py-[8px] px-3 rounded-md">
          No Notifications
        </h1>
      )}
    </div>



        </PopupBody>
      </BasePopup>
    </div>
  );
}

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const PopupBody = styled("div")(
  ({ theme }) => `
  width: max-content;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  box-shadow: ${
    theme.palette.mode === "dark"
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`
  };
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  z-index: 1;
`
);

const Button = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${blue[500]};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${blue[500]};
  box-shadow: 0 2px 1px ${
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.5)"
      : "rgba(45, 45, 60, 0.2)"
  }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      background-color: ${blue[500]};
    }
  }
`
);
