import axios from "axios";

const busiUrl = "https://api.cloutin.co/api/Business";
const comapignUrl = "https://api.cloutin.co/api/allcampaigns";

const getAllBusiness = (setDataBusiness) => {
  axios.get(busiUrl).then(({ data }) => {
    setDataBusiness(data["company"]);
  });
};
const getAllCompaign = (setCampaign) => {
  axios.get(comapignUrl).then(({ data }) => {
  
    setCampaign(data);
  });
};
const getAllCompaignByUserId = (setCampaign, id) => {
  axios
    .get("https://api.cloutin.co/api/campaign/user/" + id)
    .then(({ data }) => {
      if (!Array.isArray(data)) {
        data = [data]; // Convert data to an array if it's not already an array
      }
      setCampaign(data);
    });
};
export { getAllBusiness, getAllCompaign, getAllCompaignByUserId };
