import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  marketData: [],
};

const marketPlaceSlice = createSlice({
  name: "marketPlace",
  initialState,
  reducers: {
    setMarketData(state, action) {
      state.marketData = action.payload;
    },
  },
});

export const { setMarketData } = marketPlaceSlice.actions;

export default marketPlaceSlice.reducer;