import React from "react";

const ImagePreview = ({ imageUrl }) => {
  return (
    <div className="image-preview border border-gray-300 rounded-md p-2">
      <img src={imageUrl} alt="Preview" className="w-full h-auto" />
    </div>
  );
};

export default ImagePreview;
