import React from "react";

const MainDashCard = ({
  iconImg,
  dataNumber,
  titleOfCard,
  incre_decre_data,
}) => {
  return (
    <div className="w-full lg:w-48 p-4 bg-white rounded-lg shadow-md mb-4 lg:mb-0 flex flex-col items-center hover:shadow-lg">
      <div className="flex justify-between w-full mb-4">
        <img className="w-6 h-6" src={iconImg} alt={titleOfCard} />
        <div className="flex items-center">
          <img
            className="w-6 h-6 mr-2"
            src={
              incre_decre_data > 0
                ? "/Images/increment.png"
                : "/Images/decrement.png"
            }
            alt={incre_decre_data > 0 ? "Increment" : "Decrement"}
          />
          <h1
            className={`text-lg font-bold ${
              incre_decre_data > 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {incre_decre_data}%
          </h1>
        </div>
      </div>
      <div className="flex justify-start flex-col flex-wrap gap-2">
        <h3 className="text-lg text-gray-800">{titleOfCard}</h3>
        <p className="text-2xl font-bold text-left">{dataNumber}</p>
      </div>
    </div>
  );
};

export default MainDashCard;