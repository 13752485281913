import React, { useState, useEffect, useCallback } from "react";

const NetworkStatusOverlay = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  const handleNetworkChange = useCallback(() => {
    setIsOffline(!navigator.onLine);
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);
    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, [handleNetworkChange]);

  if (!isOffline) return null;

  return (
    <div className="fixed inset-0 z-[60] flex justify-center items-center bg-black bg-opacity-50 left-[300px]">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">No Internet Connection</h2>
        <p className="text-gray-700 mb-4">
          Please check your network and try refreshing the page.
        </p>
        <div className="flex justify-center">
          <button
            onClick={() => window.location.reload()}
            className="text-blue-500 hover:text-blue-700"
            aria-label="Refresh Page"
          >
            Refresh Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default NetworkStatusOverlay;