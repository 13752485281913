export const categories=[
    "Sports",
    "Entertainment",
    "Technology",
    "Health and Wellness",
    "Fashion and Beauty",
    "Travel and Tourism",
    "Food and Beverage",
    "Business and Finance",
    "Education and Learning",
    "Lifestyle",
    "Environment and Sustainability",
    "Automotive",
    "Gaming",
    "Science and Innovation",
    "Books and Literature",
    "Politics and Current Events",
    "Home and Garden",
    "Relationships and Family",
    "Arts and Culture"
  ]