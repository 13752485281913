import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white p-3 border border-gray-200 rounded-md shadow-lg">
        <p className="font-semibold text-gray-800">{formatDate(label)}</p>
        <p className="text-blue-600">
          Purchases: {payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const AffiliateGraphReact = ({ chartData }) => {
  return (
    <ResponsiveContainer width="100%" height={320}>
      <LineChart
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
        <XAxis
          dataKey="date"
          tickFormatter={(dateString) => {
            const date = new Date(dateString);
            return `${date.getDate()}`;
          }}
          interval="preserveStartEnd"
          allowDuplicatedCategory={false}
        >
          <Label value="Date" offset={-5} position="insideBottom" />
        </XAxis>
        <YAxis
          ticks={[0, 4000, 8000, 12000, 14000, 16000]}
          domain={[0, "auto"]}
          allowDuplicatedCategory={false}
        >
          <Label
            value="Purchase Amount (RS)"
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" height={36} />
        <Line
          type="monotone"
          dataKey="purchases"
          stroke="#8884d8"
          strokeWidth={2}
          dot={{ fill: "#8884d8", strokeWidth: 2 }}
          activeDot={{ r: 8 }}
          name="Purchase Amount"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AffiliateGraphReact;
