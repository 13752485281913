import React, { useEffect, useState } from "react";
import Posted from "../Posted";
import TableCamp from "../TableCamp";
import axios from "axios";
import ChatBox from "../ChatBox";
import Cookies from "js-cookie";
import BasicModal from "../../Modal";
import BasicModal1 from "../../Modal1";
import { RxExternalLink } from "react-icons/rx";
import ErrorPage from "../../utils/ErrorPage";
import { FaCheckCircle } from "react-icons/fa";

function Campaign2() {
  const companyId = Cookies.get("userid");
  const campaignId = window.location.pathname.split("/").pop();

  const [campaignData, setCampaignData] = useState(null);
  const [influencers, setInfluencers] = useState([]);
  const [approvalWaitingInfluencers, setApprovalWaitingInfluencers] = useState(
    []
  );
  const [errors, setErrors] = useState(false);
  const [
    approvalWaitingInfluencersforBarter,
    setApprovalWaitingInfluencersforBarter,
  ] = useState([]);
  const [approvedInfluencers, setApprovedInfluencers] = useState([]);
  const [approvedInfluencersforBarter, setApprovedInfluencersforBarter] =
    useState([]);
  const [postData, setPostData] = useState([]);

  // Assuming you have a function to fetch campaign data by ID
  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(
          `https://api.cloutin.co/api/campaign/${campaignId}`
        );
        if (response?.status === 200) {
          const data = response?.data;
          setCampaignData(data);

          const influencersWithAnalytics = await Promise.all(
            data.Influencers.map(async (influencer) => {
              try {
                const response = await axios.get(
                  `https://api.cloutin.co/api/influencerAnalytics/${influencer._id}`
                  // `http://localhost:3001/api/influencerAnalytics/${influencer._id}`
                );

                if (response?.status === 200) {
                  const analyticsData =
                    response.data.analyticsData?.instaAnalytics;
                  const followers = analyticsData?.followers || 0; // Replace 'followers' with the actual field name from your analytics data
                  const engagement =
                    ((analyticsData?.likes + analyticsData?.comments) /
                      followers) *
                    100;
                  const ytAnalyticsData =
                    response?.data?.analyticsData?.ytAnalytics;
                  const subscribers = ytAnalyticsData?.subscriberCount || 0;
                  const engagementYT =
                    ((ytAnalyticsData?.likeCount +
                      ytAnalyticsData?.commentCount) /
                      subscribers) *
                    100;

                  const influencerWithAnalytics = {
                    ...influencer,
                    instagramAnalytics: {
                      ...analyticsData,
                      engagement, // Add the engagement field
                    },
                    youtubeAnalytics: {
                      ...ytAnalyticsData,
                      engagementYT, // Add the engagement field
                    },
                  };

                  return influencerWithAnalytics;
                }
              } catch (error) {
                setErrors(true);
              } finally {
                setErrors(false);
              }
            })
          );

          const approvalWaitingInfluencers = influencersWithAnalytics?.filter(
            (influencer) => {
              const statusObject = data?.applicationStatusTimeline?.find(
                (status) => {
                  return (
                    status?.influencerId.includes(influencer._id) &&
                    status?.status === "Approval Waiting" &&
                    status?.application_type !== "Barter"
                  );
                }
              );
              return !!statusObject;
            }
          );
          const approvalWaitingInfluencersforBarter =
            influencersWithAnalytics?.filter((influencer) => {
              const statusObject = data?.applicationStatusTimeline?.find(
                (status) => {
                  return (
                    status?.influencerId?.includes(influencer._id) &&
                    status?.status === "Approval Waiting" &&
                    status?.application_type === "Barter"
                  );
                }
              );
              return !!statusObject;
            });

          const approvedInfluencers = influencersWithAnalytics?.filter(
            (influencer) => {
              const statusObject = data?.applicationStatusTimeline?.find(
                (status) => {
                  return (
                    status?.influencerId?.includes(influencer._id) &&
                    status?.status === "Approved" &&
                    status?.application_type !== "Barter"
                  );
                }
              );
              return !!statusObject;
            }
          );
          const approvedInfluencersforBarter = influencersWithAnalytics?.filter(
            (influencer) => {
              const statusObject = data?.applicationStatusTimeline?.find(
                (status) => {
                  return (
                    status?.influencerId?.includes(influencer._id) &&
                    status?.status === "Approved" &&
                    status?.application_type === "Barter"
                  );
                }
              );
              return !!statusObject;
            }
          );
          const influencerStatusResponse = await axios.get(
            "https://api.cloutin.co/api/influencerStatus",
            { params: { campaignId: campaignId } }
          );
          // console.log(influencerStatusResponse, "influencerStatusResponse");
          if (influencerStatusResponse.status === 200) {
            const influencerStatusData =
              influencerStatusResponse?.data?.influencer;

            const approvedInfluencersWithStatus = approvedInfluencers?.map(
              (influencer) => {
                const hasPosted = influencerStatusData?.some(
                  (status) => status?.influencer_id?._id === influencer?._id
                );
                return { ...influencer, hasPosted };
              }
            );

            setApprovalWaitingInfluencers(approvalWaitingInfluencers);
            setApprovedInfluencers(approvedInfluencersWithStatus);
            setApprovalWaitingInfluencersforBarter(
              approvalWaitingInfluencersforBarter
            );
            setApprovedInfluencersforBarter(approvedInfluencersforBarter);
          } else {
            setErrors(true);
          }
          const response2 = await axios.get(
            "https://api.cloutin.co/api/influencerStatus",
            { params: { campaignId: campaignId } }
          );
          if (response2.status === 200) {
            const data2 = response2?.data?.influencer;
            setPostData(data2);
          } else {
            setErrors(true);
          }
        } else {
          setErrors(true);
        }
      } catch (error) {
        setErrors(true);
      } finally {
        setErrors(false);
      }
    };

    if (campaignId) {
      fetchCampaignData();
    }
    }, [campaignId, approvalWaitingInfluencers, approvedInfluencers]);
  // }, [campaignId]);

  const handleInfluencerAction = async (influencerId, action) => {
    try {
      const response = await axios.post(
        "https://api.cloutin.co/api/InfCampApply/modifyInfCampApply",
        {
          campaign_id: campaignId,
          influencer_id: influencerId,
          action: action,
        }
      );

      const notification = await axios.post(
        "https://api.cloutin.co/api/notifications/post",
        {
          data: {
            influencerId: influencerId,
            notificationTitle: "Your Application Status",
            notificationDesc: `Your application is ${action}ed`,
          },
        }
      );

      if (response.status === 200) {
        // Handle success (e.g., show a success message)

        const updatedCampaignResponse = await axios.get(
          `https://api.cloutin.co/api/campaign/${campaignId}`
        );
        if (updatedCampaignResponse.status === 200) {
          const updatedData = updatedCampaignResponse?.data;

          // Update the state variables with the updated data
          setCampaignData(updatedData);
          setInfluencers(updatedData?.Influencers);

          const influencersWithAnalytics = await Promise.all(
            updatedData?.Influencers?.map(async (influencer) => {
              try {
                const response = await axios.get(
                  `https://api.cloutin.co/api/influencerAnalytics/${influencer._id}`
                );
                if (response.status === 200) {
                  const analyticsData = response?.data?.analyticsData;
                  // Create a new influencer object with the analytics data
                  const influencerWithAnalytics = {
                    ...influencer,
                    instagramAnalytics: analyticsData,
                  };
                  return influencerWithAnalytics;
                }
              } catch (error) {
                console.error(
                  `Error fetching analytics data for influencer ${influencer?._id}:`,
                  error
                );
              }
            })
          );

          // Filter and set the approvalWaitingInfluencers again
          const approvalWaitingInfluencers = influencersWithAnalytics.filter(
            (influencer) => {
              const statusObject = updatedData?.applicationStatusTimeline?.find(
                (status) => {
                  return (
                    status?.influencerId?.includes(influencer?._id) &&
                    status?.status === "Approval Waiting" &&
                    status?.application_type !== "Barter"
                  );
                }
              );
              return !!statusObject;
            }
          );
          const approvedInfluencers = influencersWithAnalytics.filter(
            (influencer) => {
              const statusObject = updatedData?.applicationStatusTimeline?.find(
                (status) => {
                  return (
                    status?.influencerId?.includes(influencer?._id) &&
                    status?.status === "Approved" &&
                    status?.application_type !== "Barter"
                  );
                }
              );
              return !!statusObject;
            }
          );

          setApprovalWaitingInfluencers(approvalWaitingInfluencers);
          setApprovedInfluencers(approvedInfluencers);
        }
      } else {
        // Handle error (e.g., show an error message)
        console.error("Failed to update influencer status");
      }
    } catch (error) {
      console.error("Error updating influencer status:", error);
    }
  };

  const [warningModal, setWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  // State to manage the popup visibility and rejection reason
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const [successModal, setSuccessModal] = useState(false);
  // Function to handle reject action
  const handleRejectClick = () => {
    setShowRejectPopup(true);
  };

  // Function to handle cancel in the popup
  const handleCancel = () => {
    setShowRejectPopup(false);
    setRejectionReason("");
  };

  // Function to handle submit in the popup
  const handleSubmit = (influencer, forceFlag = null) => {
    // Call the action function with the influencer's ID and other necessary details

    handleInfluencerPostAction(
      influencer?._id,
      "disapprove",
      influencer?.influencer_id,
      rejectionReason,
      forceFlag
    );
    setShowRejectPopup(false);
    if (forceFlag) setRejectionReason("");
  };

  const handleInfluencerPostAction = async (
    postDataId,
    action,
    influencerId,
    rejectionReason = null,
    forceFlag
  ) => {
    try {
      const response = await axios.post(
        "https://api.cloutin.co/api/modifyInfluencerPostStatus",
        {
          postDataId,
          action: action, // "accept" or "reject" based on the button clicked
          campaign_id: campaignId,
          influencer_id: influencerId,
          rejectionReason: rejectionReason,
          forceFlag,
        }
      );
      console.log(response);

      if (response?.data?.warning) {
        setWarningModal(true);
        setWarningMessage(response?.data?.warning);
      } else if (response?.data?.ticketForRefund) {
        setWarningModal(true);
        setWarningMessage(response?.data?.ticketForRefund);
      } else if (response?.data?.refundRequested) {
        setSuccessModal(true);
        setWarningMessage(response?.data?.refundRequested);

        setTimeout(() => {
          setPostData((prevData) => {
            return prevData.map((post) => {
              if (post?._id === postDataId) {
                return {
                  ...post,
                  post_status: "Requested for Refund",
                };
              }
              return post;
            });
          });
        }, 3000);
      } else if (response?.status === 200) {
        const notification = await axios.post(
          "https://api.cloutin.co/api/notifications/post",
          {
            data: {
              influencerId: influencerId,
              notificationTitle: "Your Post Status",
              notificationDesc: `Your Post is ${action}ed`,
            },
          }
        );

        // Handle success (e.g., show a success message)

        setPostData((prevData) => {
          return prevData.map((post) => {
            if (post?._id === postDataId) {
              return {
                ...post,
                post_status:
                  action === "approve" ? "Approved" : "Requested for Edit",
              };
            }
            return post;
          });
        });
      } else {
        // Handle error (e.g., show an error message)
        console.error("Failed to update influencer's Post status");
      }
    } catch (error) {
      console.error("Error updating influencer's Post status:", error);
    }
  };
  return (
    <div className="p-[20px] mr-[10px] md:p-2 space-y-[20px] h-auto max-w-[1200px]">
      {errors && <ErrorPage />}
      <div className="w-full space-y-[20px]">
        <TableCamp />
      </div>

      <div className="bg-blue-500 text-white shadow-md text-[14px] rounded-lg p-2 w-fit">
        Influencers Applied
      </div>

      {/* new ui */}
      <div className="bg-white py-4 w-[95%] px-3 overflow-x-auto md:w-full shadow-lg rounded-lg">
        <table className="table-auto w-full text-center lg:w-[95%]">
          <thead>
            <tr className="bg-gray-200">
              <th className="text-xs md:text-sm p-2">INFLUENCER</th>
              <th className="text-xs md:text-sm p-2">Followers</th>
              {campaignData?.platform === "Instagram" ? (
                <th className="text-xs md:text-sm p-2">Following</th>
              ) : null}
              <th className="text-xs md:text-sm p-2">Likes</th>
              <th className="text-xs md:text-sm p-2">Comments</th>
              <th className="text-xs md:text-sm p-2">Engagement</th>
              <th className="text-xs md:text-sm p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {approvalWaitingInfluencers?.length > 0 ? (
              approvalWaitingInfluencers?.map((influencer, index) => (
                <tr key={index} className="border-b">
                  <td className="text-xs md:text-sm p-2">
                    <div className="flex items-center justify-center space-x-2">
                      {/* <img
                  className="w-6 h-6 rounded-full"
                  src={influencer.profile_image_url}
                  alt="profile"
                /> */}
                      <span className="uppercase text-gray-700">
                        {influencer?.name}
                      </span>
                    </div>
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {campaignData?.platform === "Instagram"
                      ? influencer?.instagramAnalytics?.followers
                      : influencer?.youtubeAnalytics?.subscriberCount}
                  </td>
                  {campaignData?.platform === "Instagram" ? (
                    <td className="text-xs md:text-sm p-2 text-gray-600">
                      {influencer?.instagramAnalytics?.following}
                    </td>
                  ) : null}
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {campaignData?.platform === "Instagram"
                      ? influencer?.instagramAnalytics?.likes
                      : influencer?.youtubeAnalytics?.likeCount}
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {campaignData?.platform === "Instagram"
                      ? influencer?.instagramAnalytics?.comments
                      : influencer?.youtubeAnalytics?.commentCount}
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {campaignData?.platform === "Instagram"
                      ? influencer?.instagramAnalytics?.engagement?.toFixed(2)
                      : influencer?.youtubeAnalytics?.engagementYT?.toFixed(2)}
                    %
                  </td>
                  <td className="text-xs md:text-sm p-2">
                    <div className="flex items-center justify-center space-x-2">
                      <button
                        className="bg-red-600 text-white px-2 py-1 rounded-full"
                        onClick={() =>
                          handleInfluencerAction(influencer?._id, "disapprove")
                        }
                      >
                        Reject
                      </button>
                      <button
                        className="bg-green-600 text-white px-2 py-1 rounded-full"
                        onClick={() =>
                          handleInfluencerAction(influencer?._id, "approve")
                        }
                      >
                        Accept
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="7"
                  className="text-xs md:text-sm p-2 text-gray-600"
                >
                  No Influencers Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className=" relative  w-full">
        <div className=" text-[14px] rounded-lg p-2 bg-blue-500 text-white shadow-md w-fit">
          Accepted Influencers
        </div>
      </div>
      <div className="bg-white py-4 w-[95%] px-3 overflow-x-auto md:w-full shadow-lg rounded-lg">
        <table className="table-auto w-full text-center lg:w-[95%]">
          <thead>
            <tr className="bg-gray-200">
              <th className="text-xs md:text-sm p-2">INFLUENCER</th>
              <th className="text-xs md:text-sm p-2">Followers</th>
              {campaignData?.platform === "Instagram" && (
                <th className="text-xs md:text-sm p-2">Following</th>
              )}
              <th className="text-xs md:text-sm p-2">Likes</th>
              <th className="text-xs md:text-sm p-2">Comments</th>
              <th className="text-xs md:text-sm p-2">Engagement</th>
              <th className="text-xs md:text-sm p-2">Post</th>
              <th className="text-xs md:text-sm p-2">Chats</th>
              <th className="text-xs md:text-sm p-2">Accepted At</th>
              <th className="text-xs md:text-sm p-2">Rejected At</th>
            </tr>
          </thead>
          <tbody>
            {approvedInfluencers?.length > 0 ? (
              approvedInfluencers?.map((influencer, index) => (
                <tr key={index} className="border-b">
                  <td className="text-xs md:text-sm p-2">
                    <div className="flex items-center justify-center space-x-2 text-gray-700">
                      <BasicModal1
                        name={influencer?.name}
                        engagementRate={
                          campaignData?.platform === "Instagram"
                            ? influencer?.instagramAnalytics?.engagement?.toFixed(
                                2
                              )
                            : influencer?.youtubeAnalytics?.engagementYT?.toFixed(
                                2
                              )
                        }
                        followers={
                          campaignData?.platform === "Instagram"
                            ? influencer?.instagramAnalytics?.followers
                            : influencer?.youtubeAnalytics?.subscriberCount
                        }
                        following={
                          campaignData?.platform === "Instagram"
                            ? influencer?.instagramAnalytics?.following
                            : null // No following for YouTube in this context
                        }
                        influencerId={influencer?._id}
                        campaignId={campaignId}
                      />
                    </div>
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {campaignData?.platform === "Instagram"
                      ? influencer?.instagramAnalytics?.followers
                      : influencer?.youtubeAnalytics?.subscriberCount}
                  </td>
                  {campaignData?.platform === "Instagram" && (
                    <td className="text-xs md:text-sm p-2 text-gray-600">
                      {influencer?.instagramAnalytics?.following}
                    </td>
                  )}
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {campaignData?.platform === "Instagram"
                      ? influencer?.instagramAnalytics?.likes
                      : influencer?.youtubeAnalytics?.likeCount}
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {campaignData?.platform === "Instagram"
                      ? influencer?.instagramAnalytics?.comments
                      : influencer?.youtubeAnalytics?.commentCount}
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {campaignData?.platform === "Instagram"
                      ? influencer?.instagramAnalytics?.engagement?.toFixed(2)
                      : influencer?.youtubeAnalytics?.engagementYT?.toFixed(2)}
                    %
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    {influencer?.hasPosted ? "Posted" : "Not Posted"}
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    <ChatBox
                      chatId={`${campaignId}${companyId}${influencer?._id}`}
                      companyId={companyId}
                      influencerId={influencer?._id}
                    />
                  </td>
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    -------
                  </td>{" "}
                  <td className="text-xs md:text-sm p-2 text-gray-600">
                    -------
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="8"
                  className="text-xs md:text-sm p-2 text-gray-600"
                >
                  No Influencers Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="bg-green-600 shadow-md rounded-lg p-2 w-fit">
        <span className="text-white">Posted</span>
      </div>
      {postData?.length === 0 ? (
        <div className="w-full bg-white flex justify-center py-10 shadow-md rounded-md">
          <h3 className="text-xl text-gray-500">
            No content has been posted by influencers.
          </h3>
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 gap-[70px] md:grid-cols-2 grid-cols-1 8k:grid-cols-6  3xl:grid-cols-5 3xl:gap-[1px] 4k:grid-cols-4 ">
          {postData &&
            postData?.map((influencer, index) => {
              // Determine the social media icon based on the campaign platform
              let socialMediaIcon;
              switch (influencer?.campaign_id?.platform?.toLowerCase()) {
                case "facebook":
                  socialMediaIcon = "facebook.png";
                  break;
                case "youtube":
                  socialMediaIcon = "youtube.png";
                  break;
                case "instagram":
                  socialMediaIcon = "insta.png";
                  break;
                // Add cases for other platforms as needed
                default:
                  socialMediaIcon = "default.png"; // Provide a default icon
              }

              return (
                <div className="border-2 border-gray-200 xl:w-[320px] lg:w-[300px] w-full text-[14px] h-fit bg-white rounded-md p-[30px] hover:shadow-md hover:border-t-blue-400">
                  <div className="relative w-full">
                    <img
                      className="w-full"
                      src={influencer?.postImage}
                      alt="tag"
                    />
                    {/* Overlay */}
                    {influencer?.post_status === "Requested for Edit" && (
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <span className="text-white text-sm font-semibold">
                          Requested for edit
                        </span>
                      </div>
                    )}
                    {influencer?.post_status === "Requested for Refund" && (
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <span className="text-white text-sm font-semibold">
                          Requested for refund
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center mt-[20px]">
                    <h1 className="text-[#929292] text-[18px] w-full font-bold">
                      {influencer && influencer?.influencer_id?.name}
                    </h1>
                    <img
                      className="w-8 h-8"
                      src={`/Images/${socialMediaIcon}`}
                      alt="i"
                    />
                  </div>
                  <div className="flex text-[#727272] items-center text-justify mt-[10px] mb-[20px]">
                    <p>{influencer?.caption}</p>
                  </div>
                  <div className="flex justify-between text-[12px] lg:text-[11px] xl:text-[14px] items-center">
                    <a href={influencer?.post_url} target="_blank">
                      <div className="bg-[#00A3FF] cursor-pointer rounded-3xl flex items-center justify-between text-white lg:px-3 px-5 xl:px- py-2 gap-2">
                        <h1>View Link</h1>
                        <RxExternalLink className="font-semibold" />
                      </div>
                    </a>
                    <div className="flex space-x-[6px]">
                      {influencer?.post_status !== "Approved" &&
                        influencer?.post_status !== "Disapproved" &&
                        influencer?.post_status !== "Requested for Edit" &&
                        influencer?.post_status !== "Requested for Refund" && (
                          <>
                            {influencer?.post_status !== "Refund Cancelled" && (
                              <h1
                                className="bg-red-200 cursor-pointer px-4 text-black rounded-md py-2 text-md"
                                onClick={handleRejectClick}
                              >
                                Edit
                              </h1>
                            )}

                            {showRejectPopup && (
                              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                                <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
                                  <h2 className="text-lg font-semibold mb-4">
                                    What do you want to be edited?
                                  </h2>
                                  <p className="mb-4">
                                    Please explain in detail:
                                  </p>
                                  <textarea
                                    className="w-full h-32 p-2 border rounded-lg mb-4"
                                    value={rejectionReason}
                                    onChange={(e) =>
                                      setRejectionReason(e.target.value)
                                    }
                                    placeholder="Enter rejection reason here..."
                                  ></textarea>
                                  <div className="flex justify-end space-x-2">
                                    <button
                                      className="bg-red-500 text-white px-4 py-2 rounded-lg"
                                      onClick={handleCancel}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="bg-green-500 text-white px-4 py-2 rounded-lg"
                                      onClick={() => handleSubmit(influencer)}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {warningModal && (
                              <WarningModal
                                setWarningModal={setWarningModal}
                                warningMessage={warningMessage}
                                onProceed={handleSubmit}
                                influencer={influencer}
                              />
                            )}
                            {successModal && (
                              <SuccessModal
                                setSuccessModal={setSuccessModal}
                                message={warningMessage}
                                FaCheckCircle={FaCheckCircle}
                                setWarningModal={setWarningModal}
                              />
                            )}

                            <h1
                              className="bg-green-600 cursor-pointer px-2 text-white rounded-md py-2 text-md"
                              onClick={() =>
                                handleInfluencerPostAction(
                                  influencer?._id,
                                  "approve",
                                  influencer?.influencer_id
                                )
                              }
                            >
                              Accept
                            </h1>
                          </>
                        )}
                      {influencer?.post_status !== "pending_approve" &&
                        influencer?.post_status !== "Requested for Edit" &&
                        influencer?.post_status !== "Requested for Refund" &&
                        influencer?.post_status !== "Refund Cancelled" && (
                          <h1 className="bg-green-500 cursor-pointer px-1.5 text-white rounded-md py-2 text-md">
                            {influencer?.post_status}
                          </h1>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {campaignData?.barter?.isBarter ? (
        <>
          <div className="bg-[#D9FDFF] text-[14px] rounded-lg p-2 w-fit">
            Influencers Applied through Barter
          </div>

          <div className="bg-white py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
            <table className="table-auto  md:gap-2 text-center lg:w-[95%]">
              <thead>
                <tr className="font-semibold">
                  <th className="text-xs pr-[20px] md:text-[14px]">
                    INFLUENCER
                  </th>

                  <th className="text-xs pr-[20px] md:text-[14px]">
                    Followers
                  </th>
                  {campaignData?.platform === "Instagram" ? (
                    <th className="text-xs pr-[20px] md:text-[14px]">
                      Following
                    </th>
                  ) : null}
                  <th className="text-xs pr-[20px] md:text-[14px]">Likes</th>
                  <th className="text-xs pr-[20px] md:text-[14px]">Comments</th>
                  <th className="text-xs pr-[20px] md:text-[14px]">
                    Engagement
                  </th>

                  <th className="text-xs flex pr-[20px] md:text-[14px]">
                    <h1>Accept</h1>
                    <h1 className="mx-[5px]"> Reject</h1>
                  </th>
                </tr>
              </thead>
              <tbody className="my-[20px] mx-[20px] space-y-[20px] ">
                {approvalWaitingInfluencersforBarter?.length > 0 ? (
                  approvalWaitingInfluencersforBarter?.map(
                    (influencer, index) => (
                      <tr key={index}>
                        <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                          <div className="flex text-[#bfbdbd] text-[14px] justify-center items-center">
                            <img
                              className="w-6 mr-[5px] h-6 rounded-full"
                              src={influencer?.profile_image_url}
                              alt="profile"
                            />
                            <h1>{influencer && influencer?.name}</h1>
                          </div>
                        </td>

                        <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                          {campaignData?.platform === "Instagram"
                            ? influencer?.instagramAnalytics?.follower
                            : influencer?.youtubeAnalytics?.subscriberCount}
                        </td>
                        {campaignData?.platform === "Instagram" ? (
                          <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                            {influencer?.instagramAnalytics?.following}
                          </td>
                        ) : null}
                        <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                          {campaignData?.platform === "Instagram"
                            ? influencer?.instagramAnalytics?.likes
                            : influencer?.youtubeAnalytics?.likeCount}
                        </td>
                        <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                          {campaignData?.platform === "Instagram"
                            ? influencer?.instagramAnalytics?.comments
                            : influencer?.youtubeAnalytics?.commentCount}
                        </td>
                        <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                          {campaignData?.platform === "Instagram"
                            ? influencer?.instagramAnalytics?.engagement
                            : influencer?.youtubeAnalytics?.engagementYT}
                          %
                        </td>

                        <td className="text-xs flex pt-[20px] space-x-[5px] text-[#bfbdbd] md:text-[14px]">
                          <h1
                            className="bg-red-600 cursor-pointer px-2 text-white rounded-xl py-1"
                            onClick={() =>
                              handleInfluencerAction(
                                influencer?._id,
                                "disapprove"
                              )
                            }
                          >
                            Reject
                          </h1>
                          <h1
                            className="bg-green-600 cursor-pointer px-2 text-white rounded-xl py-1"
                            onClick={() =>
                              handleInfluencerAction(influencer?._id, "approve")
                            }
                          >
                            Accept
                          </h1>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <>No Influencers Found</>
                )}
              </tbody>
            </table>
          </div>
          <div className=" relative  w-full">
            <div className=" text-[14px] rounded-lg p-2 bg-[#D9FDFF] w-fit">
              Accepted Influencers through Barter
            </div>

            {/* <div className="absolute top-10 z-20 right-0">
          <ChatBox />
        </div> */}
          </div>

          <div className="bg-white  py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
            <table className="table-auto  md:gap-2 text-center lg:w-[95%]">
              <thead>
                <tr>
                  <th className="text-xs pr-[20px] md:text-[14px]">
                    INFLUENCER
                  </th>

                  <th className="text-xs pr-[20px] md:text-[14px]">
                    Followers
                  </th>
                  {campaignData?.platform === "Instagram" ? (
                    <th className="text-xs pr-[20px] md:text-[14px]">
                      Following
                    </th>
                  ) : null}
                  <th className="text-xs pr-[20px] md:text-[14px]">
                    Engagement
                  </th>
                  <th className="text-xs pr-[20px] md:text-[14px]">
                    Dispatch the Product
                  </th>
                  <th className="text-xs pr-[20px] md:text-[14px]">
                    Influencer Address
                  </th>
                  <th className="text-xs pr-[20px] md:text-[14px]">
                    Order Status
                  </th>
                  <th className="text-xs flex pr-[20px] md:text-[14px]">
                    Post
                  </th>
                  <th className="text-xs flex pr-[20px] md:text-[14px]">
                    Chats
                  </th>
                </tr>
              </thead>
              <tbody className="my-[20px] mx-[20px] space-y-[20px]">
                {approvedInfluencersforBarter &&
                  approvedInfluencersforBarter?.map((influencer, index) => (
                    <tr key={index}>
                      <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                        <div className="flex text-[#bfbdbd] text-[14px]">
                          <img
                            className="w-6 mr-[5px] h-6 rounded-full"
                            src={influencer?.profile_image_url}
                            alt="profile"
                          />
                          <BasicModal1
                            name={influencer && influencer?.name}
                            enganmentRate={
                              influencer?.instagramAnalytics?.engagement
                            }
                            followers={
                              influencer?.instagramAnalytics?.followers
                            }
                            following={
                              influencer?.instagramAnalytics?.following
                            }
                            influencerId={influencer?._id}
                          />

                          {/* <h1>{influencer && influencer.name}</h1> */}
                        </div>
                      </td>

                      <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                        {campaignData?.platform === "Instagram"
                          ? influencer?.instagramAnalytics?.follower
                          : influencer?.youtubeAnalytics?.subscriberCount}
                      </td>
                      {campaignData?.platform === "Instagram" ? (
                        <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                          {influencer?.instagramAnalytics?.following}
                        </td>
                      ) : null}

                      <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                        {campaignData?.platform === "Instagram"
                          ? influencer?.instagramAnalytics?.engagement
                          : influencer?.youtubeAnalytics?.engagementYT}
                        %
                      </td>
                      <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                        {campaignData?.barter?.product_name}
                      </td>
                      <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                        {influencer?.name}:Address
                      </td>
                      <td className="text-xs pt-[20px] text-red-500 md:text-[14px]">
                        Not Dispatched
                      </td>
                      <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                        {influencer?.hasPosted ? "Posted" : "Not Posted"}
                      </td>
                      <td className="text-xs pt-[20px] text-[#bfbdbd] md:text-[14px]">
                        <ChatBox
                          chatId={`${campaignId}${companyId}${influencer?._id}`}
                          companyId={`${companyId}`}
                          influencerId={`${influencer?._id}`}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Campaign2;

const WarningModal = ({
  setWarningModal,
  warningMessage,
  onProceed,
  influencer,
}) => {
  // If the modal is not open, do not render it

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        {/* Modal Header */}
        <h2 className="text-xl font-bold mb-4">Warning</h2>

        {/* Warning Message */}
        <p className="mb-4">{warningMessage}</p>

        {/* Modal Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={() => setWarningModal(false)}
          >
            Close
          </button>
          <button
            className={` ${
              warningMessage.includes(
                "We can't process more than 3 edit requests"
              )
                ? "bg-green-500"
                : "bg-red-500"
            } text-white px-4 py-2 rounded`}
            onClick={() => onProceed(influencer, true)}
          >
            {`${
              warningMessage.includes(
                "We can't process more than 3 edit requests"
              )
                ? "Raise Refund Ticket"
                : "Proceed"
            }`}
          </button>
        </div>
      </div>
    </div>
  );
};

// SuccessModal.js

const SuccessModal = ({
  setSuccessModal,
  message,
  FaCheckCircle,
  setWarningModal,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        {/* Header */}
        <div className="flex items-center justify-center mb-4">
          <FaCheckCircle className="text-green-500 text-4xl" />{" "}
          {/* Green Tick Icon */}
        </div>
        <h2 className="text-center text-2xl font-semibold mb-4">Success</h2>

        {/* Message */}
        <p className="text-center text-gray-700 mb-6">{message}</p>

        {/* Close Button */}
        <div className="flex justify-center">
          <button
            onClick={() => {
              setWarningModal(false);
              setSuccessModal(false);
            }}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
