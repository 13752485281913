import imageCompression from 'browser-image-compression';

export const base64ToBlob = (base64) => {
  const byteString = atob(base64.split(',')[1]);
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const calculateImageSizeInMB = (base64) => {
  const sizeInBytes = (base64.length * (3 / 4)) - 
    (base64.indexOf('=') > 0 ? base64.length - base64.indexOf('=') : 0);
  return sizeInBytes / (1024 * 1024);
};

export const compressImage = async (imageBlob, maxSizeMB = 1) => {
  const options = {
    maxSizeMB,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };
  return await imageCompression(imageBlob, options);
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
  });
};
