import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllBusiness } from "./Api";
import ButtonCampaign from "../ButtonCampaign";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "../../../redux/Slice/loadingSlice";

const Businesses_Admin = () => {
  const [dataBusiness, setDataBusiness] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);
  const [searchTerm, setSearchTerm] = useState("");
  const { loading } = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showLoading());
    getAllBusiness((data) => {
      setDataBusiness(data);
      dispatch(hideLoading());
    });
  }, []);

  const navigate = useNavigate();
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = dataBusiness.filter((item) =>
    item?.companyId?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => setCurrentPage(value);
  return (
    <div className="p-4 md:p-2 space-y-4 md:space-y-10">
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row">
        {/* graph  */}
        {/* section  */}
        <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw]  3xl:w-[40%]  3xl:h-[25vw]  4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]">
          {/* section-1 */}
          <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw]  8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh]   3xl:text-[1.3vw] 3xl:h-[8vw]  lgk:text-[1vw] lgk:w-[40vw]">
            {/* 1card  */}
            {/* 2 card  */}
          </div>
        </div>
      </div>

      <h1 className="text-3xl">Business Campaigns</h1>

      {loading ? (
        <div className=" items-center justify-center h-[50vh] flex flex-col gap-3 ">
          <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-blue-500"></div>
          <p className="text-xl text-green-500">
            Please Wait....Loading the data
          </p>
        </div>
      ) : (
        <div>
          <div className="bg-white shadow-md py-[10px] w-[95%] p-3 overflow-x-auto md:w-full border-2 border-e-gray-200 rounded-md">
            <div className="flex items-center mb-4">
              <input
                type="text"
                placeholder="Search by business name..."
                className="border border-gray-300 rounded-md p-2 mb-4"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <table className="table-auto md:gap-2 text-center lg:w-[95%] sm:w-[95%] border-collapse">
              <thead>
                <tr className="bg-blue-400">
                  <th className="text-sm md:text-base font-semibold p-2">
                    Business
                  </th>
                  <th className="text-sm md:text-base p-2">Status</th>
                  <th className="text-sm md:text-base p-2">Total Cart</th>
                  <th className="text-sm md:text-base p-2">Purchases</th>
                  <th className="text-sm md:text-base p-2">Action</th>
                </tr>
              </thead>
              <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
                {currentItems.map((i) =>
                  i.companyId == undefined || i.companyId == null ? (
                    <></>
                  ) : (
                    <tr key={i.companyId._id} className="border-b">
                      <td
                        className="text-sm md:text-base font-semibold cursor-pointer py-2 px-4 text-[#707070] hover:text-blue-500"
                        onClick={() => {
                          navigate(`/admin/campaign/page2/${i.companyId._id}`);
                        }}
                      >
                        {i.companyId.name}
                      </td>
                      <td
                        className={`text-md font-bold py-2 px-4 ${
                          i.companyId.status === "Active"
                            ? "text-[#00B528]"
                            : "text-[#FF0000]"
                        }`}
                      >
                        {i.companyId.status}
                      </td>
                      <td className="text-sm md:text-base py-2 px-4 text-[#707070]">
                        {i.total_add_to_carts.length}
                      </td>
                      <td className="text-sm md:text-base py-2 px-4 text-[#707070]">
                        {i.total_purchases.length}
                      </td>
                      <td className="text-sm md:text-base py-2 px-4 text-[#707070]">
                        <ButtonCampaign
                          label={i.companyId.status}
                          id={i.companyId._id}
                          setInfluencer={setDataBusiness}
                          color="#D6F0FF"
                          tcolor="black"
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center items-center mt-4">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                size="small"
                color="primary"
                variant="rounded"
              />
            </Stack>
          </div>
        </div>
      )}
    </div>
  );
};

export default Businesses_Admin;
