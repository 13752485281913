import React, { useState, useEffect } from "react";

const LoadingOnWebsite = () => {
  const [randomText, setRandomText] = useState("Loading...");

  useEffect(() => {
    const randomPhrases = [
      "Loading...",
      "Almost there...",
      "Hang tight!",
      "Fetching data...",
      "Preparing...",
      "Gathering resources...",
      "Preparing the stage...",
      "Brewing the magic...",
      "Polishing the details...",
      "Warming up the servers...",
      "Unleashing the power...",
    ];

    const interval = setInterval(() => {
      setRandomText(
        randomPhrases[Math.floor(Math.random() * randomPhrases.length)]
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="flex items-center justify-center h-screen bg-gray-100"
      style={{
        backgroundImage:
          "url('https://cdn.pixabay.com/photo/2021/01/05/17/09/code-5891879_1280.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: 0.8,
      }}
    >
      <div className="text-center flex flex-col items-center justify-center relative">
        <div className="absolute z-10">
          <img
            className="w-20 h-20 8k:w-[5vh] 8k:h-[5vh] 3xl:w-[2.5vw] 3xl:h-[2.5vw]"
            src="/Images/logo.png"
            alt="logo"
          />
        </div>
        <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-b-4 border-blue-600 relative"></div>
        <div className="absolute inset-0 flex items-center justify-center mt-[10rem]">
          <p className="text-white font-bold text-center text-xl">
            {randomText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingOnWebsite;
