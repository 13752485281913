import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { getAllAffiliatesByCompanyId, updateAffiliateStatus } from "./Api";
import {FaEdit,FaAngleRight} from 'react-icons/fa'


function AffiliateCampaignDetails() {
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState([]);

  const [allAffiliates, setAllAffiliates] = useState([]);
  const [editableAffiliateId, setEditableAffiliateId] = useState(-1);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#FFC300";
      case "approved":
        return "#00B528";
      case "rejected":
        return "#FF0000";
      case "deleted":
        return "#FF0000";
      default:
        return "#000000";
    }
  };

  const { id12 } = useParams();

  const handleEdit = (affiliate_id) => {
  
    setEditableAffiliateId(affiliate_id);
  };
  const handleUpdate = async (e, affiliate_id) => {
  
    try {
      const selectedStatus = e.target.value;
      await updateAffiliateStatus(affiliate_id, selectedStatus);
      toast.success(`Status changed to ${selectedStatus} successfully`);
      setEditableAffiliateId(-1);

      getAllAffiliatesByCompanyId(setAllAffiliates, id12);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error changing status");
      setEditableAffiliateId(-1);
    }
  };

  useEffect(() => {
    getAllAffiliatesByCompanyId(setAllAffiliates, id12);
  }, [id12]);

  const countApprovedStatuses = (campaign) => {
    if (campaign.applicationStatusTimeline) {
      return campaign.applicationStatusTimeline.filter(
        (status) => status.status === "Approved"
      ).length;
    }
    return 0;
  };
  const countDisapprovedStatuses = (campaign) => {
    if (campaign.applicationStatusTimeline) {
      return campaign.applicationStatusTimeline.filter(
        (status) => status.status === "Disapproved"
      ).length;
    }
    return 0;
  };

  const [selectedOption, setSelectedOption] = useState("Year");
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getCurrentMonth = () => {
    const currentMonthIndex = new Date().getMonth();
    return currentMonthIndex;
  };

  const datachYear = [
    { year: getCurrentYear() - 3, uv: 120, pv: 2400, amt: 2400 },
    { year: getCurrentYear() - 2, uv: 280, pv: 1000, amt: 1200 },
    { year: getCurrentYear() - 1, uv: 380, pv: 1800, amt: 1200 },
    { year: getCurrentYear(), uv: 150, pv: 2000, amt: 1200 },
  ];

  const datachMonth = [
    { month: months[getCurrentMonth() - 3], uv: 120, pv: 2400, amt: 2400 },
    { month: months[getCurrentMonth() - 2], uv: 280, pv: 1000, amt: 1200 },
    { month: months[getCurrentMonth() - 1], uv: 380, pv: 1800, amt: 1200 },
    { month: months[getCurrentMonth()], uv: 150, pv: 2000, amt: 1200 },
  ];
  return (
    <div className="p-[20px] mr-[10px] md:p-2 space-y-[20px] ">
      <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-[10px] 8k:w-[115vh] 4k:w-[77vw]  8k:w-[280vh] 3xl:mt-[1.5vw] lgk:pr-[2vw]">
        {/* graph  */}
        <div
          className="xl:w-[554px] lg:w-[300px] lg:ml-[10px] lg:flex flex-col justify-center px-[20px] py-[10px] bg-white 8k:w-[60vh] 8k:p-3 8k:mt-9 4k:w-[55vw] 4k:mt-9 3xl:w-[60%] rk:py-[1vw]
          1440k:h-[29vw]  lgk:w-[32vw] 2xl:w-[100%]"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-black font-extrabold text-md md:text-lg mr-[10px] 8k:text-3xl 8k:font-bold 4k:text-xl 4k:font-bold 3xl:text-[1.3vw] 3xl:font-bold rk:text-[1vw] rk:font-bold 1440k:font-bold lgk:font-bold lgk:text-[1.2vw] ">
              Sales Generated
            </h1>
            <div className=" mb-[10px] text-[#D9D9D9] w-fit border rounded-full flex justify-between space-x-[20px] 8k:text-xl 4k:text-lg mobile:w-[15vh] mobile:h-[4vh] mobile:text-xs lgk:text-[1vw] lgk:w-[10vw] lgk:px-0 ">
              <select
                className="w-full rounded-full py-2 pl-6 focus:outline-none mobile:p-1 lgk:px-[0.5vw] "
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="Year">Year</option>
                <option value="Month">Month</option>
              </select>
            </div>
          </div>

          {/* <div className="graph  md:w-full w-[99%] 3xl:h-[100%]  4k:h-[100%] 1440k:h-[100%] lgk:h-[100%] lgk:w-[100%] "> */}
          <ResponsiveContainer>
            {selectedOption === "Year" ? (
              <LineChart data={datachYear}>
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  strokeWidth={8}
                />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <YAxis />
                <Tooltip />

                {/* Conditional rendering of XAxis label based on selection */}
                <XAxis dataKey={"year"} />
              </LineChart>
            ) : (
              <LineChart data={datachMonth}>
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  strokeWidth={8}
                />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <YAxis />
                <Tooltip />

                {/* Conditional rendering of XAxis label based on selection */}
                <XAxis dataKey={"month"} />
              </LineChart>
            )}
          </ResponsiveContainer>
          {/* <Line options={options} data={data} /> */}
          {/* </div> */}
        </div>
        {/* section  */}
        <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw]  3xl:w-[40%]  3xl:h-[25vw]  4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]">
          {/* section-1 */}
          <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw]  8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh]   3xl:text-[1.3vw] 3xl:h-[8vw]  lgk:text-[1vw] lgk:w-[40vw]">
            {/* 1card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/influencer_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh] "
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw]  lgk:mb-[0]">
                8.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw] lgk:text-[1.2vw] ">
                Approved Influencers
              </div>
            </div>
            {/* 2 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/click.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="text-lg font-extrabold text-black mt-[10px] mb-[10px] 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                2.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                clicks
              </div>
            </div>
          </div>

          <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0  4k:w-[26vw] lg:flex-row  8k:ml-[2vh] 8k:my-[5vh]  8k:h-[12vh] 3xl:text-[1.3vw] 3xl:h-[8vw] lgk:w-[40vw]">
            {/* 3rd card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/sales.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                8.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Total spent
              </div>
            </div>
            {/* 4 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/influencer_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                2.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Pending Influencers
              </div>
            </div>
          </div>

          <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0 4k:w-[26vw] lg:flex-row 8k:ml-[2vh] 8k:mt-[2vh]  8k:h-[12vh]  lgk:w-[40vw] 3xl:text-[1.3vw] 3xl:h-[8vw]">
            {/* 5 card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/reach.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                8.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Potential reach
              </div>
            </div>
            {/* 6 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/social_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">
                2.4K+
              </div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Total Posts
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-lg text-black font-extrabold 8k:text-3xl 8k:font-bold 8k:py-9">
        Campaign List
      </div>

      {/* Running Affiliate Campaigns List */}
      <div className="bg-white py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
        <table className="table-auto md:gap-2 text-center lg:w-[95%]">
          <thead>
            <tr>
              <th className="text-xs pr-[20px] md:text-[14px] font-semibold pl-8">
                Campaign Name
              </th>
              <th className="text-xs pr-[20px] md:text-[14px] pl-2">status</th>
              <th className="text-xs pr-[20px] md:text-[14px]">
                No. of Influencers
              </th>
              <th className="text-xs pr-[20px] md:text-[14px]">
                Approved Influencers
              </th>
              <th className="text-xs pr-[20px] md:text-[14px]">
                Rejected Influencers
              </th>
              <th className="text-xs pr-[20px] md:text-[14px] pl-1">Spent</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Clicks</th>
              <th className="text-xs pr-[20px] md:text-[14px] pl-4">
                Conversions
              </th>
              <th className="text-xs pr-[60px] md:text-[14px]">Action</th>
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
            {campaign.map((i) => (
              <tr key={i._id} className="">
                <td
                  className="text-xs pl-[20px] pt-[20px] md:text-lg cursor-pointer "
                  onClick={() => {
                    navigate(`/admin/campaign/page3/:123`);
                  }}
                >
                  <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
                    {/* <img
                        className="w-6 mr-[5px] h-6 rounded-full"
                        src={i.cover}
                        alt="profile"
                      /> */}
                    <h1>{i.name}</h1>
                    {/* <h1 className="mx-[5px]">Campaign</h1> */}
                  </div>
                </td>
                <td
                  className={`text-md pt-[20px] font-bold ${
                    i.status === "Active" ? "text-[#00B528]" : "text-[#FF0000]"
                  }`}
                >
                  {i.status}
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                  {i.requiredInfluencers}
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                  {countApprovedStatuses(i)}
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] pr-8">
                  {countDisapprovedStatuses(i)}
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                  0
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                  0
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-2">
                  0
                </td>
                <td className="flex pt-[20px] space-x-[5px]  md:text-[14px]">
                  {/* <ButtonIndividualCampaign label={i.status} id={i._id} setCampaign={setCampaign} color="#D6F0FF" tcolor="black"/> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* New Affiliate Campaigns */}
      <div className="text-lg text-black font-extrabold 8k:text-3xl 8k:font-bold 8k:py-9">
        New Affiliate Campaigns List
      </div>
      <div className="flex flex-wrap -mx-3 mb-5">
        <div className="w-full max-w-full px-3 mb-6  mx-auto">
          <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
            <div className="relative flex flex-col min-w-0 break-words border border-dashed bg-clip-border rounded-2xl border-stone-200 bg-light/30">
              {/* end card header */}
              {/* card body  */}
              <div className="flex-auto block py-8 pt-6 px-9">
                <div className="overflow-x-auto">
                  <table className="w-full my-0 align-middle text-dark border-neutral-200">
                    <thead className="align-bottom">
                      <tr className="font-semibold text-[0.95rem] text-secondary-dark">
                        <th className="pb-3 text-end min-w-[100px]">
                          CAMPAIGN NAME
                        </th>
                        <th className="pb-3 text-end min-w-[100px]">
                          COMMISION
                        </th>
                        <th className="pb-3 pr-12 text-end min-w-[175px]">
                          STATUS
                        </th>
                        <th className="pb-3 pr-12 text-end min-w-[100px]">
                          PLATFORM ALLOWS
                        </th>
                        <th className="pb-3 text-end min-w-[50px]">DETAILS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allAffiliates && allAffiliates.length > 0 ? (
                        allAffiliates.map((affiliate) => (
                          <tr className="border-b border-dashed last:border-b-0">
                            <td className="p-3 pr-0 text-end">
                              <span className="font-semibold text-light-inverse text-md/normal">
                                {affiliate.affiliate_campaign_name}
                              </span>
                            </td>
                            <td className="p-3 pr-0 text-end">
                              <span className="text-center align-baseline inline-flex px-3 py-2 mr-auto items-center font-semibold text-base/none text-blue-500 bg-blue-200 rounded-full">
                                {affiliate.commission} %
                              </span>
                            </td>
                            <td className="p-3 pr-12 text-end">
                              {affiliate._id === editableAffiliateId ? (
                                <select
                                  onChange={(e) =>
                                    handleUpdate(e, editableAffiliateId)
                                  }
                                >
                                  <option>Choose</option>
                                  <option
                                    value="pending"
                                    style={{ color: getStatusColor("pending") }}
                                  >
                                    Pending
                                  </option>
                                  <option
                                    value="approved"
                                    style={{
                                      color: getStatusColor("approved"),
                                    }}
                                  >
                                    Approved
                                  </option>
                                  <option
                                    value="rejected"
                                    style={{
                                      color: getStatusColor("rejected"),
                                    }}
                                  >
                                    Rejected
                                  </option>
                                  <option
                                    value="deleted"
                                    style={{ color: getStatusColor("deleted") }}
                                  >
                                    Deleted
                                  </option>
                                </select>
                              ) : (
                                <span
                                  className="font-bold"
                                  style={{
                                    color: getStatusColor(
                                      affiliate.affiliate_status
                                    ),
                                  }}
                                >
                                  {affiliate.affiliate_status}
                                </span>
                              )}

                              <button
                                onClick={(e) => {
                                  handleEdit(affiliate._id);
                                }}
                                className="text-white bg-blue-400 rounded-full px-3 py-2 ml-2"
                              >
                                <FaEdit/>
                              </button>
                            </td>
                            <td className=" p-3 pr-12 ">
                              <div className="text-end font-semibold text-light-inverse text-md/normal">
                                {affiliate.platform_allows.toLowerCase() ===
                                "instagram" ? (
                                  <SocialIcon network="instagram" />
                                ) : (
                                  <SocialIcon network="youtube" />
                                )}
                              </div>
                            </td>
                            <td
                              className="p-3 pr-0 text-end"
                              onClick={() => {
                                // navigate(
                                //   `/admin/campaign/page2/${affiliate._id}`
                                // );
                                navigate(`/admin/affiliate/individual/${affiliate.affiliate_id}`)
                              }}
                            >
                              <button className="ml-auto relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center">
                                <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                  <FaAngleRight/>
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            <span className="font-semibold text-red-500 my-5 text-md/normal">
                              No new affiliates
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AffiliateCampaignDetails;
