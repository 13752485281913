// import React from 'react';
// import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

// const ErrorFallback = ({ error, resetErrorBoundary }) => {
//   return (
//     <div role="alert" className="error-boundary p-4 bg-red-100 border border-red-400 text-red-700 rounded">
//       <h2 className="text-lg font-bold mb-2">Something went wrong:</h2>
//       <pre className="text-sm overflow-auto mb-4">{error.message}</pre>
//       <button
//         onClick={resetErrorBoundary}
//         className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
//       >
//         Try again
//       </button>
//     </div>
//   );
// };

// export const ErrorBoundary = ({ children }) => {
//   return (
//     <ReactErrorBoundary FallbackComponent={ErrorFallback}>
//       {children}
//     </ReactErrorBoundary>
//   );
// };

import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          <h2 className="text-lg font-bold mb-2">
            Oops! Something went wrong.
          </h2>
          <p className="text-sm mb-4">
            We're sorry for the inconvenience. Please try again later.
          </p>
          {process.env.NODE_ENV === "development" && (
            <pre className="text-xs bg-red-200 p-2 rounded">
              {this.state.error.toString()}
            </pre>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
