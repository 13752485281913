import React from 'react';
import FacebookLoginButton from './instagram';

const InstagramTest = () => {
  return (
    <div>
      {/* Other components */}
      <FacebookLoginButton />
      {/* Other components */}
    </div>
  );
};

export default InstagramTest;
