import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "../Slice/userSlice";
import loadingSlice from "../Slice/loadingSlice";
import marketPlaceSlice from "../Slice/marketPlaceSlice";
import dashboardSlice from "../Slice/dashboardSlice";
import campaignsSlice from "../Slice/campaignsSlice";
import UserAccountStatusSlice from "../Slice/UserAccounStatusSlice";

import adminSlice from "../Slice/AdminSlice";
import emailSlice from "../Slice/EmailVerification";

const persistConfig = {
  key: "root",
  storage,
};

// for the dashboard
const dashboardPersistConfig = {
  key: "dashboard",
  storage,
};
const campaignsPersistConfig = {
  key: "campaigns",
  storage,
};
const adminStatusConfig = {
  key: "admin",
  storage,
};
const accountStatusConfig = {
  key: "ac_staus",
  storage,
};
const marketPlacePersistConfig = {
  key: "marketplace",
  storage,
};

export const store = configureStore({
  reducer: {
    admin: persistReducer(adminStatusConfig, adminSlice),
    user: persistReducer(persistConfig, userReducer),
    loading: loadingSlice,
    marketplace: persistReducer(marketPlacePersistConfig, marketPlaceSlice),
    email: emailSlice,
    dashboard: persistReducer(dashboardPersistConfig, dashboardSlice),
    campaings: persistReducer(campaignsPersistConfig, campaignsSlice),
    userAccountStatus: persistReducer(
      accountStatusConfig,
      UserAccountStatusSlice.reducer
    ),
  },
});

export const persistor = persistStore(store);
