import axios from "axios";
const campaignUrl = "https://api.cloutin.co/api";
const baseUrl = "https://api.cloutin.co/api/Business";

const getAffiliatesByAffiliateId = (setAffiliate, affiliateId) => {
  axios
    .get(`${campaignUrl}/affiliate/getAffiliate/${affiliateId}`)
    .then((response) => {
      setAffiliate(response.data);
    });
};

const getInfluencerByInfluencerId = (influencerId) => {
  axios
    .get(`${campaignUrl}/influencer/${influencerId}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.error("Error getting Influencers by influencerId:", error);
      throw error;
    });
};

export { getAffiliatesByAffiliateId, getInfluencerByInfluencerId };
