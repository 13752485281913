import axios from "axios";

const baseUrl = "https://api.cloutin.co/api/influencer/marketPlace";
// const baseUrl = "https://api.cloutin.co/api/influencer/marketPlace";

const getAllMarketPlaceData = (setData,influencerId="NA") => {
  axios.get(baseUrl).then(({ data }) => {
    if(influencerId==="NA"){
      setData(data["influencers"]);
    }
    else{
    const index = data["influencers"].findIndex((obj)=>obj.id===influencerId)
    setData(data["influencers"][index]);
    }
  });
};

export { getAllMarketPlaceData };
