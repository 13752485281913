import React, { createContext, useState } from "react";

// Create a new context
export const SharedStateContext = createContext();

// Create a provider component
export const SharedStateProvider = ({ children }) => {
  const [hiredInfluencer, setHiredInfluencer] = useState(false);
  const [create, setCreate] = useState(false);
  const [dashBoard, setDashBoard] = useState(true);
  const [marketplace, setMarketplace] = useState(false);
  const [visible, setVisible] = useState(false);
  const [integration, setIntegration] = useState(false);
  const [report, setReport] = useState(false);
  const [campaigns, setCampaigns] = useState(false);
  const [setting, setSetting] = useState(false);
  const [wallet, setWallet] = useState(false);
  const [conversion, setConversion] = useState(false);
  const [affiliate, setAffiliate] = useState(false);
  return (
    <SharedStateContext.Provider
      value={{
        create,
        setCreate,
        marketplace,
        setMarketplace,
        hiredInfluencer,
        setHiredInfluencer,
        dashBoard,
        setDashBoard,
        visible,
        setVisible,
        integration,
        setIntegration,
        report,
        setReport,
        campaigns,
        setCampaigns,
        wallet,
        setWallet,
        setting,
        setSetting,
        affiliate,
        setAffiliate,
        conversion,
        setConversion,
      }}
    >
      {children}
    </SharedStateContext.Provider>
  );
};
