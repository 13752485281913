import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllPackages } from "../utils/HandleApi";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import toast from "react-hot-toast";
// import Cookie from "js-cookie";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import Loader from "../utils/Loader";
import {
  Instagram,
  KeyboardArrowDownOutlined,
  YouTube,
} from "@mui/icons-material";
import { IoLogoYoutube } from "react-icons/io";
import { useSelector } from "react-redux";
import { IoIosShareAlt } from "react-icons/io";
import {
  EmailShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  InstapaperShareButton,
} from "react-share";
import {
  EmailIcon,
  WhatsappIcon,
  TwitterIcon,
  FacebookMessengerIcon,
  InstapaperIcon,
} from "react-share";
import { MdOutlineRateReview } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
function UsermarktplacePage() {
  const [details, setDetails] = useState({});
  const igUserName = details?.userHandle;
  const engagement_rate = details?.engagement_rate;
  const price = details?.price;
  const categories = details?.categories;
  const followers = details?.followers;
  const engagement_rate_per_post = details?.engagement_rate_per_post;
  const { id1, platform } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const params=useParams();
  //extact theurl of this page
  const shareLink =
    "Check out our marketplace and get more insights. " + window.location.href;


  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.cloutin.co/api/influencerMarketplaceInd/${id1}/${platform}`
        );
        setLoading(false);
        setDetails(response?.data);
      } catch (error) {

        setDetails("No Data");
        setLoading(false);
      }
    };
    fetchDetails();
  }, [navigate]);

  const [micro, setMicro] = useState(false);
  const [nano, setNano] = useState(true);
  const [mega, setMega] = useState(false);
  const [any, setAny] = useState(false);
  const [name, setName] = useState("");
  const [mid, setMid] = useState(false);
  const [macro, setMacro] = useState(false);
  // const [post, setPost] = useState(true);
  // const [story, setStory] = useState(false);
  // const [reels, setReels] = useState(false);
  const [category, setCategory] = useState("");

  const [desc, setDesc] = useState("");
  const [type, setType] = useState("Post");
  const [summary, setSummary] = useState("");
  const [url, setUrl] = useState("");
  const [requiredInfluencers, setRequiredInfluencers] = useState("");
  const [cpm, setCpm] = useState("");
  const [campaign_tracking_type, setCampaignTrackingType] = useState("");
  // const [cost, setCost] = useState("");
  // const [visible1, setVisible1] = useState(false);
  // const [visible2, setVisible2] = useState(false);
  const [cover, setCover] = useState("");
  const [sample, setSample] = useState("");
  const [file, setFile] = useState([]);
  const [selectedImg1, setSelectedImg1] = useState("");
  const [previewSource1, setPreviewSource1] = useState("");
  const [selectedImg2, setSelectedImg2] = useState("");
  const [previewSource2, setPreviewSource2] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [fileInputState1, setFileInputState1] = useState("");
  const [fileInputState2, setFileInputState2] = useState("");
  const [isBarterChecked, setIsBarterChecked] = useState(false);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const handleBarterChange = () => {
    setIsBarterChecked(!isBarterChecked);
  };
  const handleFileInputChange1 = (e) => {
    const files = e.target.files;
    setFile(files);
    previewFile1(files[0]);
    setSelectedImg1(files[0]);
    setFileInputState1(e.target.value);
    setDesc("");
  };

  const handleFileInputChange2 = (e) => {
    const file = e.target.files[0];
    previewFile2(file);
    setSelectedImg2(file);
    setFileInputState2(e.target.value);
  };

  const previewFile1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource1(reader.result);
    };
  };

  const previewFile2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource2(reader.result);
    };
  };

  const handleImage1 = (e) => {
    e.preventDefault();
    if (!selectedImg1) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg1);
    for (let i = 0; i < file.length; i++) {
      const reader = new FileReader();

      reader.readAsDataURL(file[i]);

      reader.onloadend = () => {
        uploadImage1(reader.result);
      };
    }
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const handleImage2 = (e) => {
    e.preventDefault();
    if (!selectedImg2) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg2);
    reader.onloadend = () => {
      uploadImage2(reader.result);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const uploadImage1 = async (base64EncodedImage) => {
    try {
      setLoading1(true);
      const { data } = await axios.post(
        "https://api.cloutin.co/api/campaign/cover",
        { data: base64EncodedImage },
        config
      );
 
      setFileInputState1("");
      setPreviewSource1("");
      setCover(data.url);
      setLoading1(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const uploadImage2 = async (base64EncodedImage) => {
    try {
      setLoading2(true);
      const { data } = await axios.post(
        "https://api.cloutin.co/api/campaign/sample",
        { data: base64EncodedImage },
        config
      );
      setFileInputState2("");
      setPreviewSource2("");
      setSample(data.url);
      setLoading2(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const create = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.cloutin.co/api/campaign",
        {
          campaignId: "645d13e447e2a32803e2d8c3",
          name: name,
          company_mail: "nivedtester10@gmail.com",
          category: category,
          platform: platform,
          description: desc,
          type: type,
          barter: isBarterChecked,
          product_price: productPrice,
          placement: "Placement",
          url: url,
          redirect_url: url,
          instruction: summary,
          requiredInfluencers: requiredInfluencers,
          cpm: cpm,
          cover_image_url: cover,
          sample_image_url: sample,
          cost: cpm,
          userId: "645d13e447e2a32803e2d8c3",
          status: "Active",
          Influencers: ["645d13e447e2a32803e2d8c3"],
          status_code: 200,
          // cost: cost,
        },
        config
      );
  
      if (response.status == 200) {
        toast.success("Campaign created successfully");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      // console.log(error);
      toast.error("Invalid Url");
    }
  };

  const [isValidUrl, setIsValidUrl] = useState(true);

  const handleUrlChange = (event) => {
    const inputUrl = event.target.value;
    setUrl(inputUrl);
    const urlPattern =
      /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    const isValid = urlPattern.test(inputUrl);
    setIsValidUrl(isValid);
  };

  const [image, setImage] = useState(null);
  const [pack, setPack] = useState([]);
  const [popularPackage, setPopularPackage] = useState([]);
  // const [popularPackage, setPopularPackage] = useState({});
  useEffect(() => {
    setImage(localStorage.getItem("imagelink"));
    getAllPackages(id1, setPack, setPopularPackage, platform);
  
  }, []);
  const getinfluencers = (popular, name, price, id) => {
    const params = new URLSearchParams();
    params.append("name", name);
    params.append("price", price);
    params.append("id", id);

    // navigate(`/influencerinfo/${id1}`);
    navigate(`/influencerinfo/${id1}?popular=${popular}&${params.toString()}`);
    // e.preventDefault();

  };

  const [rating, setRating] = useState(4);
  const [influencerReview, setInfluencerReview] = useState([]);

  // console.log("inforid", id1);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const postdata = {
          inf_id: id1,
        };
        const apiurl = "https://api.cloutin.co/api/review/getreviews";
        const response = await axios.post(apiurl, postdata);
        // console.log("reviewsssdata", response.data);
        setInfluencerReview(response?.data?.reviews);
      } catch (error) {
        // console.log(error);
        toast.error("Error in fetching reviews");
      }
    };
    fetchData();
  }, []);

  // console.log("influencerReview", influencerReview);
  // console.log("text", influencerReview[0]?.reviews[0]?.review);

  const [visibleReviews, setVisibleReviews] = useState(5);

  const handleSeeMore = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 5);
  };
  function removeSpecialCharacters(word) {
    const regex = /[^a-zA-Z0-9]/g;
    // Replace non-alphanumeric characters with an empty string
    return word.key.replace(regex, " ").toUpperCase();
  }

  const params = useParams();
  const [selectedPackage, setSelectedPackage] = useState("choose");
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectPackage = (packageType) => {
    if (packageType === "youtube" && params.platform !== "youtube") {
      navigate(
        `/single/${params.id1}/${params.igUserName}/${
          params.engagement_rate || 0
        }/youtube/${params.price}/${params.categories}/${params.followers}`
      );
      setLoading(true);
    } else if (packageType === "instagram" && params.platform !== "instagram") {
      navigate(
        `/single/${params.id1}/${params.igUserName}/${
          params.engagement_rate || 0
        }/instagram/${params.price}/${params.categories}/${params.followers}`
      );
      setLoading(true);
    }
    setSelectedPackage(packageType);
    setIsOpen(false);
  };
  const token = localStorage.getItem("token");
  const { currentUser } = useSelector((state) => state.user);

  const [showModal, setShowModal] = useState(false);

  const handleShareClick = () => {
    setShowModal(!showModal);
  };

  return (
    <div className=" p-[20px] max-w-7xl m-auto mt-4 md:p-2 8k:overflow-y-auto h-auto border border-blue-400 rounded-md border-l-2 border-t-2 px-2 ">
      <div className="text-center mb-3 flex items-center justify-center gap-3">
        <h1 className="text-xl text-green-500 font-semibold uppercase underline">
          {igUserName}
        </h1>
        <h1>
          {platform === "instagram" ? (
            <img src="/Images/insta.png" className="h-5 w-5" />
          ) : (
            <span className="text-red-500">
              <IoLogoYoutube />
            </span>
          )}
        </h1>
        <button
          className=" rounded-full p-2 shadow-md"
          onClick={handleShareClick}
        >
          <IoIosShareAlt />
        </button>
        {showModal && <ShareModal shareLink={shareLink} />}
      </div>
      {loading && <Loader loadingText={"Data"} />}
      {params.price !== "price" ? (
        <div className="flex justify-end pb-2 mb-2">
          <button
            onClick={handleToggle}
            className="bg-blue-500 text-white font-sm px-2 py-1 rounded-md"
          >
            {selectedPackage === "instagram"
              ? " Instagram Packages"
              : selectedPackage === "youtube"
              ? "YouTube Packages"
              : "Choose Platforms"}
            <KeyboardArrowDownOutlined />
          </button>
          {isOpen && (
            <div className="absolute mt-10 w-56 rounded-md shadow-lg bg-[#F5F5F5] z-10">
              <button
                className="block py-2 px-4 text-gray-800 hover:bg-gray-200 w-full text-left"
                onClick={() => handleSelectPackage("instagram")}
              >
                View Instagram Packages
              </button>
              <button
                className="block py-2 px-4 text-gray-800 hover:bg-gray-200 w-full text-left"
                onClick={() => handleSelectPackage("youtube")}
              >
                View YouTube Packages
              </button>
            </div>
          )}
        </div>
      ) : null}

      <>
        {/* <div className=" md:flex md:space-y-0 space-y-[10px] gap-[10px] 8k:w-[100%] 8k:space-x-0 4k:w-[100%] "> */}
        <div className="md:flex md:space-y-0 space-y-10 md:gap-10 w-full mb-[20px]">
          {/* first section  */}
          {/* <div className=" flex flex-col items-center 5k:h-[30vw] md:w-[90%] tk:h-[37vw] md:h-[48vw] lg:h-[44vw] bg-white h-fit rounded-lg  8k:p-5 8k:w-screen 8k:h-fit 4k:h-[30vw] 3xl:h-[34vw] rk:h-[32vw] 1440k:h-[43vw] lgk:h-[51vw] shadow-md hover:shadow-lg"> */}
          <div className="flex flex-col items-center bg-white rounded-lg shadow-md hover:shadow-lg">
            <div className="flex flex-row px-[10px] pt-[20px] 8k:gap-6 1440k:gap-4 border border-gray-100 rounded-md pb-2">
              <img
                className="rounded-md"
                src={`/Images/${image}`}
                alt="integrate"
              />
            </div>
            <div className=" w-full space-y-[10px] py-2">
              <div className=" w-full xl:flex justify-start gap-3 px-[10px] 8k:pt-3">
                {details?.packages?.map((i) => (
                  <>
                    {Object.entries(i?.deliverables[0]).map(
                      ([key, value]) =>
                        value === "true" && (
                          <div className=" flex">
                            <span className="text-blue-500 8k:text-2xl text-sm bg-white shadow-md text-center px-2 rounded-md  border-t-2 border-blue-400">
                              {removeSpecialCharacters({ key })}
                            </span>
                          </div>
                        )
                    )}
                  </>
                ))}
              </div>
              <div className=" flex space-x-[10px] px-[10px]  items-center">
                <h1 className="font-bold 8k:text-2xl mx-[10px] ">
                  {categories}
                </h1>
                <div className="flex">
                  {details?.overall_ratings === 0 ? (
                    <h1 className="text-gray-500">No rating yet</h1>
                  ) : (
                    <>
                      {Array.from(
                        { length: Math.floor(details?.overall_ratings) },
                        (_, i) => (
                          <StarIcon key={i} style={{ color: "#FFD700" }} />
                        )
                      )}
                      {details?.overall_ratings % 1 !== 0 && (
                        <StarHalfIcon style={{ color: "#FFD700" }} />
                      )}
                    </>
                  )}
                </div>
                <img
                  className="w-5 h-5"
                  src={`${
                    platform === "youtube"
                      ? "/Images/youtube.png"
                      : "/Images/insta.png"
                  }`}
                  alt="insta"
                />
              </div>
              <p className="px-[10px] text-[#495057] pb-[10px] 8k:text-xl text-md font-normal">
                I'll feature your product in an{" "}
                {platform === "instagram"
                  ? "Instagram Reel"
                  : "youtube video/short"}{" "}
                on {igUserName} by demonstrating or using it.
              </p>
            </div>
          </div>
          {/* second right section  */}
        </div>

        <div>
          <div className="flex justify-evenly flex-wrap ">
            <div className="w-full lg:w-[255px] rounded-lg bg-white p-4 shadow-md hover:shadow-xl border-b-2 border-r-2 border-blue-400 cursor-pointer">
              <h1 className="text-gray-600 text-xl">Followers</h1>
              <h1 className="text-3xl">{followers}</h1>
            </div>
            <div className="w-full lg:w-[255px] rounded-lg bg-white p-4 shadow-md hover:shadow-xl border-b-2 border-r-2 border-blue-400 cursor-pointer">
              <h1 className="text-gray-600 text-xl">Avg. Eng. Per Post</h1>
              <h1 className="text-3xl">
                {engagement_rate_per_post?.toFixed(4)}%
              </h1>
            </div>
            <div className="w-full lg:w-[255px] rounded-lg bg-white p-4 shadow-md hover:shadow-xl border-b-2 border-r-2 border-blue-400 cursor-pointer">
              <h1 className="text-gray-600 text-xl">Eng. Rate</h1>
              <h1 className="text-3xl">{engagement_rate?.toFixed(3)}%</h1>
            </div>
          </div>
        </div>
        <div className="mt-[2rem] flex items-center">
          {popularPackage?.map((item) => (
            // <div className=" bg-white md:w-1/2 p-[20px]  rounded-lg shadow-md hover:shadow-lg">
            <div className="flex items-center justify-center">
              <div className="bg-white md:w-1/2 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg border-t-2 border-black ">
                <div className="text-bold border border-x-0 border-t-0 pb-[10px] 8k:text-3xl 8k:font-bold">
                  Listing Overview
                </div>
                <div className="flex border border-x-0 border-t-0 text-[#495057] space-y-[10px] flex-col pt-[20px] pb-5">
                  <div className="flex justify-between 8k:text-xl 4k:text-[1vw]">
                    <div className="flex flex-col space-y-[5px]">
                      <div>DEAL</div>
                      <div className="font-bold text-black text-sm">
                        {item?.name}
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row text-sm">
                          <img
                            className="object-scale-down h-5"
                            src="/Images/cali.png"
                            alt="calendar"
                            height="2px"
                          ></img>
                          <pre> </pre>15 days Delivery
                        </div>
                        <div className="flex flex-row text-sm">
                          <img
                            className="object-scale-down h-5"
                            src="/Images/C_vector.png"
                            alt="calendar"
                            height="2px"
                          ></img>
                          <pre> </pre>Content Rights
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <div className="flex flex-row text-sm">
                          <img
                            className="object-scale-down h-5"
                            src="/Images/shield.png"
                            alt="calendar"
                            height="2px"
                          ></img>
                          <pre> </pre>Satisfaction Guaranteed
                        </div>
                      </div>
                    </div>
                    <div className="font-bold text-black pt-6">
                      Rs {item?.price}
                    </div>
                  </div>
                </div>
                <div className="flex text-[#495057] space-y-[10px] flex-col pt-[10px]">
                  <div className="flex justify-between 8k:text-xl 4k:text-[1vw] font-bold text-black">
                    <div>Subtotal</div>
                    <div>Rs {item?.price}</div>
                  </div>
                  <div className="flex justify-between 8k:text-xl 4k:text-[1vw] font-bold text-black">
                    <div>Total Delivery Time</div>
                    <div className="flex items-center">
                      <img
                        className="w-5 h-5 mr-2"
                        src="/Images/cali.png"
                        alt="calender"
                      />
                      <h1>15 days</h1>
                    </div>
                  </div>
                </div>
                {/* //popup  */}
                <div className="bg-[#0b84ca] text-center cursor-pointer my-[20px] rounded-lg py-[10px] px-[10px] text-white 8k:text-2xl 8k:mt-9">
                  <Popup
                    trigger={
                      <button
                        className="button"
                        onClick={() => {
                          if (currentUser === null) {
                            toast.error("Please login first!!");
                            setTimeout(() => {
                              navigate("/login");
                            }, 1000);
                          }
                        }}
                      >
                        Get Influencer
                      </button>
                    }
                    modal
                    nested
                    closeOnDocumentClick={false}
                  >
                    {(close) => (
                      <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-blue-600 bg-opacity-10">
                        <div className="modal-content relative bg-white p-4 rounded-lg shadow-lg w-96">
                          <button
                            className="close absolute top-0 right-0 -mt-4 -mr-4 w-10 h-10 bg-white rounded-full p-2 sm:w-10 sm:h-10 md:w-12 md:h-12"
                            onClick={close}
                          >
                            &times;
                          </button>
                          <div className="header text-xl font-semibold mb-4 text-silver-600">
                            Listing Overview
                          </div>
                          <hr className="my-4 border-t-2 border-gray-500" />

                          <div className="content text-[#495057] text-md font-normal">
                            <div className="mb-4">
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold text-black">
                                    Deal
                                  </span>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold text-silver-600">
                                    {item?.name}
                                  </span>
                                </div>
                                <div className="flex flex-row text-sm">
                                  <span className="font-bold text-red-500">
                                    Rs {item?.price}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between mb-4">
                              <div className="flex flex-row text-sm">
                                <img
                                  className="object-scale-down h-5"
                                  src="/Images/cali.png"
                                  alt="calendar"
                                  height="2px"
                                ></img>
                                <pre> </pre>15 days Delivery
                              </div>
                              <div className="flex flex-row text-sm">
                                <pre> </pre>Content Rights
                              </div>
                            </div>
                            <div className="flex flex-row mb-4">
                              <div className="flex flex-row text-sm">
                                <pre> </pre>Satisfaction Guaranteed
                              </div>
                            </div>
                            <hr className="my-4 border-t-2 border-gray-500" />
                            <div className="flex flex-row justify-between mb-4">
                              <div className="flex flex-row text-sm">
                                <pre> </pre>
                                <span className="font-bold text-gray-600">
                                  Subtotal
                                </span>
                              </div>
                              <div className="flex flex-row text-sm">
                                <span className="font-bold text-black">
                                  Rs {item?.price}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between mb-4">
                              <div className="flex flex-row text-sm">
                                <pre> </pre>
                                <span className="font-bold text-silver-600">
                                  Total Delivery Time
                                </span>
                              </div>
                              <div className="flex flex-row text-sm">
                                <div className="flex items-center">
                                  <h1 className="font-bold text-gray-600">
                                    15 days
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between mb-4">
                              <div className="flex flex-row text-sm">
                                <pre> </pre>
                                <span className="font-bold text-black">
                                  Name
                                </span>
                              </div>
                              <div className="flex flex-row text-sm">
                                <span className="font-bold text-silver-600">
                                  {igUserName}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between mb-4">
                              <div className="flex flex-row text-sm">
                                <pre> </pre>
                                <span className="font-bold text-gray-600">
                                  Platform
                                </span>
                              </div>
                              <div className="flex flex-row text-sm">
                                <span className="text-black">
                                  <img
                                    src={`${
                                      platform === "youtube"
                                        ? "/Images/youtube.png"
                                        : "/Images/insta.png"
                                    }`}
                                    alt="insta"
                                    className="w-5 h-5"
                                  />
                                  {/* {platform} */}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between mb-4">
                              <div className="flex flex-row text-sm">
                                <pre> </pre>
                                <span className="font-bold text-silver-600">
                                  Tax Price
                                </span>
                              </div>
                              <div className="flex flex-row text-sm">
                                <span className="text-gray-600">Rs 100</span>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between mb-4">
                              <div className="flex flex-row text-sm">
                                <pre> </pre>
                                <span className="font-bold text-black">
                                  Wallet
                                </span>
                              </div>
                              <div className="flex flex-row text-sm">
                                <span className="text-silver-600">Rs 100</span>
                              </div>
                            </div>
                          </div>
                          <div className="bg-[#00A3FF] text-center cursor-pointer rounded-lg">
                            <button
                              className="text-white text-sm lg:text-base py-2 px-4 lg:px-4"
                              onClick={() => {
                                if (currentUser === null) {
                                  toast.error("Please login first!!");
                                  setTimeout(() => {
                                    navigate("/login");
                                  }, 1000);
                                } else {
                                  getinfluencers(
                                    "popular",
                                    item?.name,
                                    item?.price,
                                    item?._id
                                  );
                                }
                              }}
                            >
                              Get Influencer
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
                <p className="lgk:text-[0.1vw] text-xs">
                  <span className="font-bold">
                    You won't be charged until after you click{" "}
                    <span className="text-blue-500 text-xs">
                      'Get Influencer'
                    </span>
                    .
                  </span>
                  <span className="text-[#6C757D] text-xs">
                    If the seller doesn't respond in 7 days, the order will be
                    automatically cancelled and your payment will be refunded.
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* //about part  */}

        {/* middle section starts */}

        {/* <div className=" flex lg:justify-start justify-between lg:w-[85%] mt-[40px] mb-[10px] 8k:justify-between 8k:justify-between 1440k:justify-between 1440k:pb-2  3xl:justify-between  4k:justify-between rk:justify-between lgk:justify-between">
          <h1 className="font-extrabold 8k:text-3xl 4k:text-[1.5vw] 3xl:text-[1.4vw]">
            <span>About</span> {igUserName}
          </h1>
          <img
            className="w-5 h-5 lg:ml-[20px] 8k:w-9 8k:h-9 3xl:w-[1.5vw] 3xl:h-[1.5vw]  4k:w-[1.5vw] 4k:h-[1.5vw]  "
            src={`${
              platform === "youtube"
                ? "/Images/youtube.png"
                : "/Images/insta.png"
            }`}
            alt="logo"
          />
        </div>
        <div className=" grid lg:w-[85%] xl:grid-cols-3 gap-28 lg:grid-cols-2 grid-cols-1 8k:mt-9 ">
          <div className="2xl:w-[200px] xl:w-[190px] lg:w-[255px] space-y-[10px] rounded-lg bg-white p-[20px] 8k:w-[25vh] 1440k:w-[20vw] 4k:w-[20vw] 3xl:w-[15vw] 3xl:text-[1.2vw] rk:w-[20vw] shadow-md hover:shadow-xl">
            <h1 className="text-[#6C757D] 8k:text-3xl 1440k:text-xl  ">
              Followers
            </h1>
            <h1 className="8k:text-3xl 1440k:text-xl">{followers}</h1>
          </div>

          <div className="2xl:w-[200px] xl:w-[190px] lg:w-[255px] space-y-[10px] rounded-lg bg-white p-[20px] 8k:w-[25vh] 1440k:w-[20vw] 4k:w-[20vw] 3xl:w-[15vw] 3xl:text-[1.2vw] rk:w-[20vw] shadow-md hover:shadow-xl">
            <h1 className="text-[#6C757D] 8k:text-3xl 1440k:text-xl ">
              Avg. Eng. Per Post
            </h1>
            <h1 className="8k:text-3xl 1440k:text-xl">
              {engagement_rate_per_post}%
            </h1>
          </div>

          <div className="2xl:w-[200px] xl:w-[190px] lg:w-[255px] space-y-[10px] rounded-lg bg-white p-[20px] 8k:w-[25vh] 1440k:w-[20vw] 4k:w-[20vw] 3xl:w-[15vw] 3xl:text-[1.2vw] rk:w-[20vw] shadow-md hover:shadow-xl">
            <h1 className="text-[#6C757D] 8k:text-3xl 1440k:text-xl ">
              Eng. Rate
            </h1>
            <h1 className="8k:text-3xl 1440k:text-xl">{engagement_rate}%</h1>
          </div>
        </div> */}

        {/* middle section ends */}

        {/* choose you package starts */}
        <h1 className="font-extrabold mt-[20px] mb-[10px] 8k:text-3xl 8k:mt-9 8k:mb-8  4k:text-[1.5vw] 3xl:text-[1.4vw] 4k:pt-[1vw]">
          Choose Your Package!
        </h1>
        <div className=" grid xl:grid-cols-3 justify-center items-center w-full gap-[20px] lg:grid-cols-2  8k:mb-9">
          {/* 1 start  */}

          {details?.packages?.map((item, index) => (
            <div className="2xl:w-[260px] xl:w-[290px] w-[350px] flex flex-col h-full justify-between p-[20px] rounded-lg bg-white 8k:w-[30vh] 4k:w-[20vw]  3xl:w-[80%] shadow-md hover:shadow-xl border-t-2 border-blue-400">
              <h1 className="text-[32px] mb-[10px] 8k:text-5xl 1440k:text-3xl">
                {item.name}
                {/* {item._id} */}
              </h1>
              <div className="flex justify-between mb-[20px] items-center">
                <h1 className="8k:text-2xl">
                  Rs{item.price}/<span className="text-[#ACB4C3]">Month</span>
                </h1>
                {index === Math.floor(pack.length / 2) && (
                  <div className="text-blue-500 rounded-2xl px-3 py-1 bg-[#F0F5FF] 8k:text-2xl">
                    popular
                  </div>
                )}
              </div>
              {item?.deliverables[0]["ig_post"] === "true" ? (
                <div className="flex text-[#ACB4C3]">
                  <img
                    className="w-6 h-6 mr-2 8k:w-9 8k:h-9"
                    src="/Images/checkbox.png"
                    alt="checkbox"
                  />
                  <h1 className="8k:text-2xl">Posts</h1>
                </div>
              ) : (
                <div></div>
              )}
              {item?.deliverables[0]["ig_reels"] === "true" ? (
                <div className="flex text-[#ACB4C3]">
                  <img
                    className="w-6 h-6 mr-2 8k:w-9 8k:h-9"
                    src="/Images/checkbox.png"
                    alt="checkbox"
                  />
                  <h1 className="8k:text-2xl">Reels</h1>
                </div>
              ) : (
                <div></div>
              )}
              {item?.deliverables[0]["yt_video"] === "true" ? (
                <div className="flex text-[#ACB4C3]">
                  <img
                    className="w-6 h-6 mr-2 8k:w-9 8k:h-9"
                    src="/Images/checkbox.png"
                    alt="checkbox"
                  />
                  <h1 className="8k:text-2xl">Video</h1>
                </div>
              ) : (
                <div></div>
              )}
              {item?.deliverables[0]["yt_shorts"] === "true" ? (
                <div className="flex text-[#ACB4C3]">
                  <img
                    className="w-6 h-6 mr-2 8k:w-9 8k:h-9"
                    src="/Images/checkbox.png"
                    alt="checkbox"
                  />
                  <h1 className="8k:text-2xl">Shorts</h1>
                </div>
              ) : (
                <div></div>
              )}
              {item?.deliverables[0]["ig_story"] === "true" ? (
                <div className="flex my-[20px] text-[#ACB4C3]">
                  <img
                    className="w-6 h-6 mr-2 8k:w-9 8k:h-9"
                    src="/Images/checkbox.png"
                    alt="checkbox"
                  />
                  <h1 className="8k:text-2xl">Stories</h1>
                </div>
              ) : (
                <div></div>
              )}
              <div className="flex text-[#ACB4C3]">
                <img
                  className="w-6 h-6 mr-2"
                  src="/Images/checkbox.png"
                  alt="checkbox"
                />
                <h1 className="8k:text-2xl">Priority Support</h1>
              </div>
              <div className="border my-[40px]"></div>

              <div
                className="border text-center mb-[20px] py-3 rounded-lg 8k:text-3xl cursor-pointer bg-blue-500 text-white border-none outline-none hover:bg-white hover:text-black hover:rounded-lg hover:shadow-md"
                onClick={(e) => {
                  if (currentUser === null) {
                    toast.error("Please login first!!");
                    setTimeout(() => {
                      navigate("/login");
                    }, 1000);
                  } else {
                    getinfluencers(
                      index === Math.floor(pack.length / 2)
                        ? "popular"
                        : "notpopular",
                      item?.name,
                      item?.price,
                      item?._id
                    );
                  }
                }}
              >
                Get Influencer
              </div>
            </div>
          ))}
        </div>
        {/* choose you package ends */}

        {/* review section starts */}
        <div className=" my-4">
          <div className="flex  items-center gap-2 pb-2">
            <h1 className="text-2xl">Reviews</h1>
            <span>
              <MdOutlineRateReview size={25} />
            </span>
          </div>
          {influencerReview && influencerReview.length > 0 ? (
            <>
              <div className="border border-gray-600 px-3 shadow-sm rounded-md">
                {influencerReview
                  .slice(0, visibleReviews)
                  .map((review, index) => (
                    <div className="pb-6" key={index}>
                      <div className="flex items-center justify-start gap-3 my-2">
                        <div className="w-8 h-8">
                          <img
                            className="w-100 h-100"
                            src="https://images-eu.ssl-images-amazon.com/images/S/amazon-avatars-global/default._CR0,0,1024,1024_SX48_.png"
                            alt="Avatar"
                          />
                        </div>
                        <h1 className="text-xl">{review?.review?.name}</h1>
                      </div>
                      <div className="my-1">
                        {[...Array(5)].map((_, index) => (
                          <span key={index}>
                            {review?.reviews[0]?.rating >= index + 1 ? (
                              <StarIcon
                                style={{
                                  color: "#ffa41c",
                                  fontSize: "35px",
                                }}
                              />
                            ) : review.rating >= index + 0.5 ? (
                              <StarHalfIcon
                                style={{
                                  color: "#ffa41c",
                                  fontSize: "35px",
                                }}
                              />
                            ) : (
                              <StarIcon
                                style={{
                                  color: "gray",
                                  fontSize: "35px",
                                }}
                              />
                            )}
                          </span>
                        ))}
                      </div>
                      <div>
                        <h1>{review.date}</h1>
                      </div>
                      <div className="my-1">
                        <p className="text-[#535353]">
                          {/* ?.reviews[0]?.review */}
                          {review?.reviews[0]?.review}
                        </p>
                      </div>
                    </div>
                  ))}
                {visibleReviews < influencerReview.length && (
                  <button onClick={handleSeeMore}>See More</button>
                )}
              </div>
            </>
          ) : (
            <h1 className="text-gray-400 text-2xl">No Reviews Yet!! ☹</h1>
          )}
        </div>
      </>

      {/* review section ends */}
    </div>
  );
}

export default UsermarktplacePage;

export function ShareModal({ shareLink }) {
  return (
    <div className="modal">
      <div className="modal-content flex items-center gap-3">
        <EmailShareButton url={shareLink}>
          <EmailIcon size={32} round />
        </EmailShareButton>
        <WhatsappShareButton url={shareLink}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <TwitterShareButton url={shareLink}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <FacebookMessengerShareButton url={shareLink}>
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
        <InstapaperShareButton url={shareLink}>
          <InstapaperIcon size={32} round />
        </InstapaperShareButton>
      </div>
    </div>
  );
}
