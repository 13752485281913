import React from "react";
import { motion } from "framer-motion";
import appScreenShot from "../imgs/appScreenShot.jpg";

const ExploreApp = () => {
  const features = [
    "Seamless influencer-brand matching",
    "Real-time analytics dashboard",
    "Automated campaign management",
    "Secure payment processing",
    "AI-powered content suggestions",
  ];

  return (
    <div className="bg-gradient-to-b from-white to-blue-50">
      <div className="bg-gradient-to-b from-white to-blue-50 py-20 max-w-7xl mx-auto">
        <div className="px-4 sm:px-6 lg:px-8">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl sm:text-5xl font-bold text-center mb-16"
          >
            Let's Explore Our App
          </motion.h2>

          <div className="flex flex-col lg:flex-ro sm:flex-row items-center justify-between gap-2">
            <motion.div
              className="w-full lg:w-1/2"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <div className="relative max-w-md mx-auto">
                <img
                  src={appScreenShot}
                  alt="App Screenshot"
                  className="relative z-10 rounded-2xl shadow-2xl max-w-full max-h-[400px] object-contain"
                />
              </div>
            </motion.div>
            {/* Features List */}
            <motion.div
              className="w-full lg:w-1/2"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <h3 className="text-3xl font-semibold mb-8">Key Features</h3>
              <ul className="space-y-6">
                {features.map((feature, index) => (
                  <motion.li
                    key={index}
                    className="flex items-start"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <svg
                      className="w-6 h-6 text-green-500 mr-4 flex-shrink-0"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                    <span className="text-xl text-gray-700">{feature}</span>
                  </motion.li>
                ))}
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-12 px-8 py-4 bg-blue-600 text-white text-xl font-semibold rounded-full shadow-md hover:bg-blue-700 transition duration-300"
              >
                Start Free Trial
              </motion.button>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreApp;
