import axios from 'axios';
import React, { useState } from 'react'

function EmailVerificationComponent({currentUserId}) {


    const [verificationStatus, setVerificationStatus] = useState(null); // null, 'sending', 'success', 'error'

    const handleVerify = async () => {
      setVerificationStatus('sending');
      try {
        const response = await axios.post('https://api.cloutin.co/api/verify/user/mail', {
          currentUserId
        });
        console.log(response);
        setVerificationStatus('success');
      } catch (err) {
        console.log(err);
        setVerificationStatus('error');
      }
    };


  return (
    <div className="w-full bg-blue-100 text-center font-semibold p-4 rounded-md shadow-md">
    <p className="text-blue-700">
      {verificationStatus === 'sending' && 'Sending verification mail...'}
      {verificationStatus === 'success' && 'Verification mail sent! Please check your email.'}
      {verificationStatus === 'error' && 'Error sending verification mail. Please try again.'}
      {verificationStatus === null && (
        <>
          Your Email is not verified yet... Please 
          <span
            className="text-blue-700 bg-white rounded-lg p-2 ml-2 cursor-pointer hover:bg-blue-50 hover:text-blue-900 transition-all duration-300 ease-in-out"
            onClick={handleVerify}
          >
            Verify
          </span>
        </>
      )}
    </p>
  </div>
  )
}

export default EmailVerificationComponent