import React, { useState, useEffect, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  query,
  collection,
  onSnapshot,
  addDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { firestore } from "../firebase";

const ChatBox = ({ chatId, influencerId, companyId }) => {
  const [chats, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [showChat, setShowChat] = useState(false);
  const chatBoxRef=useRef(null)

  // const chatId = "65c255e4d12c7b42d8c09c0065bf966ec89158803b6f49e4"; // replace it with chat id.Chat id format for marketPlace is {{marketPlace._id}{business._id}{influencer._id}}.Chat id format for Campaign is {{campaign._id}{business._id}{influencer._id}}

  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(firestore, "user", "chats", chatId),
        orderBy("time", "asc")
      );

      const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
        const fetchedMessages = [];
        QuerySnapshot.forEach((doc) => {
          fetchedMessages.push({ ...doc.data(), id: doc?.id });
        });
        const sortedMessages = fetchedMessages.sort(
          (a, b) => b.createdAt - a.createdAt
        );
        setMessages(sortedMessages);
      });

      return () => unsubscribe;
    }

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
        setShowChat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [chatBoxRef]);

  const handleMessageSend = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(firestore, "user", "chats", chatId), {
        from: companyId, // Replace with business id
        to: influencerId, // Replace with influencer id
        message: messageText,
        likedMessageUser: [],
        time: serverTimestamp(),
      });

      // Clear input fields after sending message
      setMessageText("");
      console.log("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="relative ">
      <button
        onClick={() => setShowChat(!showChat)}
        className="bg-[#3b82f6] px-2 py-2 rounded-full df"
      >
        <QuestionAnswerIcon style={{ fontSize: "25px", color: "white" }} />
      </button>
      {showChat ? (
        <div ref={chatBoxRef} className="border z-50 border-[#c0c0c0] fixed right-[20px] bottom-5 h-[390px] rounded-lg w-[290px] flex flex-col justify-between pb-2 shadow-lg bg-white">
          <div className="overflow-y-scroll  h-full ">
            {chats &&
              chats.map((i) => (
                // companyId !== i.from
                <div
                  className={
                    companyId === i.from ? `text-end px-4` : "text-start px-4"
                  }
                >
                  <h1 className="my-1 px-4 rounded-full bg-blue-600 py-1 inline-block text-white">
                    {i.message}
                  </h1>
                </div>
              ))}
          </div>

          <div className=" flex justify-between  px-2">
            <input
              type="text"
              className="w-[85%] rounded-lg pl-3  bg-[#e5e5e5]"
              placeholder="Enter Your Message"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />
            <button
              type="submit"
              onClick={handleMessageSend}
              className="bg-[#3b82f6] px-[5px] py-[5px] rounded-full df"
            >
              <SendIcon style={{ background: "#3b82f6", color: "white" }} />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChatBox;

// import React, { useState, useEffect, useRef } from "react";
// import SendIcon from "@mui/icons-material/Send";
// import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
// import {
//   query,
//   collection,
//   onSnapshot,
//   addDoc,
//   serverTimestamp,
//   orderBy,
// } from "firebase/firestore";
// import { firestore } from "../firebase";

// const ChatBox = ({ chatId, influencerId, companyId }) => {
//   const [chats, setMessages] = useState([]);
//   const [messageText, setMessageText] = useState("");
//   const [showChat, setShowChat] = useState(false);
//   const chatBoxRef = useRef(null);

//   useEffect(() => {
//     async function fetchData() {
//       const q = query(
//         collection(firestore, "user", "chats", chatId),
//         orderBy("time", "asc")
//       );

//       const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
//         const fetchedMessages = [];
//         QuerySnapshot.forEach((doc) => {
//           fetchedMessages.push({ ...doc.data(), id: doc?.id });
//         });
//         const sortedMessages = fetchedMessages.sort(
//           (a, b) => b.createdAt - a.createdAt
//         );
//         setMessages(sortedMessages);
//       });

//       return () => unsubscribe;
//     }

//     fetchData();
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
//         setShowChat(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [chatBoxRef]);

//   const handleMessageSend = async (e) => {
//     e.preventDefault();
//     try {
//       await addDoc(collection(firestore, "user", "chats", chatId), {
//         from: companyId,
//         to: influencerId,
//         message: messageText,
//         likedMessageUser: [],
//         time: serverTimestamp(),
//       });

//       setMessageText("");
//       console.log("Message sent successfully!");
//     } catch (error) {
//       console.error("Error sending message:", error);
//     }
//   };

//   return (
//     <div className="relative">
//       <button
//         onClick={() => setShowChat(!showChat)}
//         className="bg-[#3b82f6] hover:bg-[#2563eb] transition duration-300 px-2 py-2 rounded-full flex items-center justify-center shadow-md"
//       >
//         <QuestionAnswerIcon style={{ fontSize: "25px", color: "white" }} />
//       </button>
//       {showChat ? (
//         <div
//           ref={chatBoxRef}
//           className="border z-50 border-[#c0c0c0] fixed right-5 bottom-5 h-[390px] rounded-lg w-[300px] flex flex-col justify-between pb-2 shadow-lg bg-white"
//         >
//           <div className="overflow-y-scroll p-2 h-full space-y-2">
//             {chats &&
//               chats.map((i) => (
//                 <div
//                   key={i.id}
//                   className={
//                     companyId === i.from ? "text-right px-4" : "text-left px-4"
//                   }
//                 >
//                   <h1
//                     className={`inline-block rounded-full py-1 px-4 ${
//                       companyId === i.from
//                         ? "bg-blue-600 text-white"
//                         : "bg-gray-200 text-black"
//                     } shadow-sm`}
//                   >
//                     {i.message}
//                   </h1>
//                 </div>
//               ))}
//           </div>

//           <div className="flex items-center justify-between p-2 border-t border-gray-200">
//             <input
//               type="text"
//               className="w-[85%] rounded-full pl-3 py-2 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
//               placeholder="Enter your message"
//               value={messageText}
//               onChange={(e) => setMessageText(e.target.value)}
//             />
//             <button
//               type="submit"
//               onClick={handleMessageSend}
//               className="bg-[#3b82f6] hover:bg-[#2563eb] transition duration-300 p-2 rounded-full flex items-center justify-center shadow-md"
//             >
//               <SendIcon style={{ color: "white" }} />
//             </button>
//           </div>
//         </div>
//       ) : null}
//     </div>
//   );
// };

// export default ChatBox;
