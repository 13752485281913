import React from 'react';

const ReportAdmin = () => {
    return (
        <div>
        <h1>Report Admin</h1>
        </div>
    );
    }

export default ReportAdmin;