import axios from "axios";
// const busiUrl = "https://test-branch-ulc7.onrender.com/api/Business/";
const busiUrl = "https://api.cloutin.co/api/Business/";
const influencerUrl = "https://api.cloutin.co/api/influencer/businessId";
const getAllBusiness = (setDataBusiness) => {
  axios.get(busiUrl).then(({ data }) => {
    setDataBusiness(data["company"]);
  });
};

const getAllInfluencer = (setInfluencer, setEachInfluencerCurrAnalytic, id) => {
  axios.get(`${influencerUrl}/${id}`).then(({ data }) => {
    setInfluencer(data?.response);
    setEachInfluencerCurrAnalytic(data?.influencerStats);
  });
};

export { getAllBusiness, getAllInfluencer };
