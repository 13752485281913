

import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../redux/Slice/loadingSlice";

function Report() {
  const { loading } = useSelector((state) => state.loading);
  const currentUserId = Cookies.get("userid");

  const [formData, setFormData] = useState({
    issue: "Influencer Issue",
    subject: "",
    message: "",
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      const { data } = await axios.post(
        "https://api.cloutin.co/api/report",
        formData
      );
      toast.success("Report submitted successfully");

      // Clear form fields after successful submission
      setFormData({
        issue: "Influencer Issue",
        subject: "",
        message: "",
        name: "",
        email: "",
      });

      // Notify the user about the successful report submission
      await axios.post("https://api.cloutin.co/api/notifications/post", {
        data: {
          influencerId: currentUserId,
          notificationTitle: "Report",
          notificationDesc: "Your report has been submitted successfully.",
        },
      });
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
      // Notify the user about the failure of report submission
      await axios.post("https://api.cloutin.co/api/notifications/post", {
        data: {
          influencerId: currentUserId,
          notificationTitle: "Report",
          notificationDesc: "Failed to submit your report.",
        },
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div className="p-8 md:p-2 overflow-hidden">
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Report an Issue
        </h1>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <button
            type="button"
            className={`py-2 rounded-lg text-white font-semibold ${
              formData.issue === "Influencer Issue"
                ? "bg-blue-500"
                : "bg-gray-300"
            }`}
            onClick={() =>
              setFormData({ ...formData, issue: "Influencer Issue" })
            }
          >
            Influencer Issue
          </button>
          <button
            type="button"
            className={`py-2 rounded-lg text-white font-semibold ${
              formData.issue === "Campaign Issue"
                ? "bg-blue-500"
                : "bg-gray-300"
            }`}
            onClick={() =>
              setFormData({ ...formData, issue: "Campaign Issue" })
            }
          >
            Campaign Issue
          </button>
        </div>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none"
          placeholder="Your Name"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none"
          placeholder="Your Email"
          required
        />
        <select
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none"
          required
        >
          <option value="">Select Subject</option>
          <option value="Facebook">Facebook</option>
          <option value="Youtube">Youtube</option>
          <option value="Instagram">Instagram</option>
        </select>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none"
          placeholder="Your Message"
          rows="5"
          required
        ></textarea>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-semibold rounded-md py-2 transition duration-300 ease-in-out hover:bg-blue-600"
        >
          {/* Submit Report */}
          {loading ? "Submitting Report...." : "Submit Report"}
        </button>
      </form>
    </div>
  );
}

export default Report;
