// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdKrWn76N6_0NvGHuOeFxIA5id8kU1Sjw",
  authDomain: "collabb-firebase.firebaseapp.com",
  projectId: "collabb-firebase",
  storageBucket: "collabb-firebase.appspot.com",
  messagingSenderId: "473209548641",
  appId: "1:473209548641:web:7b572fe9030624640e1f20",
  measurementId: "G-P0L8M9EBN1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
