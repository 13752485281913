import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Button from "@mui/material/Button";
import Review from "./Component/MarketPlace/Review";
import { FaInstagram, FaYoutube } from "react-icons/fa";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 100,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const BasicModal1 = ({
  name,
  influencerId,
  following,
  followers,
  engagementRate,
  campaignId
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const platformIcon =
    following === null ? (
      <FaYoutube size={50} color="#FF0000" />
    ) : (
      <FaInstagram size={50} color="#E1306C" />
    );

  return (
    <div>
      <Button onClick={handleOpen} className="uppercase hover:text-green-500">
        {name}
      </Button>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center"
            style={modalStyles.overlay}
          >
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="relative bg-white p-6 rounded-lg shadow-lg"
              style={{ maxWidth: "90%", width: 800 }}
            >
              <div className="text-center mb-4">
                <h2 className="text-2xl font-semibold">{name}'s Analytics</h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="shadow-lg border border-gray-300 p-4 rounded-lg text-center">
                  <h3 className="text-lg font-medium">Engagement Rate</h3>
                  <p className="text-xl font-semibold">{engagementRate}%</p>
                </div>
                <div className="shadow-lg border border-gray-300 p-4 rounded-lg text-center">
                  <h3 className="text-lg font-medium">Followers</h3>
                  <p className="text-xl font-semibold">{followers}</p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="shadow-lg border border-gray-300 p-4 rounded-lg text-center">
                  <h3 className="text-lg font-medium">Platform</h3>
                  <div className="flex items-center justify-center">
                    {platformIcon}
                    <span className="ml-2">
                      {following === null ? "YouTube" : "Instagram"}
                    </span>
                  </div>
                </div>
                {following !== null && (
                  <div className="shadow-lg border border-gray-300 p-4 rounded-lg text-center">
                    <h3 className="text-lg font-medium">Following</h3>
                    <p className="text-xl font-semibold">{following}</p>
                  </div>
                )}
              </div>
              <Review influencerId={influencerId} campaignId={campaignId} />
              <button
                onClick={handleClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BasicModal1;
