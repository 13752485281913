import React from "react";
import { Link } from "react-router-dom";
import ScrollToTopButton from "./ScrollToTopButton";

const TermsConditions = () => {
  return (
    <div className="container px-4 py-8 max-w-[1400px] m-auto">
      <h2 className="text-3xl font-bold mb-6">Terms and Conditions</h2>

      <p className="mb-4">
        Welcome to <Link to="/" className="text-blue-500 underline">Cloutin</Link> 👋
      </p>

      <p className="mb-4">
        These terms and conditions outline the rules and regulations for the use
        of<Link to="/" className="text-blue-500 underline">Cloutin</Link>'s Website, located at{" "}
        <Link to="https://cloutin.co" target="_blank" className="text-blue-500 underline">
          https://cloutin.co
        </Link>
        .
      </p>

      <p className="mb-4">
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use<Link to="/" className="text-blue-500 underline">Cloutin</Link> if you do
        not agree to take all of the terms and conditions stated on this page.
      </p>

      <p className="mb-4">
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: "Client", "You" and
        "Your" refers to you, the person log on this website and compliant to
        the Company's terms and conditions. "The Company", "Ourselves", "We",
        "Our" and "Us", refers to our Company. "Party", "Parties", or "Us",
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client's needs in respect of
        provision of the Company's stated services, in accordance with and
        subject to, prevailing law of in. Any use of the above terminology or
        other words in the singular, plural, capitalization and/or he/she or
        they, are taken as interchangeable and therefore as referring to same.
      </p>

      <h3 className="text-2xl font-semibold mt-6 mb-4">Cookies</h3>

      <p className="mb-4">
        We employ the use of cookies. By accessing <Link to="/" className="text-blue-500 underline">Cloutin</Link>,
        you agreed to use cookies in agreement with<Link to="/" className="text-blue-500 underline">Cloutin</Link>
        's Privacy Policy.
      </p>

      <p className="mb-4">
        Most interactive websites use cookies to let us retrieve the user's
        details for each visit. Cookies are used by our website to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use
        cookies.
      </p>

      <h3 className="text-2xl font-semibold mt-6 mb-4">License</h3>

      <p className="mb-4">
        Unless otherwise stated,<Link to="/" className="text-blue-500 underline">Cloutin</Link> and/or its
        licensors own the intellectual property rights for all material on{" "}
       <Link to="/" className="text-blue-500 underline">Cloutin</Link>. All intellectual property rights are
        reserved. You may access this from
       <Link to="/" className="text-blue-500 underline">Cloutin</Link> for your own personal use subjected to
        restrictions set in these terms and conditions.
      </p>

      <p className="mb-4">You must not:</p>
      <ul className="list-disc list-inside mb-4 space-y-2">
        <li>
          Republish material from<Link to="/" className="text-blue-500 underline">Cloutin</Link>
        </li>
        <li>
          Sell, rent or sub-license material from<Link to="/" className="text-blue-500 underline">Cloutin</Link>
        </li>
        <li>
          Reproduce, duplicate or copy material from<Link to="/" className="text-blue-500 underline">Cloutin</Link>
        </li>
        <li>
          Redistribute content from<Link to="/" className="text-blue-500 underline">Cloutin</Link>
        </li>
      </ul>

      <p className="mb-4">
        Parts of this website offer an opportunity for users to post and
        exchange opinions and information in certain areas of the website.
       <Link to="/" className="text-blue-500 underline">Cloutin</Link> does not filter, edit, publish or review
        Comments prior to their presence on the website. Comments do not reflect
        the views and opinions of<Link to="/" className="text-blue-500 underline">Cloutin</Link>, its agents and/or
        affiliates. Comments reflect the views and opinions of the person who
        post their views and opinions. To the extent permitted by applicable
        laws,<Link to="/" className="text-blue-500 underline">Cloutin</Link> shall not be liable for the Comments
        or for any liability, damages or expenses caused and/or suffered as a
        result of any use of and/or posting of and/or appearance of the Comments
        on this website.
      </p>

      <p className="mb-4">
       <Link to="/" className="text-blue-500 underline">Cloutin</Link> reserves the right to monitor all Comments
        and to remove any Comments which can be considered inappropriate,
        offensive or causes breach of these Terms and Conditions.
      </p>

      <p className="mb-4">You warrant and represent that:</p>

      <ul className="list-disc list-inside mb-4 space-y-2">
        <li>
          You are entitled to post the Comments on our website and have all
          necessary licenses and consents to do so;
        </li>
        <li>
          The Comments do not invade any intellectual property right, including
          without limitation copyright, patent or trademark of any third party;
        </li>
        <li>
          The Comments do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful material which is an invasion of
          privacy;
        </li>
        <li>
          The Comments will not be used to solicit or promote business or custom
          or present commercial activities or unlawful activity.
        </li>
      </ul>

      <p className="mb-4">
        You hereby grant<Link to="/" className="text-blue-500 underline">Cloutin</Link> a non-exclusive license to
        use, reproduce, edit and authorize others to use, reproduce and edit any
        of your Comments in any and all forms, formats or media.
      </p>

      <p className="mb-4">
        If you find any link on our Website that is offensive for any reason,
        you are free to contact and inform us any moment. We will consider
        requests to remove links but we are not obligated to or so or to respond
        to you directly.
      </p>

      <p className="mb-4">
        We do not ensure that the information on this website is correct, we do
        not warrant its completeness or accuracy; nor do we promise to ensure
        that the website remains available or that the material on the website
        is kept up to date.
      </p>

      <h3 className="text-2xl font-semibold mt-6 mb-4">Disclaimer</h3>

      <p className="mb-4">
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will:
      </p>

      <ul className="list-disc list-inside mb-4 space-y-2">
        <li>
          limit or exclude our or your liability for death or personal injury;
        </li>
        <li>
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </li>
        <li>
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </li>
        <li>
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </li>
      </ul>

      <p className="mb-4">
        The limitations and prohibitions of liability set in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer,
        including liabilities arising in contract, in tort and for breach of
        statutory duty.
      </p>

      <p className="mb-4">
        As long as the website and the information and services on the website
        are provided free of charge, we will not be liable for any loss or
        damage of any nature.
      </p>

      <ScrollToTopButton/>
    </div>
  );
};

export default TermsConditions;
