// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { getAllInfluencer } from "./Api";

// const Influencers_admin2 = () => {
//   const { id8 } = useParams();
//   const navigate = useNavigate();
//   const [influencers, setInfluencers] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [eachInfluencerCurrAnalytic, setEachInfluencerCurrAnalytic] = useState(
//     {}
//   );

//   useEffect(() => {
//     getAllInfluencer(setInfluencers, setEachInfluencerCurrAnalytic, id8);
//   }, [id8]);

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
//         Influencers List
//       </h1>
//       {loading ? (
//         <div className="flex items-center justify-center">
//           <p className="text-lg text-gray-600">Loading...</p>
//         </div>
//       ) : (
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//           {influencers.length === 0 ? (
//             <div className="flex items-center justify-center col-span-3">
//               <p className="text-lg text-gray-600">No influencers found</p>
//             </div>
//           ) : (
//             <>
//               {influencers.map((influencer, index) => (
//                 <div
//                   key={influencer?._doc?._id}
//                   className="bg-white rounded-lg shadow-md overflow-hidden"
//                 >
//                   <button
//                     className="block w-full p-4 text-left"
//                     // onClick={() => navigate(`/admin/influencers/${influencer._id}`)}
//                   >
//                     <h2 className="text-lg font-bold text-gray-800 mb-2">
//                       {influencer?._doc?.name}
//                     </h2>
//                     <div className="border-t border-gray-200 pt-4 mt-4">
//                       <h3 className="text-sm font-semibold text-gray-600">
//                         Current Analytics:
//                       </h3>
//                       {eachInfluencerCurrAnalytic[index] && (
//                         <div className="mt-2">
//                           <p className="text-sm text-gray-700">
//                             Total Campaigns Completed:{" "}
//                             {
//                               eachInfluencerCurrAnalytic[index]
//                                 ?.total_campaigns_completed
//                             }
//                           </p>
//                           <p className="text-sm text-gray-700">
//                             Total Orders Completed:{" "}
//                             {
//                               eachInfluencerCurrAnalytic[index]
//                                 ?.total_orders_completed
//                             }
//                           </p>
//                           <p className="text-sm text-gray-700">
//                             Total Purchase Value:{" "}
//                             {
//                               eachInfluencerCurrAnalytic[index]
//                                 ?.total_purchase_value
//                             }
//                           </p>
//                         </div>
//                       )}
//                     </div>
//                   </button>
//                 </div>
//               ))}
//             </>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Influencers_admin2;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllInfluencer } from "./Api";
import ErrorBoundary from "../../../utils/ErrorBoundary";
// import { ErrorBoundary } from "../../../utils/ErrorBoundary";

const InfluencersList = () => {
  const { id8 } = useParams();
  const navigate = useNavigate();
  const [influencers, setInfluencers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eachInfluencerCurrAnalytic, setEachInfluencerCurrAnalytic] = useState(
    {}
  );
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getAllInfluencer(
          setInfluencers,
          setEachInfluencerCurrAnalytic,
          id8
        );
        // Uncomment the next line to test error boundary
        // throw new Error("Test error");
      } catch (err) {
        console.error("Error fetching influencers:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id8]);

  if (error) {
    throw error; // This will trigger the error boundary
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <p className="text-lg text-gray-600">Loading...</p>
      </div>
    );
  }

  if (influencers.length === 0) {
    return (
      <div className="flex items-center justify-center col-span-3">
        <p className="text-lg text-gray-600">No influencers found</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {influencers.map((influencer, index) => (
        <div
          key={influencer?._doc?._id}
          className="bg-white rounded-lg shadow-md overflow-hidden"
        >
          <button
            className="block w-full p-4 text-left"
            // onClick={() => navigate(`/admin/influencers/${influencer._id}`)}
          >
            <h2 className="text-lg font-bold text-gray-800 mb-2">
              {influencer?._doc?.name}
            </h2>
            <div className="border-t border-gray-200 pt-4 mt-4">
              <h3 className="text-sm font-semibold text-gray-600">
                Current Analytics:
              </h3>
              {eachInfluencerCurrAnalytic[index] && (
                <div className="mt-2">
                  <p className="text-sm text-gray-700">
                    Total Campaigns Completed:{" "}
                    {
                      eachInfluencerCurrAnalytic[index]
                        ?.total_campaigns_completed
                    }
                  </p>
                  <p className="text-sm text-gray-700">
                    Total Orders Completed:{" "}
                    {eachInfluencerCurrAnalytic[index]?.total_orders_completed}
                  </p>
                  <p className="text-sm text-gray-700">
                    Total Purchase Value:{" "}
                    {eachInfluencerCurrAnalytic[index]?.total_purchase_value}
                  </p>
                </div>
              )}
            </div>
          </button>
        </div>
      ))}
    </div>
  );
};

const ErrorThrower = () => {
  throw new Error("Test error");
};

const Influencers_admin2 = () => {
  const [shouldThrowError, setShouldThrowError] = useState(false);

  return (
    <ErrorBoundary>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Influencers List
        </h1>
        <button
          onClick={() => setShouldThrowError(!shouldThrowError)}
          className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Toggle Error
        </button>
        {shouldThrowError ? (
          <ErrorThrower />
        ) : (
          <InfluencersList />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Influencers_admin2;