import React from "react";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

const PostPageOfInfluencer = ({ ...postInfo }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-4" key={postInfo._id}>
      <div className="mb-4">
        <img
          src={postInfo.post_image}
          alt={postInfo.campaign_name}
          className="rounded-lg"
        />
      </div>
      <div className="mb-4">
        <h1 className="text-2xl font-bold mb-2 text-ellipsis text-gray-600">
          {postInfo.campaign_name}
        </h1>
        <Link
          to={postInfo.post_url}
          target="_blank"
          className="text-blue-500 flex items-center"
        >
          <FaExternalLinkAlt className="inline-block mr-2" />
          <span>View Post</span>
        </Link>
      </div>
      <div className="flex justify-between gap-2">
        <span className="text-sm">
          Post Status:
          <span
            className={`inline-block  py-1 rounded ${
              postInfo.post_status === "Active"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {postInfo.post_status}
          </span>
        </span>
        <span className="text-sm">
          Campaign Status:
          <span
            className={`inline-block py-1 rounded ${
              postInfo.campaign_status === "Active"
                ? " text-green-500"
                : "text-red-500 "
            }`}
          >
            {postInfo.campaign_status}
          </span>
        </span>
      </div>
    </div>
  );
};

export default PostPageOfInfluencer;
