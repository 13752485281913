import axios from "axios";

const ApiInterceptor = axios.create({
  baseURL: "https://api.cloutin.co/api/",
  // baseURL: "/api",
});

ApiInterceptor.interceptors.request.use((req) => {
  return req;
});

export default ApiInterceptor;
