import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaPlus, FaMinus } from "react-icons/fa";

const Faq = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const questions = [
    {
      id: 1,
      question: "What is influencer marketing?",
      answer:
        "Influencer marketing is a type of marketing that focuses on using key leaders to drive your brand’s message to the larger market.",
    },
    {
      id: 2,
      question: "How does your platform connect brands and influencers?",
      answer:
        "Our platform uses advanced algorithms to match brands with influencers based on campaign goals and target audience.",
    },
    {
      id: 3,
      question: "What metrics do you provide for campaign performance?",
      answer:
        "We provide a comprehensive dashboard with metrics such as reach, engagement, conversions, and ROI.",
    },
    {
      id: 4,
      question: "Can I track the performance of each influencer?",
      answer:
        "Yes, our platform allows you to track individual influencer performance in real-time.",
    },
    {
      id: 5,
      question: "Do you offer customer support?",
      answer:
        "Yes, we offer 24/7 customer support to assist you with any queries or issues.",
    },
  ];

  const handleQuestionClick = (id) => {
    setOpenQuestion(id === openQuestion ? null : id);
  };

  return (
    <div className="flex justify-center flex-col items-center mt-2 p-2">
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-bold text-blue-600">😁 FAQs</h1>
        <p className="text-lg text-blue-500 mt-2">
          Find answers to the most frequently asked questions about our
          platform.
        </p>
      </div>
      <div className="max-w-2xl w-full border border-blue-600 border-b-4 border-r-4 rounded-md shadow-lg bg-white">
        {questions.map((q) => (
          <div key={q.id} className="border-b border-gray-300">
            <div
              className="cursor-pointer flex justify-between items-center py-4 px-6 bg-white hover:bg-blue-50 rounded-t-md"
              onClick={() => handleQuestionClick(q.id)}
            >
              <span className="font-semibold text-blue-600">{q.question}</span>
              <motion.span
                className="text-blue-600"
                initial={{ rotate: 180 }}
                animate={{ rotate: openQuestion === q.id ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                {openQuestion === q.id ? <FaMinus /> : <FaPlus />}
              </motion.span>
            </div>
            <AnimatePresence>
              {openQuestion === q.id && (
                <motion.div
                  key="content"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                  className="overflow-hidden"
                >
                  <div className="bg-gray-50 px-6 py-4">
                    <p className="text-gray-700">{q.answer}</p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
