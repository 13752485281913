import React from "react";

const CampaignCard = ({
  iconImage,
  // incre_decre_img,
  // incre_decre_count,
  dash_data,
  dash_name,
  dashimg,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300">
      <div className="p-6">
        <div className="flex justify-between mb-4">
          {dashimg ? (
            <span>{dashimg}</span>
          ) : (
            <img src={iconImage} alt="Icon" className="w-8 h-8" />
          )}
          <div className="flex items-center">
          </div>
        </div>
        <h1 className="text-2xl font-bold text-gray-500 mb-4">
          {dash_data || 0}
        </h1>
        <p className="text-sm text-gray-600">{dash_name}</p>
      </div>
    </div>
  );
};

export default CampaignCard;