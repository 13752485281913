import axios from "axios";

const baseUrl = "https://api.cloutin.co/api/influencerMarketplaceInd";
const packUrl = "https://api.cloutin.co/api/packages?influencer_id=";
const busiUrl = "https://api.cloutin.co/api/Business";
const comapignUrl = "https://api.cloutin.co/api/allcampaigns";
const influencerUrl = "https://api.cloutin.co/api/influencer";
const getAllData = (setData) => {
  axios.get(baseUrl).then(({ data }) => {
    setData(data["result"]);
  });
};

const getAllPackages = (id, setPack, setPopularPackage, platform) => {
  axios.get(packUrl + id).then(({ data }) => {
    let pack = data["packages"];
    pack = pack.filter((p) => p.platform === platform);
    const sortedPack = pack.slice().sort((a, b) => a.price - b.price);
    const popularPackage = sortedPack[Math.floor(pack.length / 2)];
    setPack(sortedPack);
    setPopularPackage([popularPackage]);
  });
};
const getAllBusiness = (setDataBusiness) => {
  axios.get(busiUrl).then(({ data }) => {
    setDataBusiness(data["company"]);
  });
};
const getAllCompaign = (setCampaign) => {
  axios.get(comapignUrl).then(({ data }) => {
    setCampaign(data["campaigns"]);
  });
};
const getAllInfluencer = (setInfluencer) => {
  axios.get(influencerUrl).then(({ data }) => {
    setInfluencer(data["influencer"]);
  });
};

export {
  getAllData,
  getAllPackages,
  getAllBusiness,
  getAllCompaign,
  getAllInfluencer,
};
