import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../utils/Loader";

const AdminRevenue = () => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await axios.get(
          "https://api.cloutin.co/api/admin/revenue/getallrevenue"
        );
        setMetrics(response?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  if (loading) return <Loader loadingText="" />;
  if (error) return <div className="text-red-500">Error: {error.message}</div>;

  const filteredCampaigns = metrics.campaigns.filter((campaign) =>
    campaign.campaignName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCampaigns = filteredCampaigns.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredCampaigns.length / itemsPerPage);

  const renderPageNumbers = () => {
    const maxPageNumbers = 5;
    const pageNumbersToShow = [];

    if (totalPages <= maxPageNumbers) {
      return [...Array(totalPages).keys()].map((number) => (
        <button
          key={number + 1}
          onClick={() => setCurrentPage(number + 1)}
          className={`px-3 py-2 mx-1 rounded-lg ${
            currentPage === number + 1
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-700 hover:bg-gray-400"
          }`}
        >
          {number + 1}
        </button>
      ));
    }

    if (currentPage <= 3) {
      pageNumbersToShow.push(
        ...[...Array(4).keys()].map((i) => i + 1),
        "...",
        totalPages
      );
    } else if (currentPage >= totalPages - 2) {
      pageNumbersToShow.push(
        1,
        "...",
        ...[...Array(4).keys()].map((i) => totalPages - 3 + i)
      );
    } else {
      pageNumbersToShow.push(
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages
      );
    }

    return pageNumbersToShow.map((item, index) => (
      <button
        key={index}
        onClick={() => item !== "..." && setCurrentPage(item)}
        className={`px-3 py-2 mx-1 rounded-lg ${
          currentPage === item
            ? "bg-blue-600 text-white"
            : item === "..."
            ? "text-gray-500 cursor-default"
            : "bg-gray-300 text-gray-700 hover:bg-gray-400"
        }`}
        disabled={item === "..."}
      >
        {item}
      </button>
    ));
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-gradient-to-r from-blue-500 to-blue-700 p-6 rounded-lg shadow-lg text-center text-white">
          <h2 className="text-2xl font-semibold mb-2">Total Earnings</h2>
          <p className="text-4xl font-bold">
            ₹{metrics.totalEarnings.toFixed(2)}
          </p>
        </div>
        <div className="bg-gradient-to-r from-green-500 to-green-700 p-6 rounded-lg shadow-lg text-center text-white">
          <h2 className="text-2xl font-semibold mb-2">Total Deals</h2>
          <p className="text-4xl font-bold">{metrics.totalDeals}</p>
        </div>
        <div className="bg-gradient-to-r from-purple-500 to-purple-700 p-6 rounded-lg shadow-lg text-center text-white">
          <h2 className="text-2xl font-semibold mb-2">
            Total Completed Campaigns
          </h2>
          <p className="text-4xl font-bold">
            {metrics.totalCompletedCampaigns}
          </p>
        </div>
      </div>

      {/* <div className="mb-4">
        <input
          type="text"
          placeholder="Search campaigns..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
      </div> */}

      <div className="bg-white p-6 rounded-lg shadow-lg overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                #
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                Campaign Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                Business Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                Influencer Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                Earnings
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                Creation Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                Completion Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentCampaigns.map((campaign, index) => (
              <tr key={campaign.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {indexOfFirstItem + index + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap font-semibold text-gray-900">
                  {campaign.campaignName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {campaign.businessName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {campaign.influencerName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-green-600 font-semibold">
                  ₹{campaign.totalEarnings.toFixed(2)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {new Date(campaign.creationDate).toLocaleDateString("en-GB")}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {new Date(campaign.completionDate).toLocaleDateString(
                    "en-GB"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6 flex justify-center items-center">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-2 mx-1 rounded-lg bg-gray-300 disabled:opacity-50 hover:bg-gray-400"
        >
          &lt;
        </button>
        {renderPageNumbers()}
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="px-3 py-2 mx-1 rounded-lg bg-gray-300 disabled:opacity-50 hover:bg-gray-400"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default AdminRevenue;
