import React from "react";
import { Link } from "react-router-dom";

const OverlayDisableAccountPage = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[50] flex justify-center items-center bg-black bg-opacity-50 left-[300px]">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">
          Your Account Has Been Disabled
        </h2>
        <p className="text-gray-700 mb-4">
          Please contact the admin for further assistance.
        </p>
        <div className="flex justify-center">
          <Link
            to="mailto:admin@example.com"
            className="text-blue-500 hover:text-blue-700 mr-2"
          >
            Email
          </Link>
          <Link
            to="tel:+123456789"
            className="text-blue-500 hover:text-blue-700 mr-2"
          >
            Phone
          </Link>
          <button className="text-blue-500 hover:text-blue-700">
            Live Chat
          </button>
        </div>
      </div>
    </div>
  );
};

export default OverlayDisableAccountPage;