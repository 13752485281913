import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Review from "./MarketPlace/Review";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal2({
  name,
  influencerId,

  following,
  followers,
  enganmentRate,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        style={{
          fontWeight: "bold",
          color: "black",
          border: "1px solid gray",
          padding: "3px 12px",
        }}
        onClick={handleOpen}
      >
        {name}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
          {/* <div className=" flex justify-evenly mb-2">
            <div className="shadow-lg border border-[#cecece] w-[40%]  py-4 df flex-col">
              <h1>Engagement Rate</h1>
              <h1>{enganmentRate}</h1>
            </div>
            <div className="shadow-lg border border-[#cecece] w-[40%]  py-4 df flex-col">
              <h1>Followers</h1>
              <h1>{following}</h1>
            </div>
          </div>
          <div className=" flex justify-evenly mb-4">
            <div className="shadow-lg border border-[#cecece] w-[40%]  py-4 df flex-col">
              <h1>Followers</h1>
              <h1>{followers}</h1>
            </div>
            <div className="shadow-lg border border-[#cecece] w-[40%]  py-4 df flex-col">
              <h1>Following</h1>
              <h1>{following}</h1>
            </div>
          </div> */}
          <Review influencerId={influencerId} />
        </Box>
      </Modal>
    </div>
  );
}
