import React from "react";

function Card({ image, title }) {
  return (
    <div className="bg-white rounded-xl   2xl:space-y-[30px] h-auto md:w-[225px] p-[20px] 8k:w-[350px] 8k:mb-[1vw] 3xl:mb-[1vw] 3xl:w-[14vw] 4k:w-[17vw] 4k:mb-[2vw] lgk:mb-[2vw] 1440k:mb-[2vw] rk:mb-[2vw] mdd:mb-[2vw]  border-l-2 border-blue-500 drop-shadow-sm hover:drop-shadow-md ">
      <div className="flex justify-between items-center">
        <img
          className="w-12 h-12 rounded-full 8k:w-14 8k:h-14"
          src={`/Images/${image}`}
          alt="icon"
        />
        <h1 className="font-bold text-black  8k:text-[0.8vw] 3xl:text-[0.8vw]">{title}</h1>
      </div>
      <p className="text-[#b2aeae] py-[20px] 8k:text-[0.8vw] 3xl:text-[0.8vw] 4k:text-[1vw]">
        Enable integrations that unlock features in the firebase, Google
        Analytics, and Google Play
      </p>
      <div className="bg-[#00A3FF] cursor-pointer rounded-3xl flex items-center justify-between text-white px-4 py-2 8k:text-[1vw] 3xl:text-[1vw] 4k:text-[1.3vw] ">
        <h1>View Link</h1>
        <img className="w-4 h-4 8k:w-[1vw] 8k:h-[1vw] 4k:w-[1.2vw] 4k:h-[1.2vw] 3xl:w-[1vw] 3xl:h-[1vw] " src="/Images/link.png" alt="link" />
      </div>
    </div>
  );
}

export default Card;
