import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "react-oauth-google";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import toast from "react-hot-toast";
import axios from "axios";

const GoogleOAuthButton = (userId) => {
  const [tokenl, setTokenl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
   

    let targetUrl = `/googleRegister?accessToken=${tokenl.access_token}`;
    //get the email from access token
    // const email = tokenl.idTokenPayload.email;
    // console.log(email, "eamil from googleOauthButton");

    // Conditionally add the 'userid' query parameter if 'userId' is available
    if (userId) {
      targetUrl += `&userId=${userId.userId}`;
    }

    if (tokenl !== "") {
      navigate(targetUrl);
    }
  }, [tokenl, userId]);

  // const openGoogleOAuthWindow = () => {
  // const url = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?gsiwebsdk=3&client_id=473209548641-31atat8vj0k48agknau5epsrun9fdtru.apps.googleusercontent.com&scope=openid%20profile%20email&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FgoogleRegister&prompt=select_account&response_type=token&include_granted_scopes=true&enable_granular_consent=true&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow";
  // // Open the Google OAuth window
  // const oauthWindow = window.open(url, "GoogleOAuthWindow", "width=500,height=600");
  // const handleAccessToken = (accessToken) => {
  //   console.log("Access Token: " + accessToken);
  //   // Perform actions with the access token, such as redirecting to the googleregister page
  //   window.location.href = `/googleRegister?accessToken=${accessToken}`;
  // };
  //   window.addEventListener("message", (event) => {
  //     if (event.origin === "http://localhost:3000" && event.data.accessToken) {
  //   // Handle the access token when it's received
  //      handleAccessToken(event.data.accessToken);
  // }
  //   });

  // Optionally, you can add an event listener to handle when the window is closed or if there is any other interaction.
  // if (oauthWindow) {
  //   oauthWindow.addEventListener("beforeunload", () => {
  //     // Handle window close event
  //   });
  // }
  // };

  const loginGoogle = useGoogleLogin({
    clientId:
      "473209548641-31atat8vj0k48agknau5epsrun9fdtru.apps.googleusercontent.com",
    redirectUri: userId
      ? `https://api.cloutin.co/googleRegister?userId=${userId}`
      : "https://api.cloutin.co/googleRegister",
    scopes: ["profile", "email"], // Specify the required scopes
    onSuccess: (tokenResp) => {
      // console.log("Successfully logged in:", tokenResp);
      // console.log("token: ", tokenResp.access_token);
      setTokenl((tokenl) => tokenResp);
      // console.log(tokenl);
    },
    onError: (error) => {
      toast.error(error?.message || "Error logging in with Google", {
        duration: 1500,
        position: "top-right",
      });
    },
  });

  return (
    <div className="flex justify-center 4k:text-2xl 4k:mt-5 1440k:text-xl 1440k:pt-5 pb-2">
      <div
        // className="border cursor-pointer py-[10px] mr-[20px] w-full justify-center p-2 rounded-lg flex items-center"
        className="cursor-pointer bg-white text-black border border-gray-100 shadow-md font-bold py-2 px-4 rounded-full flex items-center"
        onClick={loginGoogle}
      >
        <FcGoogle className="w-8 h-8" />
        <h1 className="mr-[5px] md:mr-[10px]">Continue with Google</h1>
      </div>
    </div>
  );
};

export default GoogleOAuthButton;
