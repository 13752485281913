import React, { useState } from "react";
import axios from "axios";
import { updateCampaignStatus } from "../../../redux/Slice/AdminSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
const ButtonIndividualCampaign = ({
  label,
  id,
  userId,
  setCampaign,
  color,
  tcolor,
  onStatusChange,
}) => {
  const [isEnabled, setIsEnabled] = useState(label === "Active");
  const dispatch = useDispatch();
  const handleToggle = () => {
    if (isEnabled === true) {
      axios.all([
        axios
          .put(
            // `https://test-branch-ulc7.onrender.com/api/allcampaigns/edit/${id}`,
            `https://api.cloutin.co/api/allcampaigns/edit/${id}`,
            {
              status: "Inactive",
            }
          )
          .then(({ data }) => {
            // const items = data["campaign"];
            const items = Array.isArray(data?.campaign) ? data.campaign : [];
            const filteredItems = items?.filter(
              (item) => item?.userId === userId
            );
            toast.success(data?.message || "updated");
            setCampaign(filteredItems);
            setIsEnabled(!isEnabled);
            const campdata = { id: userId, status: "Inactive" };
            dispatch(updateCampaignStatus(campdata));
            if (onStatusChange) {
              onStatusChange();
            }
          })
          .then(
            axios.post("https://api.cloutin.co/api/notifications/post", {
              data: {
                influencerId: userId,
                notificationTitle: "Status",
                notificationDesc: `Your Campaign is Inactivated by administrator`,
              },
              addtionalData: {
                campaignId: id,
              },
            })
          ),
      ]);
    } else {
      axios.all([
        axios
          .put(
            // `https://test-branch-ulc7.onrender.com/api/allcampaigns/edit/${id}`,
            `https://api.cloutin.co/api/allcampaigns/edit/${id}`,
            {
              status: "Active",
            }
          )
          .then(({ data }) => {
            // const items = data["campaign"];
            const items = Array.isArray(data?.campaign) ? data.campaign : [];
            const filteredItems = items?.filter(
              (item) => item?.userId === userId
            );
            toast.success(data?.message + "....");
            setCampaign(filteredItems);
            setIsEnabled(!isEnabled);
            const campdata = { id: userId, status: "Active" };
            dispatch(updateCampaignStatus(campdata));
            if (onStatusChange) {
              onStatusChange();
            }
          })
          .then(
            axios.post("https://api.cloutin.co/api/notifications/post", {
              data: {
                influencerId: userId,
                notificationTitle: "Status",
                notificationDesc: `Your Camaign is Activated by administrator`,
              },
              addtionalData: {
                campaignId: id,
              },
            })
          ),
      ]);
    }
  };

  return (
    <button
      className={`cursor-pointer rounded-xl py-2 px-2 font-bold text-xs transition duration-300 ease-in-out ${
        label === "Active"
          ? "bg-gray-300 text-black"
          : label === "Pending"
          ? "bg-green-500 text-white"
          : "bg-blue-500 text-white"
      }`}
      onClick={handleToggle}
    >
      {label === "Active"
        ? "Disable"
        : label === "Pending"
        ? "Approve"
        : "Enable"}
    </button>
  );
};

export default ButtonIndividualCampaign;
