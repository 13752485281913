import React from "react";

const ErrorPage = () => {
  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-6 bg-red-50 text-red-600">
      <div className="max-w-md p-4 bg-white border border-red-200 rounded-lg shadow-lg">
        <h1 className="text-lg font-semibold mb-4">Server Issue</h1>
        <p className="mb-6">
          We're experiencing some server issues. Please refresh the page or try
          again later.
        </p>
        <button
          onClick={handleRetry}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
        >
          Retry
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
