import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import axios from "axios";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

const Review = ({ influencerId,campaignId }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const handleStarClick = (index, isHalf) => {
    if (isHalf) {
      setRating(index + 0.5);
    } else {
      setRating(index + 1);
    }
  };

  const submitReviewHandler = async (e) => {
    e.preventDefault();
  
    try {
      const postData = {
        influencer_id: influencerId,
        user_id: currentUserId,
        rating: rating,
        review: review,
      };
      if (postData.rating === 0) {
        return toast.error("Please give rating", {
          duration: 1500,
          position: "top-center",
        });
      }
      if (review === "" || review.trim().length === 0 || review.length < 10) {
        return toast.error("Please add some review content", {
          duration: 1500,
          position: "top-center",
        });
      }
      const apiurl = "https://api.cloutin.co/api/review/review-influencer";

      const response = await axios.post(apiurl, {
        influencer_id: influencerId,
        user_id: currentUserId,
        rating: rating,
        review: review,
        campaign_id:campaignId
      });

      // console.log("posted", response?.data?.msg);
      toast.success(
        response?.data?.message || "Review submitted successfully",
        {
          duration: 1500,
          position: "top-center",
        }
      );
    } catch (error) {
      // console.log(error);
      toast.error(
        error?.response?.data?.message || "Internal server error occured",
        {
          duration: 1500,
          position: "top-center",
        }
      );
    }
  };
  const currentUserId = Cookies.get("userid");
  
  return (
    <div className=" df">
      <div className="w-full border border-[#c9c9c9] pt-6 pb-6 px-6 shadow-lg rounded-[8px]">
        <h1 className="text-2xl font-bold text-primary">Give Your FeedBack</h1>
        <div className="my-2">
          {[...Array(5)].map((_, index) => (
            <span key={index} onClick={() => handleStarClick(index, false)}>
              {rating >= index + 1 ? (
                <StarIcon
                  style={{ color: "gold", cursor: "pointer", fontSize: "35px" }}
                />
              ) : rating >= index + 0.5 ? (
                <StarHalfIcon
                  style={{ color: "gold", cursor: "pointer", fontSize: "35px" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Stop propagation to prevent triggering parent span's onClick
                    handleStarClick(index, true); // Call handleStarClick with isHalf as true
                  }}
                />
              ) : (
                <StarIcon
                  style={{ color: "gray", cursor: "pointer", fontSize: "35px" }}
                />
              )}
            </span>
          ))}
        </div>
        <form onSubmit={submitReviewHandler}>
          <div className="my-2">
            <textarea
              style={{
                height: "130px",
                resize: "none",
                border: "1px solid #c9c9c9",
                padding: "10px",
              }}
              className="w-full h-full rounded-md"
              placeholder="Give Your Review"
              onChange={(e) => setReview(e.target.value)}
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              className="my-2 w-full py-3 font-bold text-white bg-primary"
            >
              Send FeedBack
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Review;
