import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentstatus: "",
  id: -1,
  status: "idle",
  isAdmin: false,
};

export const updateCampaignStatus = createAsyncThunk(
  "/admin/updateCampaignStatus",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error?.message);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      state.isAdmin = action.payload;
      // console.log(state.isAdmin, "state from isAdmin");
    },
    updateAdminStatus: (state, action) => {
      state.currentstatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCampaignStatus.pending, (state, action) => {
        state.error = null;
      })
      .addCase(updateCampaignStatus.fulfilled, (state, action) => {
        state.id = action.payload.id;
        state.currentstatus = action.payload.status;
      })
      .addCase(updateCampaignStatus.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const selectCampStatus = (state) => state.admin.currentstatus;

export const { setAdmin, updateAdminStatus } = adminSlice.actions;

export default adminSlice.reducer;
