import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiIcon from "@mui/icons-material/Wifi";
import RefreshIcon from "@mui/icons-material/Refresh";

const ConnectionStatus = ({ status }) => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      className="bg-red-600"
    >
      {status === "online" ? (
        <WifiIcon sx={{ fontSize: 100, color: "white" }} />
      ) : (
        <WifiOffIcon sx={{ fontSize: 100, color: "white" }} />
      )}
      <Typography variant="h4" color="white" mt={2}>
        {status === "online" ? "Online" : "Offline"}
      </Typography>
      {status !== "online" && (
        <>
          <Typography variant="h6" color="white">
            Please Refresh the Page
          </Typography>
          <IconButton onClick={handleRefresh} sx={{ color: "white", mt: 1 }}>
            <RefreshIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default ConnectionStatus;
