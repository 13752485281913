import toast from "react-hot-toast";
import Cookies from "js-cookie";
import axios from "axios";
import { loginError } from "../redux/Slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import ConversionCode from "./ConversionCodeDailogule";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../redux/Slice/UserAccounStatusSlice";
import OverlayDisableAccountPage from "../Pages/OverlayDisableAccountPage";
import NetworkStatusOverlay from "../Pages/NetworkStatusOverlay";
function Conversion() {
  const [profilepic, setProfilePic] = useState(null);
  const [includeInConversion, setIncludeInConversion] = useState(true);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [AddtoCartSelected,setAddtoCartSelected] = useState(false);
  const [PurchasesSelected,setPurchasesSelected] = useState(false);
  const dispatch = useDispatch();
  const [campaign_tracking_type, setCampaignTrackingType] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const currentUserId = Cookies.get("userid");
  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  useEffect(() => {
    const companyId = Cookies.get("userid");
    if (companyId) {
      dispatch(getUserAccountStatus({ companyId: companyId })).then(
        (response) => {
          dispatch(setUserAccountStatus(response?.payload));
        }
      );
    }
  }, [dispatch]);

  const fetchUserData = async () => {
    try {
      // console.log(currentUserId);
      const { data } = await axios.get(
        `https://api.cloutin.co/api/currentUser?userid=${currentUserId}`,
        config
      );
      // console.log("userpic", data);
      setEmail(data.email);
      setProfilePic(data?.image);
    } catch (error) {
      dispatch(loginError());
      toast.error(error?.response?.data?.message);
    }
  };
  // useEffect(() => {
  //   const fetchEventData = async () => {
  //     try {
  //       const response = await axios.post(
  //         "https://api.cloutin.co/api/conversion/getevents",
  //         {
  //           user_id: currentUserId,
  //         }
  //       );
  //       console.log(response?.data);
  //       setEvents(response?.data?.allEvents);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchEventData();
  // }, [currentUserId]);
  const [event, setEvents] = useState([]);
  const fetchEventData = async () => {
    try {
      const response = await axios.post(
        `https://api.cloutin.co/api/conversion/getevents/${currentUserId}`
      );
      // console.log(response?.data);
      setEvents(response?.data?.allEvents);
      response.data.allEvents.map((e)=>{
         e.Events.map((event)=>{
          if(event.category==='Purchases')
            setPurchasesSelected(true);
          if(event.category==='AddtoCart')
            setAddtoCartSelected(true);
         })
      })
    } catch (error) {
      console.log(error);
    }
  };
  const deleteHandler = async (id) => {
    console.log(id);
    try {
      const response = await axios.delete(
        `https://api.cloutin.co/api/conversion/eventroute/${id}`
      );
      toast.success(response?.data?.msg);
      await fetchEventData();
    } catch (error) {
      console.log(error);
    }
  };
  const createEvent = async (e) => {
    e.preventDefault();
    try {
      const data = {
        name: name,
        category: category,
        includeInConversion: includeInConversion,
      };
      const nameRegex = /^[a-zA-Z]+$/;
      if ((category !== 'AddtoCart' && category !== 'Purchases') && ( name === "" || name.length < 3)) {
        return toast.error(
          "Please Enter a valid Event Name with atleast 3 characters",
          {
            duration: 1000,
            position: "top-center",
          }
        );
      }
      if ((category !== 'AddtoCart' && category !== 'Purchases') && !nameRegex.test(name)) {
        setName("");
        return toast.error("Event Name must contain only alphabets", {
          duration: 1000,
          position: "top-center",
        });
      }

      if (category === "") {
        return toast.error("Category is Required", {
          duration: 1000,
          position: "top-center",
        });
      }
      const response = await axios.post(
        `https://api.cloutin.co/api/conversion/eventroute/${currentUserId}`,
        data
      );

      if (response?.status == 200) {
        toast.success("Event Added");
      } else {
        toast.error(response.data.error);
      }
      setEvents("");
      setCategory("");
      fetchEventData();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.post(
          `https://api.cloutin.co/api/conversion/getevents/${currentUserId}`
        );
      
        setEvents(response?.data?.allEvents);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEventData();
  }, [currentUserId]);

  useEffect(() => {
    fetchEventData();
  }, [currentUserId]);
  // to make border transparent of material ui
  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  };
  // const currentUserId = Cookies.get("userid");
  const TdStyle = {
    ThStyle: `w-1/3 min-w-[160px] border-l border-transparent py-2 px-3 text-lg font-medium text-white lg:py-4 lg:px-4`,
    TdStyle: `text-dark border-b border-l border-[#E8E8E8] bg-[#F3F6FF] dark:bg-dark-3 dark:border-dark dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdStyle2: `text-dark border-b border-[#E8E8E8] bg-white dark:border-dark dark:bg-dark-2 dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdButton: `inline-block px-6 py-2.5 border rounded-md border-primary text-primary hover:bg-primary hover:text-white font-medium`,
  };
  const [openDisablePageModal, setOpenDisablePageModal] = useState(false);
  useEffect(() => {
    if (userAccountStatus) {
      setOpenDisablePageModal(true);
    }
  }, [userAccountStatus]);
  return (
    <>
      <div className="w-[500px] bg-[#F8F8F8] mt-6 p-[20px] md:p-2 w-[80%]  ">
        <div className="md:w-full md:p-[15px]">
          <h1 className=" mb-4 font-extrabold text-center text-2xl">
            Create Event
          </h1>
          <form
            onSubmit={createEvent}
            className="flex items-center justify-center gap-6"
          >
            { category !== 'AddtoCart' && category !== 'Purchases' && 
            <TextField
              className=" bg-white pl-6 text-[#B2AEAE] my-[10px] p-3 w-full focus:outline-none  font-poppins w-full hover:border hover:border-black focus:outline-none focus:border focus:border-black rounded-[10px] p-3 4k:h-[5vh] 4k:text-2xl"
              label="Event Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputProps={{ style: inputStyles }}
            ></TextField>}
            <FormControl style={{ background: "white" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Select Category"
                onChange={(e) => setCategory(e.target.value)}
              >
                {(!PurchasesSelected && ( !name || name.trim() === '')) && (
                  <MenuItem value={"Purchases"}>Purchases</MenuItem>
                )}

                <MenuItem value={"PageView"}>Page View</MenuItem>
                <MenuItem value={"Scroll"}>Scroll</MenuItem>
                {(!AddtoCartSelected && ( !name || name.trim() === '')) && (
                <MenuItem value={"AddtoCart"}>Add To Cart</MenuItem>
                )}
                <MenuItem value={"Others"}>Others</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeInConversion}
                  onChange={(e) => setIncludeInConversion(e.target.checked)}
                />
              }
              label="Include In Conversion"
            />
            <button
              className="text-center cursor-pointer py-3 px-3 text-white w-1/3 my-[10px] rounded-lg bg-[#0082CC]"
              type="submit"
            >
              Add Event
            </button>
          </form>
          {/* table starts */}
          {event && event.length > 0 ? (
            <section className=" dark:bg-dark pt-6">
              <div className="container">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full ">
                    <div className="max-w-full overflow-x-auto">
                      <table className="w-full table-auto">
                        <thead className="text-center bg-primary">
                          <tr>
                            <th className={TdStyle.ThStyle}> Name </th>
                            <th className={TdStyle.ThStyle}> Category </th>
                            <th className={TdStyle.ThStyle}> Code </th>
                            <th className={TdStyle.ThStyle}>
                              {" "}
                              Include In Conversion
                            </th>
                            <th className={TdStyle.ThStyle}> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {event?.map((i) => (
                              <tr>
                                <td className={TdStyle.TdStyle}>
                                  {i?.Events[0]?.name||'Not Applicable'}
                                </td>
                                <td className={TdStyle.TdStyle2}>
                                  {i?.Events[0]?.category}
                                </td>
                                <td className={TdStyle.TdStyle2}>
                                  {" "}
                                  <ConversionCode
                                    category={i?.Events[0]?.category}
                                    script={i?.Script}
                                  />
                                </td>
                                <td className={TdStyle.TdStyle2}>
                                  {i?.Events[0]?.includeInConversion === true
                                    ? "Yes"
                                    : "No"}
                                </td>
                                <td className={TdStyle.TdStyle2}>
                                  <button
                                    onClick={() => deleteHandler(i?._id)}
                                    className={TdStyle.TdButton}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <h1 className="text-center font-semibold text-xl pt-10">
              No Events Yet
            </h1>
          )}
        </div>
      </div>
      <OverlayDisableAccountPage isOpen={openDisablePageModal} />
      <NetworkStatusOverlay/>
    </>
  );
}
export default Conversion;
