import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
  Label,
} from "recharts";

const formatData = (data) => {
  const dataMap = new Map();

  data.forEach((item) => {
    const date = new Date(item.date).toLocaleDateString();
    if (!dataMap.has(date)) {
      dataMap.set(date, 0);
    }
    dataMap.set(date, dataMap.get(date) + item.total_spent);
  });

  const formattedData = Array.from(dataMap.entries()).map(
    ([date, total_spent]) => ({
      fullDate: new Date(date),
      date,
      total_spent,
    })
  );

  return formattedData;
};

const LineChartReact = ({ data }) => {
  const formattedData = formatData(data);

  return (
    <div className="">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={formattedData}>
          {/* Remove horizontal and vertical grid lines */}
          <CartesianGrid horizontal={false} vertical={false} />
          <XAxis
            dataKey="date"
            tickFormatter={(date) => {
              const d = new Date(date);
              return `${d.getDate()}`; // Format as "day/month"
            }}
          >
            <Label value="Date" offset={-3} position="insideBottom" />
          </XAxis>
          <YAxis>
            <Label
              value="Total Spent"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
              offset={-0.2}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" height={36} />
          <Line
            type="monotone"
            dataKey="total_spent"
            strokeWidth={2}
            dot={{ r: 2 }}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload;
    const fullDate = dataPoint.fullDate;
    if (fullDate instanceof Date && !isNaN(fullDate)) {
      const formattedDate = `${fullDate.getDate()}/${
        fullDate.getMonth() + 1
      }/${fullDate.getFullYear()}`;

      return (
        <div className="custom-tooltip bg-white p-2 border border-gray-300 rounded-lg shadow-md">
          <p className="text-gray-800">{`Date: ${formattedDate}`}</p>
          <p className="text-gray-800">{`Total Spent: ${dataPoint?.total_spent?.toFixed(2)}`}</p>
        </div>
      );
    }
  }
  return null;
};

export default LineChartReact;
