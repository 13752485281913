import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const UniqueIdRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      // Function to be called when route changes to localhost/:id
      // Redirect to your desired location, e.g., sampledigital.shopify.com/:id
      window.location.href = `/`;
    }
  }, [id, navigate]);

  return null; // or any component you want to render for this route
};

export default UniqueIdRedirect;
