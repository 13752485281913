// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { getAllBusiness } from "./Api";
// import ButtonCampaign from "../ButtonCampaign";

// const Businesses_Admin = () => {
//   const [dataBusiness, setDataBusiness] = useState([]);
//   useEffect(() => {
//     getAllBusiness(setDataBusiness);
//   }, []);
//   const navigate = useNavigate();
//   return (
//     <div className="p-4 md:p-2 space-y-4 md:space-y-10">
//       <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row">
//         {/* graph  */}
//         {/* section  */}
//         <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw]  3xl:w-[40%]  3xl:h-[25vw]  4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]">
//           {/* section-1 */}
//           <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw]  8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh]   3xl:text-[1.3vw] 3xl:h-[8vw]  lgk:text-[1vw] lgk:w-[40vw]">
//             {/* 1card  */}

//             {/* 2 card  */}
//           </div>
//         </div>
//       </div>
//       <div className="bg-white py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
//         <table className="table-auto md:gap-2 text-center lg:w-[95%] sm:w-[95%]">
//           <thead>
//             <tr>
//               <th className="text-xs pr-[20px] md:text-[14px] font-semibold pl-8">
//                 Business
//               </th>
//               <th className="text-xs pr-[20px] md:text-[14px] pl-2">Status</th>
//               <th className="text-xs pr-[20px] md:text-[14px]">Total Cart</th>
//               <th className="text-xs pr-[20px] md:text-[14px]">Purchases</th>
//               <th className="text-xs pr-[20px] md:text-[14px]">Action</th>
//             </tr>
//           </thead>
//           <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
//             {dataBusiness.map((i) =>
//               i.companyId == undefined || i.companyId == null ? (
//                 <></>
//               ) : (
//                 <tr key={i.companyId._id}>
//                   <td
//                     className="text-xs pl-[20px] pt-[20px] md:text-lg cursor-pointer"
//                     onClick={() => {
//                       navigate(`/admin/influencers/${i.companyId._id}`);
//                     }}
//                   >
//                     <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
//                       <h1>{i.companyId.name}</h1>
//                       {/* <h1 className="mx-[5px]">Campaign</h1> */}
//                     </div>
//                   </td>
//                   <td
//                     className={`text-md pt-[20px] font-bold ${
//                       i.companyId.status === "Active"
//                         ? "text-[#00B528]"
//                         : "text-[#FF0000]"
//                     }`}
//                   >
//                     {i.companyId.status}
//                   </td>
//                   <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
//                     {i.total_add_to_carts.length}
//                   </td>
//                   <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
//                     {i.total_purchases.length}
//                   </td>
//                   <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] ">
//                     <ButtonCampaign
//                       label={i.companyId.status}
//                       id={i.companyId._id}
//                       setInfluencer={setDataBusiness}
//                       color="#D6F0FF"
//                       tcolor="black"
//                     />
//                   </td>
//                 </tr>
//               )
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Businesses_Admin;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllBusiness } from "./Api";
import ButtonCampaign from "../ButtonCampaign";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Businesses_Admin = () => {
  const [dataBusiness, setDataBusiness] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);


  useEffect(() => {
    getAllBusiness(setDataBusiness);
  }, []);

  const navigate = useNavigate();
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  const filteredData = dataBusiness.filter((item) =>
    item?.companyId?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="p-4 md:p-2 space-y-4 md:space-y-10">
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row">
        {/* graph  */}
        {/* section  */}
        <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw]  3xl:w-[40%]  3xl:h-[25vw]  4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]">
          {/* section-1 */}
          <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw]  8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh]   3xl:text-[1.3vw] 3xl:h-[8vw]  lgk:text-[1vw] lgk:w-[40vw]">
            {/* 1card  */}
            {/* 2 card  */}
          </div>
        </div>
      </div>

      {/* Display Businesses */}
      <div className="bg-white py-4 px-2 md:px-4 overflow-x-auto shadow-md rounded-md">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row mb-4">
          <input
            type="text"
            placeholder="Search by business name..."
            className="border border-gray-300 rounded-md p-2"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-blue-500 text-white rounded-sm">
              <th className="px-4 py-2">Business</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Total Cart</th>
              <th className="px-4 py-2">Purchases</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems
              ?.filter((i) => i?.companyId != null)
              .map((item) => (
                <tr key={item.companyId._id} className="text-center">
                  <td
                    className="px-4 py-2 cursor-pointer whitespace-nowrap overflow-hidden overflow-ellipsis items-center text-center"
                    onClick={() => {
                      navigate(`/admin/influencers/${item.companyId._id}`);
                    }}
                  >
                    {item.companyId.name}
                  </td>
                  <td
                    className={`px-4 py-2 ${
                      item.companyId.status === "Active"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {item.companyId.status}
                  </td>
                  <td className="px-4 py-2">
                    {item.total_add_to_carts.length}
                  </td>
                  <td className="px-4 py-2">{item.total_purchases.length}</td>
                  <td className="px-4 py-2">
                    <ButtonCampaign
                      label={item.companyId.status}
                      id={item.companyId._id}
                      setInfluencer={setDataBusiness}
                      color="#D6F0FF"
                      tcolor="black"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(filteredData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
};

export default Businesses_Admin;
