import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const UserAccountStatusSlice = createSlice({
  name: "userAccountStatus",
  initialState: {
    userAccountStatus: null,
  },
  reducers: {
    setUserAccountStatus: (state, action) => {
      state.userAccountStatus = action.payload;
    },
  },
});

export const { setUserAccountStatus } = UserAccountStatusSlice.actions;

export default UserAccountStatusSlice;

export const getUserAccountStatus = createAsyncThunk(
  "userAccountStatus/getUserAccountStatus",
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.cloutin.co/api/admin/business/getAccountStatus/:${companyId}`
        // `http://172.105.253.104:3001/api/admin/business/getAccountStatus/:${companyId}`
      );
      const data = await response.json();
      if (data) {
        return data?.isAccountSuspended;
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Something went wrong");
    }
  }
);
