import React, { useState } from "react";
import {
  FaLinkedin,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaTwitter,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://api.cloutin.co/api/business/contactus",
          formData
        );
        if (response.status === 200) {
          toast.success(response.data?.message);
          setFormData({ name: "", email: "", message: "" });
        }
      } catch (error) {
        toast.error(
          error?.response?.data?.message || "Unable to send the form"
        );
      }
    }
  };

  return (
    <div className="px-3 py-3">
      <div className="max-w-[95%] mx-auto min-h-[95vh] bg-white rounded-lg shadow-lg flex flex-col gap-2 md:flex-row overflow-hidden">
        <div className="w-full md:w-1/2 p-8 flex flex-col justify-between bg-fuchsia-100">
          <div>
            <h1 className="text-3xl font-bold mb-8">
              <Link to="/" className="text-blue-600 hover:text-blue-800">
                Cloutin
              </Link>
            </h1>
            <div className="space-y-6">
              <ContactInfo
                icon={<FaMapMarkerAlt />}
                title="Visit us"
                description="Come to our office"
                detail="Jodhpur, Rajasthan"
              />
              <ContactInfo
                icon={<FaPhone />}
                title="Call us"
                description="Mon-Fri from 9am to 6pm"
                detail="+91 75300003383"
              />
              <ContactInfo
                icon={<FaEnvelope />}
                title="Email us"
                description="We'll respond within 24 hours"
                detail="contact@cloutin.com"
              />
            </div>
          </div>
          <div className="mt-8 flex justify-start space-x-4">
            <SocialIcon
              icon={<FaLinkedin />}
              link={
                "https://www.linkedin.com/company/digitalshift-private-limited/posts/?feedView=all"
              }
            />
            <SocialIcon
              icon={<FaTwitter />}
              link={
                "https://www.linkedin.com/company/digitalshift-private-limited/posts/?feedView=all"
              }
            />
            <SocialIcon
              icon={<FaFacebookF />}
              link={
                "https://www.linkedin.com/company/digitalshift-private-limited/posts/?feedView=all"
              }
            />
            <SocialIcon
              icon={<FaInstagram />}
              link={
                "https://www.linkedin.com/company/digitalshift-private-limited/posts/?feedView=all"
              }
            />
          </div>
        </div>
        <div>
          <div className="w-[2px] h-full bg-black"></div>
        </div>
        <div
          className="w-full md:w-1/2 rounded-2xl p-8 flex flex-col justify-between"
          style={{
            backgroundColor: "rgb(209, 255, 117)",
          }}
        >
          <div>
            <h2 className="text-4xl font-semibold mb-4 text-gray-800">
              Get in Touch
            </h2>
            <p className="text-gray-600 mb-6">
              Tell us more about what's on your mind or any business queries you
              have.
            </p>
            <form className="space-y-4">
              <div className="relative">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 pt-6 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all peer"
                  placeholder=" "
                />
                <label
                  htmlFor="name"
                  className={`absolute left-4 top-2 text-gray-500 transition-all ${
                    formData.name
                      ? "text-xs top-1"
                      : "peer-focus:text-xs peer-focus:top-1"
                  }`}
                >
                  Enter your name
                </label>
              </div>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 pt-6 border ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all peer`}
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  className={`absolute left-4 top-2 text-gray-500 transition-all ${
                    formData.email
                      ? "text-xs top-1"
                      : "peer-focus:text-xs peer-focus:top-1"
                  }`}
                >
                  Enter your email
                </label>
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>
              <div className="relative">
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className={`w-full px-4 py-2 pt-6 border ${
                    errors.message ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all resize-none peer`}
                  placeholder=" "
                ></textarea>
                <label
                  htmlFor="message"
                  className={`absolute left-4 top-2 text-gray-500 transition-all ${
                    formData.message
                      ? "text-xs top-1"
                      : "peer-focus:text-xs peer-focus:top-1"
                  }`}
                >
                  Your message
                </label>
                {errors.message && (
                  <p className="text-red-500 text-sm mt-1">{errors.message}</p>
                )}
              </div>
            </form>
          </div>
          <button
            onClick={handleSubmitForm}
            className="w-full bg-gray-800 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition duration-300 text-lg font-semibold mt-4"
          >
            Let's Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

const ContactInfo = ({ icon, title, description, detail }) => (
  <div className="flex items-start">
    <span className="text-blue-600 text-xl mr-4">{icon}</span>
    <div>
      <h2 className="font-semibold text-lg">{title}</h2>
      <p className="text-gray-600">{description}</p>
      <span className="text-gray-800 font-medium">{detail}</span>
    </div>
  </div>
);

const SocialIcon = ({ icon, link }) => (
  <a href={link} className="text-gray-600 hover:text-blue-600 text-2xl">
    {icon}
  </a>
);

export default Contact;
