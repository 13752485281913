import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import campaigndashimg from '../imgs/campaigndashimg.png'
import maindashimg from '../imgs/maindashimg.png'
import mamarketplaceimg from  '../imgs/marketplaceimg.png'
import "./ds.css";

const DynamicSliding = () => {
  return (
    <div className="h-auto bg-[#0082CC] flex items-center justify-center ds-container">
      <DynamicCardLayout />
    </div>
  );
};

export default DynamicSliding;

export const DynamicCardLayout = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    { src: campaigndashimg, label: "Campaigns" },
    { src: maindashimg, label: "Analytics" },
    { src: mamarketplaceimg, label: "Influencers" },
  ];

  const goToPrevImage = () => {
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const goToNextImage = () => {
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="max-w-[1000px] container mx-auto p-8 flex flex-col md:flex-row items-center justify-center gap-8 ds-main">
      <div className="text-center md:text-left max-w-lg ds-heading">
        <h1 className="text-3xl md:text-4xl font-semibold text-white mb-4">
          Create Impactful <br />
          Campaigns
        </h1>
        <p className="text-lg md:text-xl text-white">
          Effortlessly launch and manage campaigns. Set objectives, budgets, and
          watch your brand's influence grow seamlessly.
        </p>
        <div className="flex justify-center md:justify-start items-center mt-5 gap-4">
          <FaAngleLeft
            className="bg-yellow-500 text-black border border-black rounded-full border-b-2 border-r-2 p-2 cursor-pointer hover:bg-yellow-600"
            size={30}
            onClick={goToPrevImage}
          />
          <div className="text-white flex items-center gap-2">
            {images.map((_, index) => (
              <span
                key={index}
                className={`w-3 h-3 rounded-full border border-black ${
                  currentImage === index ? "bg-[#5CF1A4]" : "bg-white"
                }`}
              ></span>
            ))}
          </div>
          <FaAngleRight
            className="bg-yellow-500 text-black border border-black rounded-full border-b-2 border-r-2 p-2 cursor-pointer hover:bg-yellow-600"
            size={30}
            onClick={goToNextImage}
          />
        </div>
      </div>
      <div className="relative w-full max-w-md">
        <div className="w-full h-72 overflow-hidden border border-b-4 border-r-4 border-black rounded-md">
          <img
            src={images[currentImage].src}
            alt={images[currentImage].label}
            className="object-cover w-full h-full transition-transform duration-300"
          />
        </div>
        <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-2">
          {images.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full ${
                currentImage === index ? "bg-blue-500" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
