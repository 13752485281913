import axios from "axios";
import React, { useEffect, useState } from "react";

const ButtonCampaign = ({
  label,
  color,
  tcolor,
  id,
  campaign_name,
  campaign_id,
  statusCampaign,
}) => {
  const [isEnabled, setIsEnabled] = useState();

  useEffect(() => {
    if (statusCampaign) {
      if (statusCampaign === "Active") {
        setIsEnabled(true);
      } else setIsEnabled(false);
    }
  }, []);

  const handleToggle = async () => {
    console.log(id, campaign_name, statusCampaign);
    let currstatus = statusCampaign;
    if (currstatus === "Active") currstatus = "UnActive";
    else currstatus = "Active";
    const update = await axios.put(
      "https://api.cloutin.co/api/campaign/updateStatusOfCampaign",
      { companyid: id, campaignID: campaign_id, status: currstatus }
    );

    const notification = await axios.post(
      "https://api.cloutin.co/api/notifications/post",
      {
        data: {
          influencerId: id,
          notificationTitle: "Post Status",
          notificationDesc: `You ${
            !isEnabled ? "enabled" : "disabled"
          } the status of your ${campaign_name} Campaign`,
        },
      }
    );
    setIsEnabled(!isEnabled);
  };
  return (
    <button
      className={`cursor-pointer rounded-xl py-2 px-2 font-bold text-xs transition duration-300 ease-in-out ${
        isEnabled ? "bg-blue-500 text-white" : "bg-gray-300 text-black"
      }`}
      onClick={handleToggle}
    >
      {isEnabled ? "Enabled" : "Disabled"}
    </button>
  );
};

export default ButtonCampaign;
