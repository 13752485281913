import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="bg-gradient-to-r from-blue-100 to-purple-100 py-12 px-4 sm:px-6 lg:px-8">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto text-center">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
          Welcome to{" "}
          <Link to="/" className="text-blue-500 cursor-pointer underline">
            Cloutin
          </Link>
        </h1>
        <p className="mt-4 text-lg leading-6 text-gray-600">
          The ultimate platform for influencers looking to elevate their brand
          and connect with top-tier opportunities. Our software is designed with
          influencers in mind, offering a seamless and intuitive experience to
          help you grow your influence, monetize your content, and engage with
          the brands that matter to you.
        </p>
      </div>

      {/* What We Offer Section */}
      <div className="mt-12 max-w-7xl mx-auto grid gap-12 md:grid-cols-3">
        {/* Campaign Applications */}
        <div className="bg-white shadow-neumorphism rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            Campaign Applications
          </h2>
          <p className="text-gray-600">
            Our platform allows influencers to easily apply for a variety of
            campaigns. Whether you're looking to partner with a brand for a
            specific project or participate in long-term collaborations, our
            application process is straightforward and efficient. Browse through
            available campaigns, submit your application, and track your
            progress all in one place.
          </p>
        </div>

        {/* Direct Marketplace Orders */}
        <div className="bg-white shadow-neumorphism rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            Direct Marketplace Orders
          </h2>
          <p className="text-gray-600">
            Get discovered by brands looking for influencers just like you. Our
            direct marketplace connects you with businesses that are seeking
            influencers for immediate orders. This feature ensures you never
            miss out on opportunities to showcase your unique voice and content
            to a broader audience.
          </p>
        </div>

        {/* Affiliate Campaigns */}
        <div className="bg-white shadow-neumorphism rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            Affiliate Campaigns
          </h2>
          <p className="text-gray-600">
            Boost your earnings through our affiliate campaigns. Join affiliate
            programs that align with your niche and audience, and start earning
            commissions by promoting products and services you love. Our
            platform makes it easy to find and join affiliate campaigns,
            providing you with the tools to track your performance and optimize
            your efforts.
          </p>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="mt-12 max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* User-Friendly Interface */}
        <div className="bg-white shadow-neumorphism rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            User-Friendly Interface
          </h2>
          <p className="text-gray-600">
            Our app is designed to be intuitive and easy to navigate, ensuring
            you can focus on what you do best – creating amazing content.
          </p>
        </div>

        {/* Comprehensive Dashboard */}
        <div className="bg-white shadow-neumorphism rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            Comprehensive Dashboard
          </h2>
          <p className="text-gray-600">
            Manage all your campaigns, track your earnings, and analyze your
            performance from a single, unified dashboard.
          </p>
        </div>

        {/* Top-Tier Opportunities */}
        <div className="bg-white shadow-neumorphism rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            Top-Tier Opportunities
          </h2>
          <p className="text-gray-600">
            We partner with leading brands and businesses to bring you the best
            opportunities in the industry.
          </p>
        </div>

        {/* Secure and Reliable */}
        <div className="bg-white shadow-neumorphism rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            Secure and Reliable
          </h2>
          <p className="text-gray-600">
            Your data and privacy are our top priorities. Our platform is built
            with robust security measures to protect your information.
          </p>
        </div>
      </div>

      {/* Join Our Community Section */}
      <div className="mt-12 max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-4">
          Join Our Community
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-600">
          Become part of a vibrant community of influencers who are shaping the
          future of marketing. With{" "}
          <Link
            to="https://cloutin.co"
            className="text-blue-500 font-semibold underline"
          >
            Cloutin{" "}
          </Link>
          you have the tools and support you need to succeed. Sign up today and
          start connecting with brands that value your unique influence.
        </p>
      </div>

      {/* Social Media Section */}
      <div className="mt-12 max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-900">Follow Us</h2>
        <p className="mt-4 text-lg leading-6 text-gray-600">
          Stay connected with us through our social media channels.
        </p>
        <div className="mt-8 flex justify-center space-x-6">
          <Link
            to="https://www.linkedin.com/company/digitalshift-private-limited/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="text-3xl text-blue-600 hover:text-blue-800" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/digitalshift-private-limited/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="text-3xl text-blue-400 hover:text-blue-600" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/digitalshift-private-limited/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="text-3xl text-blue-700 hover:text-blue-900" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/digitalshift-private-limited/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="text-3xl text-pink-600 hover:text-pink-800" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
