import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

const WarningMessage = ({message}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-600 text-white shadow-lg rounded-lg p-4 max-w-sm w-full z-50">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <svg
            className="w-6 h-6 text-white mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01M21 12c0-4.97-4.03-9-9-9S3 7.03 3 12s4.03 9 9 9 9-4.03 9-9z"
            />
          </svg>
          <span>{message}</span>
        </div>
        <button onClick={handleClose} className="ml-4 focus:outline-none">
          <XMarkIcon className="w-5 h-5 text-white" />
        </button>
      </div>
    </div>
  );
};

export default WarningMessage;
