import Posted from "../Posted";
import TableCamp from "../TableCamp";

function Affiliate2() {
  return (
    <div className="p-[20px] mr-[10px] md:p-2 space-y-[20px]   lg:h-[calc(100vh-102px)]">
      <div className="w-[120%] space-y-[20px]">
        <TableCamp />
      </div>

      <div className="bg-[#D9FDFF] text-[14px] rounded-lg p-2 w-fit">
        Influencers Applied
      </div>

      <div className="bg-white py-[10px] w-[95%] p-3 overflow-x-scroll md:w-[120%]">
        <table className="table-auto  md:gap-2 text-center lg:w-[95%]">
          <thead>
            <tr className="font-semibold">
              <th className="text-xs pr-[20px] md:text-[14px]">INFLUENCER</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Content</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Followers</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Following</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Engagement</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Reviews</th>
              <th className="text-xs flex pr-[20px] md:text-[14px]">
                <h1>Accept</h1>
                <h1 className="mx-[5px]"> Reject</h1>
              </th>
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px]">
            {/* {info.map((i) => ( */}

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-[20px] space-x-[5px]  md:text-[14px]">
                <h1 className="bg-red-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Reject
                </h1>
                <h1 className="bg-green-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Accept
                </h1>
              </td>
            </tr>

            <tr className="">
              <td className="text-sm pl-[20px] pt-[20px] md:text-lg text-[#707070] font-bold">
                <div className="flex  text-sm justify-center items-center font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-[20px] space-x-[5px]  md:text-[14px]">
                <h1 className="bg-red-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Reject
                </h1>
                <h1 className="bg-green-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Accept
                </h1>
              </td>
            </tr>

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-[20px] space-x-[5px]  md:text-[14px]">
                <h1 className="bg-red-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Reject
                </h1>
                <h1 className="bg-green-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Accept
                </h1>
              </td>
            </tr>

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-[20px] space-x-[5px]  md:text-[14px]">
                <h1 className="bg-red-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Reject
                </h1>
                <h1 className="bg-green-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Accept
                </h1>
              </td>
            </tr>

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-[20px] space-x-[5px]  md:text-[14px]">
                <h1 className="bg-red-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Reject
                </h1>
                <h1 className="bg-green-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Accept
                </h1>
              </td>
            </tr>

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-[20px] space-x-[5px]  md:text-[14px]">
                <h1 className="bg-red-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Reject
                </h1>
                <h1 className="bg-green-600 cursor-pointer px-2 text-white rounded-xl py-1">
                  Accept
                </h1>
              </td>
            </tr>
            {/* ))} */}
          </tbody>
        </table>
      </div>

      <div className="bg-[#D9FDFF] text-[14px] rounded-lg p-2 w-fit">
        Accepted Influencers (NOP)
      </div>

      <div className="bg-white py-[10px] w-[95%] p-3 overflow-x-scroll md:w-[120%]">
        <table className="table-auto  md:gap-2 text-center lg:w-[95%]">
          <thead>
            <tr>
              <th className="text-xs pr-[20px] md:text-[14px]">INFLUENCER</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Content</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Followers</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Following</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Engagement</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Reviews</th>
              <th className="text-xs flex pr-[20px] md:text-[14px]">Post</th>
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px]">
            {/* {info.map((i) => ( */}
            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-6 space-x-[5px]  md:text-[14px] text-[#707070] font-bold">
                Not Posted
              </td>
            </tr>

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-6 space-x-[5px]  md:text-[14px] text-[#707070] font-bold">
                Not Posted
              </td>
            </tr>

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-6 space-x-[5px]  md:text-[14px] text-[#707070] font-bold">
                Not Posted
              </td>
            </tr>

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-6 space-x-[5px]  md:text-[14px] text-[#707070] font-bold">
                Not Posted
              </td>
            </tr>

            <tr className="">
              <td className="text-xs pl-[20px] pt-[20px] md:text-lg">
                <div className="flex  text-[14px] justify-center items-center text-[#707070] font-bold">
                  <img
                    className="w-6 mr-[5px] h-6 rounded-full"
                    src="/Images/pro1.png"
                    alt="profile"
                  />
                  <h1>Richard</h1>
                  <h1 className="mx-[5px]">Wright</h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] text-[#707070] font-bold">
                21
              </td>
              <td className="text-xs flex pt-6 space-x-[5px]  md:text-[14px] text-[#707070] font-bold">
                Not Posted
              </td>
            </tr>
            {/* ))} */}
          </tbody>
        </table>
      </div>

      <div className="bg-[#D9FDFF] text-[14px] rounded-lg p-2 w-fit text-black font-extrabold">
        Posted
      </div>
    </div>
  );
}

export default Affiliate2;
