import { io } from "socket.io-client";

let socket;

export const initializeSocket = () => {
  socket = io("https://api.cloutin.co");
  socket.on("connect", () => {
    console.log("Socket Connected");
  });
};

export const registerUser = (userId) => {
  if (!socket) {
    throw new Error("Socket not initialized");
  }
  socket.emit("register", userId);
};

export const subscribeToNotifications = (userId, callback) => {
  if (!socket) {
    throw new Error("Socket not initialized");
  }
  socket.on("notification", callback);
};

export const disconnectSocket = () => {
  if (!socket) {
    throw new Error("Socket not initialized");
  }
  socket.disconnect();
};
