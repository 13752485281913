import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate, useLocation, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import axios from "axios";
import { FaGoogle } from "react-icons/fa";
import GoogleOAuthButton from "./googleOauthButton";
import { FaUpload } from "react-icons/fa";
import { SpinnerCircular } from "spinners-react";
import {
  base64ToBlob,
  blobToBase64,
  calculateImageSizeInMB,
  compressImage,
} from "../utils/ImageCompression";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../redux/Slice/userSlice";
import { MdOutlineCloudUpload } from "react-icons/md";

const firebaseConfig = {
  apiKey: "AIzaSyCdKrWn76N6_0NvGHuOeFxIA5id8kU1Sjw",
  authDomain: "collabb-firebase.firebaseapp.com",
  projectId: "collabb-firebase",
  storageBucket: "collabb-firebase.appspot.com",
  messagingSenderId: "473209548641",
  appId: "1:473209548641:web:7b572fe9030624640e1f20",
  measurementId: "G-P0L8M9EBN1",
};
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("id");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleCaptchaChange = (value) => {
    setCaptcha(value);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const [cover, setCover] = useState("");
  const [selectedImg1, setSelectedImg1] = useState("");
  const [previewSource1, setPreviewSource1] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [fileInputState1, setFileInputState1] = useState("");
  const [desc, setDesc] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleImage1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage1(file);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };
  const previewFile1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource1(reader.result);
    };
  };

  const [previewAvatar, setPreviewAvatar] = useState("");

  const handleFileInputChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      previewFile1(file);
      setSelectedImg1(file);
      setFileInputState1(e.target.value);
      setDesc("");
      handleImage1(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const uploadImage1 = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading1(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await axios.post(
        "https://api.cloutin.co/api/upload/file1",
        // "https://api.cloutin.co/api/upload/file1",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );

      setFileInputState1("");
      setPreviewSource1("");
      setCover(data.publicUrl);
      setLoading1(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setLoading1(false);
    }
  };
  const dispatch = useDispatch();
  const signUp = async (e) => {
    e.preventDefault();

    const entry = {
      name: name,
      email: email,
      password: password,
      username: username,
      register_type: true,
      phone: phone,
      url: url,
      company: company,
      image: cover,
    };
    try {
      if (!name)
        return toast.error("Please enter your name", {
          duration: 1000,
          position: "top-center",
        });
      if (!email)
        return toast.error("Please enter your email", {
          duration: 1000,
          position: "top-center",
        });
      if (!username)
        return toast.error("Please enter your username", {
          duration: 1000,
          position: "top-center",
        });
      if (!password || password.trim() === "")
        return toast.error("Please enter your password", {
          duration: 1000,
          position: "top-center",
        });
      if (!phone)
        return toast.error("Please enter your phone number", {
          duration: 1000,
          position: "top-center",
        });
      if (!url)
        return toast.error("Please enter your company website url", {
          duration: 1000,
          position: "top-center",
        });
      if (!company)
        return toast.error("Please enter your company name", {
          duration: 1000,
          position: "top-center",
        });
      if (confirmPassword === "" || confirmPassword.trim() === "") {
        return toast.error("Please enter confirm password", {
          duration: 1000,
          position: "top-center",
        });
      }
      if (
        password !== confirmPassword ||
        password.trim() !== confirmPassword.trim()
      ) {
        return toast.error("Password does not match", {
          duration: 1000,
          position: "top-center",
        });
      }

      // if (!cover)
      //   return toast.error("Please upload your profile photo", {
      //     duration: 1000,
      //     position: "top-center",
      //   });
      const { data } = await axios.post(
        "https://api.cloutin.co/api/company/register",
        // "https://api.cloutin.co/api/company/register",
        entry
      );
      setName("");
      setEmail("");
      setPassword("");
      setUrl("");
      setCompany("");
      setPhone("");
      setUsername("");
      setImage("");
      setConfirmPassword("");
      Cookies.set("username", data.username, { expires: 30, path: "/" });
      Cookies.set("name", data.name, { expires: 30, path: "/" });
      Cookies.set("userid", data.id, { expires: 30, path: "/" });
      Cookies.set("token", data.token, { expires: 30, path: "/" });
      Cookies.set("image", data.image, { expires: 30, path: "/" });
      localStorage.setItem("token", data?.token);
      dispatch(loginSuccess());
      toast.success("Register successfully");
      navigate("/maindashboard");
    } catch (error) {
      return toast.error(
        error?.response?.data?.message ||
          "Internal Server occured...Please try again later",
        {
          duration: 3000,
          position: "top-center",
        }
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/maindashboard");
    }
  }, [navigate]);

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="hidden md:flex md:w-1/2 lg:w-1/3 bg-gradient-to-b from-indigo-800 to-purple-700 text-white p-8 flex flex-col justify-center items-center md:items-start">
        <div className="mb-8 text-center md:text-left">
          {/* Company Branding */}
          <h1 className="text-4xl md:text-5xl font-bold mb-2">Cloutin</h1>
          <p className="text-gray-300">
            Marketplace, Influencer, and Campaigns
          </p>
        </div>
        <div className="max-w-md">
          {/* Company Features */}
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Features</h2>
          <ul className="list-disc pl-4 text-lg">
            <li className="mb-2">Seamless marketplace experience</li>
            <li className="mb-2">Connect with top influencers</li>
            <li className="mb-2">Powerful campaign management tools</li>
          </ul>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex-1 bg-white p-8 flex flex-col justify-center mt-[2rem]">
        <div className="max-w-4xl w-full mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center">
            Join Cloutin
          </h2>

          {/* {userId && <GoogleOAuthButton userId={userId} />}
          {!userId && <GoogleOAuthButton />} */}

          <form
            className="grid grid-cols-1 md:grid-cols-2 gap-4"
            onSubmit={signUp}
          >
            {/* Name */}
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="block mb-2 font-bold text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Username */}
            <div className="flex flex-col">
              <label
                htmlFor="username"
                className="block mb-2 font-bold text-gray-700"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            {/* Company Name */}
            <div className="flex flex-col">
              <label
                htmlFor="companyName"
                className="block mb-2 font-bold text-gray-700"
              >
                Company Name
              </label>
              <input
                type="text"
                id="companyName"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your company name"
                name="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>

            {/* Mobile Number */}
            <div className="flex flex-col">
              <label
                htmlFor="mobileNumber"
                className="block mb-2 font-bold text-gray-700"
              >
                Mobile Number
              </label>
              <input
                type="tel"
                id="mobileNumber"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your mobile number"
                name="phone"
                value={phone}
                // onChange={(e) => setPhone(e.target.value)}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (regex.test(value)) {
                    setPhone(value);
                  }
                }}
              />
            </div>

            {/* Company Website URL */}
            <div className="flex flex-col">
              <label
                htmlFor="companyWebsite"
                className="block mb-2 font-bold text-gray-700"
              >
                Company Website URL
              </label>
              <input
                type="url"
                id="companyWebsite"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your company website URL"
                name="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>

            {/* Company Email */}
            <div className="flex flex-col">
              <label
                htmlFor="companyEmail"
                className="block mb-2 font-bold text-gray-700"
              >
                Company Email
              </label>
              <input
                type="email"
                id="companyEmail"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your company email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* Password */}

            <div className="flex flex-col relative">
              <label
                htmlFor="password"
                className="block mb-2 font-bold text-gray-700"
              >
                Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 pr-10"
                  placeholder="Enter your password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-3 bg-transparent focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            {/* confirm password */}
            <div className="flex flex-col relative">
              <label
                htmlFor="password"
                className="block mb-2 font-bold text-gray-700"
              >
                Confirm Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 pr-10"
                  placeholder="Enter again your password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-3 bg-transparent focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>

            <div>
              <div className="border-2 border-gray-500 rounded-md inline-block px-2 py-2 text-blue-500">
                <label
                  htmlFor="file-upload"
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <MdOutlineCloudUpload className="hover:text-blue-600" size={24} />
                  <span className="hover:text-blue-600">
                    {loading1 ? "Uploading..." : "Upload your profile photo"}
                  </span>
                </label>

                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  value={fileInputState1}
                  onChange={handleFileInputChange1}
                  className={`hidden ${loading1 ? "cursor-not-allowed" : ""}`}
                  disabled={loading1}
                />
              </div>
            </div>
            {previewAvatar && (
              <div className="w-20 h-20 rounded-full shadow-md bg-gray-200 flex items-center justify-center text-gray-500 font-bold mb-2 relative">
                <img
                  src={previewAvatar}
                  alt="Avatar Preview"
                  className="w-20 h-20 rounded-full object-cover"
                />
              </div>
            )}

            <div className="col-span-1 md:col-span-2 flex justify-center">
              <ReCAPTCHA
                sitekey="6LdZ7ewpAAAAAOslWJq1oC_cPdbpPbforwzWcJjl" // Replace with your reCAPTCHA site key
                onChange={handleCaptchaChange}
              />
            </div>
            {/* Submit Button */}
            <div className="col-span-1 md:col-span-2">
              <button
                type="submit"
                className="bg-indigo-600 text-white rounded-md px-4 py-2 hover:bg-indigo-700 transition-colors duration-300 w-full font-bold"
              >
                Join Cloutin
              </button>
            </div>
          </form>
          <div className="mt-4 text-center">
            <span className="text-gray-600">Already have an account?</span>
            <Link
              to="/login"
              className="ml-2 text-blue-500 font-semibold hover:underline"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
