import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {FaEdit} from 'react-icons/fa'

const AdminTransaction = () => {
    const [withdrawData, setWithdrawData] = useState([]);
    const [completedData, setCompletedData] = useState([]);
    const [refundData, setRefundData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [comissionRate, setComissionRate] = useState(0);
    const [newRate, setNewRate] = useState(0);
    const [gstRate, setGstRate] = useState(0);
    const [platformCharge, setPlatformCharge] = useState(0);
    const [rateType, setRateType] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch all transactions
                const transactionsResponse = await axios.get('https://api.cloutin.co/api/admin/transactions/getalltransactions');
                const transactionsData = transactionsResponse.data;
    
                // Filter transactions
                const withdrawals = transactionsData.filter(transaction => transaction.status === 0);
                const completions = transactionsData.filter(transaction => transaction.status === 1);
    
                setWithdrawData(withdrawals); // Set withdrawals data
    
                // Fetch refunds data
                const refundsResponse = await axios.get('https://api.cloutin.co/api/admin/refunds/getRefundsData');
                const refundsData = refundsResponse.data;
    
                // Filter refunds
                const pendingRefunds = refundsData.filter(transaction => transaction.status === 0);
                const completedRefunds = refundsData.filter(transaction => transaction.status === 1); // Ensure status is correct
    
                // Combine completions and completed refunds
                const allCompletedData = [...completions, ...completedRefunds];
    
                setCompletedData(allCompletedData); // Set completed data
                setRefundData(pendingRefunds); // Set refunds data
    
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    useEffect(() => {
        const fetchTaxData = async () => {
            try {
                const response = await axios.get('https://api.cloutin.co/api/admin/taxes/gettaxdata');
                const { comission_rate, gst_rate, platform_charge } = response.data;
                setComissionRate(comission_rate);
                setGstRate(gst_rate);
                setPlatformCharge(platform_charge);
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchTaxData();
    }, []);

    const handleEditClick = (type) => {
        setRateType(type);
        setNewRate(
            type === 'comission' ? comissionRate :
                type === 'gst' ? gstRate :
                    platformCharge
        );
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleSaveRate = async () => {
        try {
            const response = await axios.put('https://api.cloutin.co/api/admin/taxes/update/taxdata', {
                type: rateType,
                tax_amount: newRate
            });

            if (response.status === 200) {
                if (rateType === 'comission') {
                    setComissionRate(newRate);
                } else if (rateType === 'gst') {
                    setGstRate(newRate);
                } else {
                    setPlatformCharge(newRate);
                }
                setModalOpen(false);
            } else {
                console.error('Failed to update rate');
            }
        } catch (error) {
            console.error('Error updating rate:', error);
        }
    };

    return (
        <div className="overflow-x-auto p-4 bg-gray-100 min-h-screen">
            <Link to={'/admin/kyc-verification'}>
                <div className="flex items-center text-xl font-semibold text-blue-800 mb-5">
                    Kyc Verification
                    <svg className="w-6 h-6 ml-2 text-blue-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
            </Link>

            <div className="flex items-center justify-between mb-6">
                <p className="text-3xl font-bold">Transactions</p>
                <div className="flex justify-center gap-4 items-center">
                    <div className="flex items-center justify-between shadow-md rounded-lg bg-white p-3">
                        <p className="font-medium">
                            Comission Rate: <span className='font-semibold text-red-500'>{comissionRate}%</span>
                        </p>
                        <button className="text-white bg-blue-500 rounded-full px-3 py-2 ml-2 transition-transform transform hover:scale-105" onClick={() => handleEditClick('comission')}>
                            <FaEdit/>
                        </button>
                    </div>
                    <div className="flex items-center justify-between shadow-md rounded-lg bg-white p-3">
                        <p className="font-medium">
                            GST Rate: <span className='font-semibold text-red-500'>{gstRate}%</span>
                        </p>
                        <button className="text-white bg-blue-500 rounded-full px-3 py-2 ml-2 transition-transform transform hover:scale-105" onClick={() => handleEditClick('gst')}>
                            <FaEdit/>
                        </button>
                    </div>
                    <div className="flex items-center justify-between shadow-md rounded-lg bg-white p-3">
                        <p className="font-medium">
                            Platform Charge: <span className='font-semibold text-red-500'>{platformCharge}%</span>
                        </p>
                        <button className="text-white bg-blue-500 rounded-full px-3 py-2 ml-2 transition-transform transform hover:scale-105" onClick={() => handleEditClick('platform')}>
                            <FaEdit/>
                        </button>
                    </div>
                </div>
            </div>

            <table className="table-auto w-full border-collapse bg-white shadow-lg rounded-lg">
                <thead>
                    <tr className="bg-blue-600 text-white">
                        <th className="px-6 py-4 text-left">
                            <div className='flex gap-2'>
                                <p>Withdrawal Requests</p>
                                <div className="h-6 w-6 bg-red-500 rounded-full flex items-center justify-center">{withdrawData.length}</div>
                            </div>
                        </th>
                        <th className="px-6 py-4 text-left">
                            <div className='flex gap-2'>
                                <p>Completed Transactions</p>
                                <div className="h-6 w-6 bg-red-500 rounded-full flex items-center justify-center">{completedData.length}</div>
                            </div>
                        </th>
                        <th className="px-6 py-4 text-left">
                            <div className='flex gap-2'>
                                <p>Refund Requests</p>
                                <div className="h-6 w-6 bg-red-500 rounded-full flex items-center justify-center">0</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-blue-50 border-b border-gray-200 hover:bg-blue-100 transition">
                        <td className="border px-6 py-4 align-top">
                            {withdrawData.length > 0 ? (
                                withdrawData.map((transaction, index) => (
                                    <Link to='/admin/transaction/details' state={transaction} key={`withdrawal-${index}`}>
                                        <div className="flex justify-between items-start mb-4 p-4 bg-white rounded-lg shadow transition-transform transform hover:scale-105 border">
                                            <div>
                                                <span className="block font-semibold">{transaction.influencer_name}</span>
                                                <span className="text-gray-500 text-sm">
                                                    {new Date(transaction.createdAt).toLocaleDateString()} {new Date(transaction.createdAt).toLocaleTimeString()}
                                                </span>

                                            </div>
                                            <span className="text-green-500 font-bold">₹ {transaction.Bank_Details?.amount}</span>
                                        </div>
                                    </Link>
                                ))
                            ) : (
                                <span>No Data</span>
                            )}
                        </td>
                        <td className="border px-6 py-4 align-top">
                            {completedData.length > 0 ? (
                                completedData.map((transaction, index) => (
                                    <div key={`completion-${index}`} className="flex justify-between items-start mb-4 p-4 bg-white rounded-lg shadow transition-transform transform hover:scale-105 border">
                                        <div>
                                            <span className="block font-semibold">{transaction.influencer_name||transaction?.refund_initiator_name}</span>
                                            <span className="text-gray-500 text-sm">
                                                {new Date(transaction.updatedAt).toLocaleDateString()} {new Date(transaction.updatedAt).toLocaleTimeString()}
                                            </span>

                                        </div>
                                        <span className="text-green-500 font-bold">₹ {transaction.Bank_Details?.amount||transaction?.amount}</span>
                                    </div>
                                ))
                            ) : (
                                <span>No Data</span>
                            )}
                        </td>
                        <td className="border px-6 py-4 align-top">

                        {refundData.length > 0 ? (
                                refundData.map((transaction, index) => (
                                    <Link to={transaction?.campaign_id ?`/admin/refund/details/${transaction._id}`:`/admin/marketplace/refund/details/${transaction._id}`}>
                                    <div key={`completion-${index}`} className="flex justify-between items-start mb-4 p-4 bg-white rounded-lg shadow transition-transform transform hover:scale-105 border">
                                        <div>
                                            <span className="block font-semibold">{transaction?.refund_initiator_name}</span>
                                            <span className="text-gray-500 text-sm">
                                                {new Date(transaction?.updatedAt).toLocaleDateString()} {new Date(transaction?.updatedAt).toLocaleTimeString()}
                                            </span>

                                        </div>
                                        <span className="text-green-500 font-bold">₹ {transaction?.amount}</span>
                                    </div>
                                    </Link>
                                ))
                            ) : (
                                <span>No Data</span>
                            )}

                        </td>
                    </tr>
                </tbody>
            </table>

            {modalOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded shadow-lg transition-transform transform scale-105">
                        <p className="text-lg font-bold mb-4">Enter {rateType === 'comission' ? 'Comission' : rateType === 'gst' ? 'GST' : 'Platform'} Rate</p>
                        <input
                            type="number"
                            className="border border-gray-300 rounded p-2 mb-4 w-full"
                            value={newRate}
                            onChange={e => setNewRate(e.target.value)}
                        />
                        <div className="flex justify-end">
                            <button className="px-4 py-2 bg-blue-500 text-white rounded mr-2 transition-transform transform hover:scale-105" onClick={handleSaveRate}>Done</button>
                            <button className="px-4 py-2 bg-gray-500 text-white rounded transition-transform transform hover:scale-105" onClick={handleModalClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminTransaction;
