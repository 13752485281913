import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { RxExternalLink } from "react-icons/rx";

function TableCamp() {
  const campaignId = window.location.pathname.split("/").pop();

  // Use idFromURL as your campaign ID

  const [campaignData, setCampaignData] = useState(null);

  // Assuming you have a function to fetch campaign data by ID
  const fetchCampaignData = async (campaignId) => {
    try {
      // Replace with your API endpoint and fetch logic using axios
      const response = await axios.get(
        `https://api.cloutin.co/api/campaign/${campaignId}`
      );
      if (response?.status === 200) {
        // Check the status code, not response.ok
        const data = response?.data; // Use response.data instead of response.json()
        setCampaignData(data);
      } else {
        // console.error("Failed to fetch campaign data");
        toast.error("Failed to fetch campaign data", {
          duration: 1500,
          position: "top-center",
        });
      }
    } catch (error) {
      // console.error("Error fetching campaign data:", error);
      toast.error("Error fetching campaign data", {
        duration: 1500,
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    if (campaignId) {
      fetchCampaignData(campaignId);
    }
  }, [campaignId]);
  if (!campaignData) {
    // Campaign data is being fetched or does not exist
    return <div className="text-center text-3xl text-gray-500">Loading...</div>;
  }


  return (
    <div className="text-[14px] flex md:flex-row flex-col h-fit bg-white rounded-md py-[20px] px-[20px] shadow-inner">
      <div className="md:basis-[45%]">
        <img
          className="max-w-md  h-[300px] object-contain m-auto rounded-md"
          src={campaignData?.cover_image_url}
          alt="tag"
        />
      </div>

      <div className="md:basis-[50%] flex md:mt-0 mt-[20px] flex-col justify-start space-y-[10px] mr-[20px]">
        <div className="flex items-center">
          <h1 className="font-extrabold text-black text-[18px] w-full ">
            Campaign: {`${campaignData?.name}`}
          </h1>
          <img
            className="w-9 h-6"
            src={
              campaignData?.platform === "Youtube"
                ? "/Images/youtube.png"
                : campaignData?.platform === "Instagram"
                  ? "/Images/insta.png"
                  : campaignData?.platform === "Facebook"
                    ? "/Images/facebook.png"
                    : "/Images/default.png" // Default image for other platforms
            }
            alt="Platform Icon"
          />
        </div>
        <div className="flex justify-between text-[12px] my-[20px] lg:text-[11px] xl:text-[14px] items-center">
          <h1 className="text-black font-bold text-lg mb-5">
            {campaignData?.category}
          </h1>
          <div className="flex flex-col">
            <h1 className="text-black font-bold">Campaign Type</h1>
            <h1 className="text-right text-[#727272] font-bold text-[16px]">
              {campaignData?.type}
            </h1>
          </div>
        </div>
        {campaignData?.campEndDate && (
          <div className="flex flex-row flex-wrap items-center gap-[10px] text-sm">
            <h1 className="text-[16px] font-bold">Campaign End Date: </h1>
            <p className="text-green-500 font-semibold underline">
              {/* {campaignData?.campEndDate?.split("T")[0]} */}
              {campaignData?.campEndDate}
            </p>
          </div>
        )}

        <div>
          <h1 className="text-[16px] font-bold">Description:</h1>
          <p className="text-[#727272] text-[13px] text-ellipsis">
            {campaignData?.description?.length === 0
              ? "No description found"
              : campaignData?.description}
          </p>
        </div>
        <div>
          <h1 className="text-[16px] font-bold">
            Instructions for influencers:
          </h1>
          <p className="text-[#727272] text-[12px]">
            {campaignData?.instruction?.length === 0
              ? "No instruction found"
              : campaignData?.instruction}
          </p>
        </div>
        {/* visit row  */}
        <div className="flex justify-between">
          <h1 className="text-[16px] font-extrabold text-black">
            {campaignData?.cpm ? `CPM: ${campaignData?.cpm}` : "CPM : NAN"}
          </h1>
          {campaignData?.campEndDate !== "" &&
            new Date().toISOString().split("T")[0] >
            campaignData?.campEndDate && (
              <div>
                <h1>Campaign has been ended</h1>
              </div>
            )}

          {campaignData?.url && (
            <a
              href={campaignData?.url.startsWith('http') ? campaignData.url : `https://${campaignData.url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 cursor-pointer rounded-3xl flex items-center justify-start text-white px-4 py-2 8k:text-[1vw] 3xl:text-[1vw] 4k:text-[1.3vw] shadow-md"
            >
              <h1 className="mr-3">View Link</h1>
              <RxExternalLink />
            </a>
          )}

        </div>
      </div>
    </div>
  );
}

export default TableCamp;
