import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonIndividualCampaign from "./ButtonIndividualCampaign";
import { getAllCompaignByUserId } from "./Api";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/Slice/loadingSlice";
import toast from "react-hot-toast";
import Loader from "../../../utils/Loader";
import axios from "axios";
import BusinessCampaignSales from "./BusinessCampaignSales";

function CampaignAdmin2() {
  const { id6 } = useParams();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState([]);
  const [campDashData, setCampDashData] = useState([]);
  const { loading } = useSelector((state) => state.loading);

  const dispatch = useDispatch();

  const countApprovedStatuses = (campaign) => {
    if (campaign.applicationStatusTimeline) {
      return campaign.applicationStatusTimeline.filter(
        (status) => status.status === "Approved"
      ).length;
    }
    return 0;
  };

  const countDisapprovedStatuses = (campaign) => {
    if (campaign.applicationStatusTimeline) {
      return campaign.applicationStatusTimeline.filter(
        (status) => status.status === "Disapproved"
      ).length;
    }
    return 0;
  };

  const fetchCampaigns = async () => {
    try {
      dispatch(showLoading());
      await getAllCompaignByUserId(setCampaign, id6);
    } catch (error) {
      toast.error("Error fetching campaigns");
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    if (id6) {
      fetchCampaigns();
    }
  }, [id6, dispatch]);
  const handleCampaignStatusChange = () => {
    fetchCampaigns();
  };
  const getCampDashData = async () => {
    try {
      const res = await axios.get(
        `https://api.cloutin.co/api/campaigndata/getcampdashdata/${id6}`
      );
      setCampDashData(res?.data?.data);
    } catch (error) {
      toast.error("Unable to fetch the data");
    }
  };
  useEffect(() => {
    getCampDashData();
  }, [id6]);

  const campaignData = [
    {
      title: "Approved Influencers",
      value: campDashData?.approved_influencer,
      icon: "/Images/influencer_main.png",
      // trend: "+10%",
    },
    {
      title: "Total Clicks",
      value: campDashData?.clicks,
      icon: "/Images/click.png",
      // trend: "-10%",
    },
    {
      title: "Total Earnings",
      value: campDashData?.total_earning,
      icon: "/Images/sales.png",
      // trend: "+10%",
    },
    {
      title: "Pending Influencers",
      value: campDashData?.pending_influencers,
      icon: "/Images/influencer_main.png",
      // trend: "-10%",
    },
    {
      title: "Potential Reach",
      value: campDashData?.potential_reach,
      icon: "/Images/reach.png",
      // trend: "+10%",
    },
    {
      title: "Total Posts",
      value: campDashData?.total_post,
      icon: "/Images/social_main.png",
      // trend: "-10%",
    },
  ];

  if (loading) {
    return <Loader loadingText="campaigns " />;
  }

  return (
    <div className="p-4 space-y-6 bg-gray-100">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Sales Generated Chart */}
        <BusinessCampaignSales id6={id6} />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {campaignData.map((item, index) => (
            <div
              key={index}
              className="flex items-center p-4 border rounded-md bg-white shadow-sm hover:shadow-md transition-all duration-300 ease-in-out"
            >
              <img
                src={item.icon}
                alt={item.title}
                className="w-10 h-10 mr-4"
              />
              <div>
                <h3 className="text-lg font-semibold">{item.title}</h3>
                <p className="text-xl">{item.value}</p>
                {/* Uncomment if you want to show the trend */}
                {/* <span className="text-sm text-gray-500">{item.trend}</span> */}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Campaign List */}
      <div>
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Campaign List</h2>
        {loading ? (
          <Loader loadingText="Loading campaigns..." />
        ) : (
          <div className="bg-white rounded-lg shadow-md overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-500 text-white">
                <tr>
                  {[
                    "Campaign Name",
                    "Status",
                    "No. of Influencers",
                    "Approved",
                    "Rejected",
                    "Spent",
                    "Clicks",
                    "Conversions",
                    "Action",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {campaign &&
                  campaign[0]?.data?.map((i) => (
                    <tr
                      key={i._id}
                      className={`${
                        new Date(i?.campEndDate) < new Date()
                          ? "bg-red-100"
                          : ""
                      }`}
                    >
                      <td
                        className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                        onClick={() =>
                          navigate(`/admin/campaign/page3/${i._id}`)
                        }
                      >
                        {i.name}
                      </td>
                      <td
                        className={`px-4 py-4 whitespace-nowrap text-sm font-medium ${
                          i.statusCampaign === "Active"
                            ? "text-green-600"
                            : "text-red-600"
                        }`}
                      >
                        {i.statusCampaign}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {i.requiredInfluencers}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {countApprovedStatuses(i)}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {countDisapprovedStatuses(i)}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        0
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {i?.clicks}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        0
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(i?.campEndDate) < new Date() ? (
                          <span className="px-2 py-1 text-xs font-medium bg-red-100 text-red-800 rounded-full">
                            Campaign Ended
                          </span>
                        ) : (
                          <ButtonIndividualCampaign
                            label={i.status}
                            id={i._id}
                            userId={id6}
                            setCampaign={setCampaign}
                            color="#D6F0FF"
                            tcolor="black"
                            onStatusChange={handleCampaignStatusChange}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default CampaignAdmin2;
