import axios from "axios";
const campaignUrl = "https://api.cloutin.co/api/affiliate";
const baseUrl = "https://api.cloutin.co/api/Business";

const getAllBusiness = (setDataBusiness) => {
  axios.get(baseUrl).then(({ data }) => {
    setDataBusiness(data["company"]);
  });
};

const getAllAffiliatesByCompanyId = (setAllAffiliates, companyId) => {
  axios.get(`${campaignUrl}/${companyId}`).then(({ data }) => {
  
    setAllAffiliates(data);
  });
};

const getAffiliatesByAffiliateId = (setAffiliate, affiliateId) => {
  axios.get(`${campaignUrl}/getAffiliate/${affiliateId}`).then(({ data }) => {
    setAffiliate(data);
  });
};

const updateAffiliateStatus = (affiliateId, selectedStatus) => {
  return axios
    .put(`${campaignUrl}/${affiliateId}/status`, {
      affiliate_status: selectedStatus,
    })
    .catch((error) => {
      console.error("Error updating status:", error);
      throw error;
    });
};

const getAllAffiliatesStatusCounts = (companyId) => {
  return axios
    .get(`${campaignUrl}/status/${companyId}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.error("Error getting affiliate status counts:", error);
      throw error; // Re-throw the error to be caught by the caller
    });
};

export {
  getAllAffiliatesByCompanyId,
  updateAffiliateStatus,
  getAllBusiness,
  getAllAffiliatesStatusCounts,
  getAffiliatesByAffiliateId,
};
