import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const IndividualAffiliateCampaign = () => {
  const { id20 } = useParams();
  const [selectedOption, setSelectedOption] = useState("Month");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [salesData, setSalesData] = useState(null);
  const affiliateDetail = async (affiliateId) => {
    try {
      const res = await axios.get(
        `https://api.cloutin.co/api/affiliate/getAffiliate/${affiliateId}`
      );
  
    } catch (error) {
      toast.error("Error getting data");
    }
  };
  useEffect(() => {
    affiliateDetail(id20);
  }, [id20]);

  const fetchSalesData = async () => {
    try {
      let url = `https://api.cloutin.co/api/affiliate/particularAffiliate/${id20}`;
      if (selectedOption === "Month") {
        url += `?month=${selectedMonth}&year=${selectedYear}`;
      } else {
        url += `?year=${selectedYear}`;
      }
      const res = await axios.get(url);
      setSalesData(res?.data);
     
    } catch (error) {
      toast.error("Error getting sales data");
    }
  };

  useEffect(() => {
    fetchSalesData();
  }, [id20, selectedOption, selectedMonth, selectedYear]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  const formatChartData = () => {
    if (!salesData) return [];

    if (selectedOption === "Year") {
      return salesData.data.map((monthData) => ({
        month: new Date(selectedYear, monthData.month - 1).toLocaleString(
          "default",
          { month: "short" }
        ),
        purchases: monthData.monthlyTotalPurchases,
        likes: monthData.monthlyTotalLikes,
      }));
    } else {
      return salesData.dailyData.map((day) => ({
        date: new Date(day.date).getDate(), // Just show the day number
        purchases: day.purchases,
        likes: day.likes,
      }));
    }
  };

  const chartData = formatChartData();

  const campaignData = [
    {
      title: "Approved Influencers",
      value: 0,
      icon: "/Images/influencer_main.png",
      // trend: "+10%",
    },
    {
      title: "Total Clicks",
      value: 0,
      icon: "/Images/click.png",
      // trend: "-10%",
    },
    {
      title: "Total Earnings",
      value: 0,
      icon: "/Images/sales.png",
      // trend: "+10%",
    },
    {
      title: "Pending Influencers",
      value: 0,
      icon: "/Images/influencer_main.png",
      // trend: "-10%",
    },
    {
      title: "Potential Reach",
      value: 0,
      icon: "/Images/reach.png",
      // trend: "+10%",
    },
    {
      title: "Total Posts",
      value: 0,
      icon: "/Images/social_main.png",
      // trend: "-10%",
    },
  ];

  return (
    <div className="p-4 space-y-6 bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold text-gray-800">Sales Generated</h1>
          <div className="flex space-x-4">
            <select
              className="bg-gray-200 rounded-full py-2 px-4 text-sm focus:outline-none"
              value={selectedYear}
              onChange={handleYearChange}
            >
              {[...Array(5)].map((_, i) => (
                <option key={i} value={new Date().getFullYear() - i}>
                  {new Date().getFullYear() - i}
                </option>
              ))}
            </select>
            {selectedOption === "Month" && (
              <select
                className="bg-gray-200 rounded-full py-2 px-4 text-sm focus:outline-none"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                {[...Array(12)].map((_, i) => (
                  <option key={i} value={i + 1}>
                    {new Date(0, i).toLocaleString("default", {
                      month: "long",
                    })}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData}>
            <Line
              type="monotone"
              dataKey="purchases"
              name="Purchases"
              stroke="#8884d8"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="likes"
              name="Likes"
              stroke="#82ca9d"
              strokeWidth={2}
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis
              dataKey={selectedOption === "Year" ? "month" : "date"}
              allowDuplicatedCategory={false}
            />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {campaignData.map((item, index) => (
          <div
            key={index}
            className="flex items-center p-4 border rounded-md bg-white shadow-sm hover:shadow-md transition-all duration-300 ease-in-out"
          >
            <img src={item.icon} alt={item.title} className="w-10 h-10 mr-4" />
            <div>
              <h3 className="text-lg font-semibold">{item.title}</h3>
              <p className="text-xl">{item.value}</p>
              {/* Uncomment if you want to show the trend */}
              {/* <span className="text-sm text-gray-500">{item.trend}</span> */}
            </div>
          </div>
        ))}
      </div>
      <div>
        <h1>List of Influencers Associated with this Campaigns</h1>
        <div className="bg-white rounded-lg shadow-md overflow-x-auto"></div>
      </div>
    </div>
  );
};

export default IndividualAffiliateCampaign;
