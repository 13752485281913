// import React from "react";
// import { FaAngleRight } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// const Header = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="min-h-[560px] flex justify-center items-center flex-col gap-10 px-4 md:px-8 bg-[#0082CC]">
//       <div className="text-center">
//         <h1 className="text-4xl md:text-6xl lg:text-7xl text-white font-bold leading-tight">
//           Unleash{" "}
//           <button
//             className="px-4 py-2 rounded-full bg-orange-500 text-white border-4 border-white shadow-lg inline-block transition-transform hover:scale-105"
//             style={{ transform: "rotate(-3deg)" }}
//           >
//             Data Driven
//           </button>{" "}
//           Influencer
//           <br />
//           <span className="block md:inline-block mx-2 md:mx-8 mt-4 md:mt-0">
//             Partnerships with Collabb
//           </span>
//         </h1>
//       </div>
//       <div className="bg-white border-4 border-black rounded-full shadow-xl hover:shadow-2xl transition-all duration-300">
//         <button
//           className="flex items-center gap-3 text-2xl md:text-3xl px-8 py-4 font-semibold text-purple-800"
//           onClick={() => {
//             navigate("/login");
//           }}
//         >
//           Get Started
//           <span className="mt-1 animate-bounce">
//             <FaAngleRight />
//           </span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Header;

import React from "react";
import {
  FaAngleRight,
  FaInstagram,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./header.css";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-[560px] mt-[2rem] flex justify-center items-center flex-col gap-10 px-4 md:px-8 bg-[#0082CC] relative overflow-hidden">
      <div className="text-center">
        <h1 className="text-4xl md:text-6xl lg:text-7xl text-white font-bold leading-tight">
          Unleash{" "}
          <button
            className="px-4 py-2 rounded-full bg-orange-500 text-white border-4 border-white shadow-lg inline-block transition-transform hover:scale-105"
            style={{ transform: "rotate(-3deg)" }}
          >
            Data Driven
          </button>{" "}
          Influencer
          <br />
          <span className="block md:inline-block mx-2 md:mx-8 mt-4 md:mt-0">
            Partnerships with Cloutin
          </span>
        </h1>
      </div>
      <div className="bg-white border-4 border-black rounded-full shadow-xl hover:shadow-2xl transition-all duration-300">
        <button
          className="flex items-center gap-3 text-2xl md:text-3xl px-8 py-4 font-semibold text-purple-800"
          onClick={() => {
            navigate("/login");
          }}
        >
          Get Started
          <span className="mt-1 animate-bounce">
            <FaAngleRight />
          </span>
        </button>
      </div>

      {/* Animated social media icons */}
      <div className="absolute inset-0 pointer-events-none">
        <FaInstagram
          className="text-white text-4xl absolute animate-float"
          style={{ top: "10%", left: "5%", animationDelay: "0s" }}
        />
        <FaFacebookF
          className="text-white text-4xl absolute animate-float"
          style={{ top: "20%", right: "10%", animationDelay: "1s" }}
        />
        <FaTwitter
          className="text-white text-4xl absolute animate-float"
          style={{ bottom: "15%", left: "15%", animationDelay: "2s" }}
        />
        <FaInstagram
          className="text-white text-4xl absolute animate-float"
          style={{ bottom: "25%", right: "5%", animationDelay: "3s" }}
        />
      </div>
    </div>
  );
};

export default Header;
