import React from "react";
import { motion } from "framer-motion";
import collabb from "../imgs/collabb.png";
import star from "../imgs/star.png";

const DynamicConnectionSection = () => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-indigo-50 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="flex flex-col lg:flex-row items-center justify-between gap-6"
        >
          {/* Image Grid */}
          <motion.div
            className="relative grid grid-cols-2 gap-6"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <img
              src={star}
              alt="star"
              className="absolute -top-8 -left-8 h-16 w-16 animate-spin-slow"
            />
            {[1, 2, 3, 4].map((index) => (
              <motion.div
                key={index}
                className="bg-white border-2 border-black shadow-brutal w-32 h-32 sm:w-40 sm:h-40 rounded-lg overflow-hidden"
                whileHover={{ rotate: index === 3 ? -3 : 0 }}
              >
                <img
                  src={collabb}
                  alt="collabb"
                  className="w-full h-full object-cover"
                />
              </motion.div>
            ))}
          </motion.div>

          {/* Content */}
          <motion.div
            className="content max-w-xl"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h2 className="text-4xl sm:text-5xl font-bold leading-tight mb-6">
              Facilitating{" "}
              <span className="text-blue-600">dynamic Connections</span>:
              <br />
              Linking brands seamlessly with
              <br />
              Influencers
            </h2>
            <p className="text-gray-700 text-xl sm:text-2xl leading-relaxed">
              Effortlessly forge impactful brand-influencer connections through
              our strategic approach, amplifying your brand's story with
              authentic engagements that matter.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="mt-8 px-8 py-4 bg-blue-600 text-white text-xl font-semibold rounded-full shadow-brutal hover:bg-blue-700 transition duration-300"
            >
              Get Started
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default DynamicConnectionSection;
