import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

const GoogleReg = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [url, setUrl] = useState("");
  const [searchParams] = useSearchParams();
  const [image, setImage] = useState("");
  const [token, setToken] = useState();
  const [urlError, setUrlError] = useState(false);

  const validateURL = (value) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.([a-z]{2,})|((\\d{1,3}\\.){3}\\d{1,3}))|" +
        "localhost|" +
        "\\d{1,3}\\.)" +
        "(:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i" // fragment locator
    );
    return !!urlPattern.test(value);
  };

  const handleURLChange = (e) => {
    const { value } = e.target;
    setUrl(value);
    if (validateURL(value)) {
      setUrlError("");
    } else {
      setUrlError("Please enter a valid URL.");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/maindashboard");
    }
  });

  const registerUser = async (e) => {
    try {
      const accessToken = searchParams.get("accessToken");
      const userId = searchParams.get("userId"); // Get the userid from the URL
      if (name.trim() === "" || name.length == 0) {
        return toast.error("Please enter the name", {
          duration: 1500,
          position: "top-center",
        });
      }
      if (company.trim() === "" || company.length == 0) {
        return toast.error("Please enter the company name", {
          duration: 1500,
          position: "top-center",
        });
      }
      if (phone.trim() === "" || phone.length == 0) {
        return toast.error("Please enter the phone number", {
          duration: 1500,
          position: "top-center",
        });
      }
      if (url.trim() === "" || url.length == 0) {
        return toast.error("Please enter the company url", {
          duration: 1500,
          position: "top-center",
        });
      }
      if (name !== "" && phone !== "" && company !== "" && url !== "") {
        const requestData = {
          accessToken,
          flag: 0,
          name,
          company,
          phone,
          url,
        };

        // Conditionally add the userid to the request if it exists
        if (userId) {
          requestData.userId = userId;
        }
        const { data } = await axios.post(
          "https://api.cloutin.co/api/company/googleAuth",
          // "https://api.cloutin.co/api/company/googleAuth",
          requestData
        );
   

        setName("");
        setCompany("");
        setPhone("");
        setUrl("");
        Cookies.set("username", data.username, { expires: 30, path: "/" });
        Cookies.set("name", data.name, { expires: 30, path: "/" });
        Cookies.set("token", data.token, { expires: 30, path: "/" });
        Cookies.set("userid", data.id, { expires: 30, path: "/" });
        localStorage.setItem("token", data.token);
        // console.log("sfadjbdsafkjdskjfkjsfdgew12323412", data.id);
        navigate("/maindashboard");
      } else {
        toast.error("Please fill all fields!!");
        navigate("/login");
      }
    } catch (err) {
      // console.log(err);
      return toast.error(
        err?.response?.data?.message || "Something went wrong",
        {
          duration: 1000,
          position: "top-center",
        }
      );
    }
  };
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-6">
      <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col md:flex-row w-full md:max-w-4xl ">
        <div className="md:w-1/2 mb-6 md:mb-0 ">
          <h1
            className="text-3xl font-bold text-blue-500 mb-4"
            onClick={() => navigate("/")}
          >
            Cloutin
          </h1>
          <ul className="space-y-2 text-blue-700">
            <li>• Connect with top influencers</li>
            <li>• Boost your brand visibility</li>
            <li>• Analytics and insights</li>
          </ul>
        </div>
        <div
          className="md:w-1/2"
          onClick={() => {
            // signInWithPopup(auth, provider).then((data) => {
            //   console.log(data.user.email);
            // });
          }}
        >
          <form className="space-y-4">
            <div>
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Name"
                name="name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-gray-700">Company Name</label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Company Name"
                name="company"
                value={company}
                required
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-gray-700">Phone</label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Phone Number"
                name="phone"
                value={phone}
                required
                // onChange={(e) => setPhone(e.target.value)}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (regex.test(value)) {
                    setPhone(value);
                  }
                }}
              />
            </div>
            <div>
              <label className="block text-gray-700">Company Website URL</label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Company Website URL"
                name="url"
                value={url}
                required
                // onChange={(e) => setUrl(e.target.value)}
                onChange={handleURLChange}
              />
              {urlError && <p className="text-red-500 text-sm">{urlError}</p>}
            </div>
            <div>
              <button
                type="button"
                className="w-full px-4 py-2 bg-red-600 text-white rounded-lg focus:outline-none hover:bg-red-700"
                onClick={registerUser}
              >
                Sign Up with Google
              </button>
            </div>
          </form>
          <div>
            <p className="pl-2 pt-2">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/login")}
                className="text-blue-500 underline cursor-pointer"
              >
                Sign in with google
              </span>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleReg;
