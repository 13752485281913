import React from "react";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import "../home/homemain.css";

const Mission = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="bg-[#0082CC] min-h-[360px] flex justify-center items-center flex-col gap-7 text-white mission-main px-4 py-16"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div className="text-center" variants={itemVariants}>
        <h1 className="text-3xl md:text-4xl font-semibold">Our Mission</h1>
      </motion.div>
      <motion.div className="text-center max-w-3xl mx-auto" variants={itemVariants}>
        <p className="text-lg md:text-xl">
          At CLOUTIN, our mission is to seamlessly connect brands with the right influencers,
          amplifying your brand's story with genuine and impactful engagements.
          Through our strategic approach, we aim to create a lasting influence and drive real results.
        </p>
      </motion.div>
      <motion.div
        className="flex flex-col md:flex-row items-center justify-center gap-8"
        variants={containerVariants}
      >
        <motion.div
          className="flex flex-col items-center"
          variants={itemVariants}
        >
          <h1 className="text-4xl font-semibold">
            <CountUp end={400} duration={3} />+
          </h1>
          <span className="text-lg">Influencers</span>
        </motion.div>
        <motion.div
          className="flex flex-col items-center"
          variants={itemVariants}
        >
          <h1 className="text-4xl font-semibold">
            <CountUp end={123} duration={3} separator="," />M
          </h1>
          <span className="text-lg">Views</span>
        </motion.div>
        <motion.div
          className="flex flex-col items-center"
          variants={itemVariants}
        >
          <h1 className="text-4xl font-semibold">
            <CountUp end={233} duration={3} />+
          </h1>
          <span className="text-lg">Campaigns</span>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Mission;
