import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: {
    campaignsData: [],
    allCampaigns: [],
    error: null,
  },
  reducers: {
    clearCampData: (state) => {
      state.campaignsData = [];
    },
    allCampaigns: (state, action) => {
      state.allCampaigns = action.payload;
    },
    clearErrorCamp: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaignsData.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(fetchCampaignsData.fulfilled, (state, action) => {
      state.campaignsData = action.payload;
    });
    builder.addCase(fetchCampaignsData.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const fetchCampaignsData = createAsyncThunk(
  "/cmpaigns/getConversionCampaignsData",
  async ({ currentUserId }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.cloutin.co/api/campaign/company/${currentUserId}`
      );

      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);


export const { clearCampData, allCampaigns, clearErrorCamp } = campaignsSlice.actions;

export default campaignsSlice.reducer;
