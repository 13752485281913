import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllBusiness } from "../../utils/HandleApi";
import ButtonCampaign from "./ButtonCampaign";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import toast from "react-hot-toast";

const Businesses_Admin = () => {
  const [dataBusiness, setDataBusiness] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSuspendPopupOpen, setIsSuspendPopupOpen] = useState(false);
  const [accountSuspendedReason, setAccountSuspendedReason] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAllBusiness(setDataBusiness);
  }, []);

  const handleSuspendClick = (companyId) => {
    setCompanyId(companyId);
    setIsSuspendPopupOpen(true);
  };

  const handleSuspendCancel = () => {
    // setSelectedBusiness(null);
    setAccountSuspendedReason("");
    setIsSuspendPopupOpen(false);
  };
  const handleSuspendSubmit = async () => {
    try {
      if (accountSuspendedReason.length < 30) {
        toast.error("Please add more details for suspension");
        return;
      }
      const response = await axios.post(
        `https://api.cloutin.co/api/admin/business/suspend/:${companyId}`,
        {
          accountSuspendedReason,
        }
      );
      if (response?.status === 200) {
        toast.success(
          response?.data?.message || "Business suspended successfully"
        );
        setCompanyId("");
        setAccountSuspendedReason("");
      }
      getAllBusiness(setDataBusiness);
    } catch (error) {
      toast.error("Error while suspending the business");
    }
    setIsSuspendPopupOpen(false);
  };
  //relase the suspended account
  const handleReleaseSuspendClick = async (companyId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.cloutin.co/api/admin/business/release/:${companyId}`
      );
      if (response?.status === 200) {
        toast.success(
          response?.data?.message || "Business released successfully"
        );
        getAllBusiness(setDataBusiness);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error while releasing the business"
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = dataBusiness.filter(
    (item) =>
      item.companyId &&
      item.companyId.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="p-4 md:p-2 space-y-4 md:space-y-10">
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row">
        {/* graph */}
        {/* section */}
        <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw] 3xl:w-[40%] 3xl:h-[25vw] 4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]">
          {/* section-1 */}
          <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw] 8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh] 3xl:text-[1.3vw] 3xl:h-[8vw] lgk:text-[1vw] lgk:w-[40vw]">
            {/* 1card */}
            {/* 2 card */}
          </div>
        </div>
      </div>
      <h1>asfsdf</h1>
      <div className="bg-white shadow-md rounded-md py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
        <div className="flex items-center mb-4">
          <input
            type="text"
            placeholder="Search by business name..."
            className="border border-gray-300 rounded-md p-2 mr-4"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <table className="table-auto md:gap-2 text-center lg:w-[95%] sm:w-[95%]">
          <thead>
            <tr className="bg-blue-400 rounded-sm text-white">
              <th className="text-xs pr-[20px] md:text-[14px] font-semibold pl-8 py-3">
                Business
              </th>
              <th className="text-xs pr-[20px] md:text-[14px] pl-2">Status</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Total Cart</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Purchases</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Action</th>
              <th className="text-xs pr-[20px] md:text-[14px]">Suspend</th>
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
            {/* {currentItems.map((i) => ( */}
            {currentItems &&
              currentItems
                .filter((item) => item.companyId != null)
                .map((i) => (
                  <tr key={i.companyId && i.companyId._id}>
                    <td className="text-xs pl-[20px] pt-[20px] md:text-lg cursor-pointer">
                      <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
                        {i.companyId && <h1>{i.companyId.name}</h1>}
                      </div>
                    </td>
                    <td
                      className={`text-md pt-[20px] font-bold ${
                        i.companyId && i.companyId.status === "Active"
                          ? "text-[#00B528]"
                          : "text-[#FF0000]"
                      }`}
                    >
                      {i?.companyId?.isAccountSuspended === true
                        ? "Suspended"
                        : i.companyId && i.companyId.status}
                      {/* {i.companyId && i.companyId.status} */}
                    </td>
                    <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                      {i.total_add_to_carts.length}
                    </td>
                    <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                      {i.total_purchases.length}
                    </td>
                    <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] ">
                      {i?.companyId?.isAccountSuspended === true ? (
                        <button
                          className="hover:text-blue-400 hover:underline"
                          onClick={() =>
                            handleReleaseSuspendClick(i?.companyId?._id)
                          }
                        >
                          {loading ? "Releasing..." : "Release"}
                        </button>
                      ) : (
                        <ButtonCampaign
                          label={i.companyId && i.companyId.status}
                          id={i.companyId && i.companyId._id}
                          setInfluencer={setDataBusiness}
                          color="#D6F0FF"
                          tcolor="black"
                        />
                      )}
                    </td>
                    <td className="text-xs pt-[20px] md:text-[14px] flex  flex-col items-center gap-2 font-semibold text-[#707070]">
                      <button
                        onClick={() => handleSuspendClick(i?.companyId?._id)}
                        disabled={i?.companyId?.isAccountSuspended}
                        className={
                          i?.companyId?.isAccountSuspended
                            ? "bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                            : "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        }
                      >
                        {i?.companyId?.isAccountSuspended
                          ? "Suspended"
                          : "Suspend"}
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>

        {isSuspendPopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
            <div className="bg-white p-8 rounded-md">
              <h2 className="text-lg font-semibold mb-4">Suspend Business</h2>
              <textarea
                rows="3"
                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                placeholder="Reason for suspension"
                value={accountSuspendedReason}
                onChange={(e) => setAccountSuspendedReason(e.target.value)}
              ></textarea>
              <div className="flex justify-end">
                <button
                  onClick={handleSuspendCancel}
                  className="mr-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSuspendSubmit}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Businesses_Admin;
