import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "../redux/Slice/loadingSlice";
import { SpinnerCircular } from "spinners-react";
import { Pagination, Stack, TextField } from "@mui/material";
import BasicModal from "../Modal";
import ChatBox from "./ChatBox";
import ErrorPage from "../utils/ErrorPage";
import { formateDate } from "../utils/helperFunction";

const HiredInfluencer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loading);
  const companyId = Cookies.get("userid");

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    fetchData();
  }, [companyId]);

  useEffect(() => {
    const filtered = data.filter((item) =>
      item.influencer_id?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchQuery, data]); // Run this effect whenever searchQuery or data changes

  const fetchData = async () => {
    dispatch(showLoading());
    try {
      const response = await axios.get(
        `https://api.cloutin.co/api/marketplace/getOrderDataByCompanyId/${companyId}`
      );
      setData(response?.data);
    } catch (error) {
      setErrors(true);
    } finally {
      setErrors(false);
      dispatch(hideLoading());
    }
  };

  const getStatusMessage = (status) => {
    const statusMessages = {
      0: <h1 className=" text-yellow-600">Pending</h1>,
      1: <h1 className=" text-green-600">Approved</h1>,
      2: <h1 className=" text-blue-600">Requested for Edit</h1>,
      3: "Waiting For influencer to post",
      4: <h1 className=" text-blue-600">Requested for Refund</h1>,
    };
    return statusMessages[status] || "Refund Cancelled";
  };


  const [warningModal, setWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  // State to manage the popup visibility and rejection reason
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const [successModal, setSuccessModal] = useState(false);
  // Function to handle reject action

  const [item, setItem] = useState(false);

  const handleRejectClick = (item) => {
    setItem(item)
    setShowRejectPopup(true);
  };

  // Function to handle cancel in the popup
  const handleCancel = () => {
    setShowRejectPopup(false);
    setRejectionReason('');
  };



  const handleSubmit = (forceFlag = null) => {
    handleApprove(
      item?.influencer_id?._id,
      item?._id,
      2,
      "rejected",
      rejectionReason,
      forceFlag
    )
    setShowRejectPopup(false);
    if (forceFlag){
      setWarningModal(false)
      setRejectionReason('');
    }
  }



  const handleApprove = async (influencerId, id, status, reason,rejectionReason = null,forceFlag) => {
    try {

      
      const response = await axios
        .put("https://api.cloutin.co/api/marketplace/updatePostStatus", {
          influencer_id: influencerId,
          market_place_order_id: id,
          status,
          reason,
          rejectionReason,
          forceFlag
        })

      if (response?.data?.warning) {
        setWarningModal(true);
        setWarningMessage(response?.data?.warning);
      }

      else if (response?.data?.ticketForRefund) {
        setWarningModal(true);
        setWarningMessage(response?.data?.ticketForRefund);
      }


      else if (response?.data?.refundRequested) {

        setSuccessModal(true);
        setWarningMessage(response?.data?.refundRequested);

        setTimeout(() => {
          fetchData();
          setSuccessModal(false); 
        }, 3000)

      }

      else {
        const finalstatus = status === 1 ? "Approved" : "Disapproved";
        // Send notification
        await axios.post("https://api.cloutin.co/api/notifications/post", {
          data: {
            influencerId: influencerId,
            notificationTitle: "Your MarketPlace Post Status",
            notificationDesc: `Your MarketPlace post is ${finalstatus}`,
          },
        });
        fetchData();
      }

    }
    catch (error) {
      console.error("Error:", error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem); // Use filteredData instead of data

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="container mx-auto px-4 py-6 space-y-6">
      {errors && <ErrorPage />}
      <h1 className="text-2xl font-bold text-center border-b-2 border-gray-300 pb-2">
        Hired Influencers
      </h1>

      {/* Search Input */}
      <div className="flex justify-center mb-4">
        <TextField
          label="Search Influencers"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {loading ? (
        <div className="flex items-center justify-center h-64">
          <SpinnerCircular speed={300} color="blue" />
          <span className="ml-4 text-xl">Loading Data...</span>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto shadow-md rounded-lg">
            <table className="w-full table-auto text-sm">
              <thead className="bg-gray-200 text-black">
                <tr>
                  {[
                    "Name",
                    "Engagement",
                    "Spent",
                    "Revenue",
                    "Likes",
                    "Comments",
                    "Purchases",
                    "Add To Cart",
                    "Purchase Value",
                    "Status",
                    "Followers",
                    "Clicks",
                    "ROAS",
                    "Messages",
                    "Accepted At",
                    "Edit Requested At",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-3 py-2 font-semibold text-left"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan="14" className="text-center py-4 text-gray-500">
                      No Hired Influencers
                    </td>
                  </tr>
                ) : (
                  currentItems.map((item) => (
                    <>
                      {showRejectPopup && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                          <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
                            <h2 className="text-lg font-semibold mb-4">What do you want to be edited?</h2>
                            <p className="mb-4">Please explain in detail:</p>
                            <textarea
                              className="w-full h-32 p-2 border rounded-lg mb-4"
                              value={rejectionReason}
                              onChange={(e) => setRejectionReason(e.target.value)}
                              placeholder="Enter rejection reason here..."
                            ></textarea>
                            <div className="flex justify-end space-x-2">
                              <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-green-500 text-white px-4 py-2 rounded-lg"
                                onClick={()=>handleSubmit()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

{warningModal && <WarningModal  setWarningModal={setWarningModal} warningMessage={warningMessage} onProceed={handleSubmit} influencer={item} />}
{successModal && <SuccessModal  setSuccessModal={setSuccessModal}  message={warningMessage} FaCheckCircle={FaCheckCircle} setWarningModal={setWarningModal}/>}

                      <tr
                        key={item._id}
                        className={`border-b text-[#707070] ${item.post_status === 1
                            ? "bg-green-100 hover:bg-green-200"
                            : "hover:bg-gray-50"
                          }`}
                      >
                        <td className="px-3 py-2 whitespace-nowrap">
                          {item.post_status === 1 ? (
                            <BasicModal
                              name={item?.influencer_id?.name}
                              enganmentRate={item?.engagement_rate?.toFixed(2)}
                              spent={item?.spent}
                              likes={item?.likes}
                              comments={item?.comments}
                              influencerId={item?.influencer_id?._id}
                            />
                          ) : (
                            <span className="text-gray-700">
                              {item?.influencer_id?.name}
                            </span>
                          )}
                        </td>
                        <td className="px-3 py-2">
                          {item?.engagement_rate?.toFixed(2)}
                        </td>
                        <td className="px-3 py-2">{item.spent}</td>
                        <td className="px-3 py-2">{item.revenue}</td>
                        <td className="px-3 py-2">{item.likes}</td>
                        <td className="px-3 py-2">{item.comments}</td>
                        <td className="px-3 py-2">{item.no_of_purchases}</td>
                        <td className="px-3 py-2">{item.no_of_add_to_carts}</td>
                        <td className="px-3 py-2">{item.purchase_value}</td>
                        <td className="px-3 py-2">
                          <span className="font-semibold">
                            {getStatusMessage(item?.post_status)}
                          </span>
                          {item?.post_status === 0 && (
                            <div className="flex gap-1 mt-1">
                              <button
                                className="px-2 py-1 bg-green-500 text-white rounded text-xs"
                                onClick={() =>
                                  handleApprove(
                                    item?.influencer_id?._id,
                                    item?._id,
                                    1,
                                    "approved"
                                  )
                                }
                              >
                                Approve
                              </button>
                              <button
                                className="px-2 py-1 bg-red-500 text-white rounded text-xs"
                                onClick={()=>handleRejectClick(item)}
                              >
                                Edit
                              </button>
                            </div>
                          )}
                           {item?.post_status === 5 && (
                             <div className="flex gap-1 mt-1">
                             <button
                               className="px-2 py-1 bg-green-500 text-white rounded text-xs"
                               onClick={() =>
                                 handleApprove(
                                   item?.influencer_id?._id,
                                   item?._id,
                                   1,
                                   "approved"
                                 )
                               }
                             >
                               Approve
                             </button>
                             </div>
                           )}
                        </td>
                        <td className="px-3 py-2 text-center">
                          {item.followers}
                        </td>
                        <td className="px-3 py-2">{item.clicks}</td>
                        <td className="px-3 py-2">
                          {parseFloat(item.roas).toFixed(2)}%
                        </td>
                        <td className="px-3 py-2">
                          {item.post_status === 1 ? (
                            <span className="text-red-600">Chat Ended</span>
                          ) : (
                            <ChatBox
                              chatId={`${item?._id}${companyId}${item?.influencer_id?._id}`}
                              companyId={companyId}
                              influencerId={item?.influencer_id?._id}
                            />
                          )}
                        </td>
                        <td className="px-3 py-2">
                          {item?.acceptedAt
                            ? formateDate(item?.acceptedAt)
                            : "----"}
                        </td>
                        <td className="px-3 py-2">
                          {item?.rejectedAt ? formateDate(item?.rejectedAt) : "----"}
                        </td>
                      </tr>
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center mt-4">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)} // Use filteredData for pagination count
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                color="primary"
                size="small"
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
};

export default HiredInfluencer;


const WarningModal = ({  setWarningModal, warningMessage, onProceed, influencer }) => {
  // If the modal is not open, do not render it

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        {/* Modal Header */}
        <h2 className="text-xl font-bold mb-4">Warning</h2>

        {/* Warning Message */}
        <p className="mb-4">{warningMessage}</p>

        {/* Modal Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={() => setWarningModal(false)}
          >
            Close
          </button>
          <button
            className={` ${warningMessage.includes("We can't process more than 3 edit requests")?'bg-green-500':'bg-red-500'} text-white px-4 py-2 rounded`}
            onClick={() => onProceed(true)}
          >
            {`${warningMessage.includes("We can't process more than 3 edit requests")?'Raise Refund Ticket':'Proceed'}`}
          </button>
        </div>
      </div>
    </div>
  );
};


// SuccessModal.js


const SuccessModal = ({setSuccessModal, message ,FaCheckCircle, setWarningModal}) => {


  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        {/* Header */}
        <div className="flex items-center justify-center mb-4">
          <FaCheckCircle className="text-green-500 text-4xl" /> {/* Green Tick Icon */}
        </div>
        <h2 className="text-center text-2xl font-semibold mb-4">Success</h2>
        
        {/* Message */}
        <p className="text-center text-gray-700 mb-6">{message}</p>

        {/* Close Button */}
        <div className="flex justify-center">
          <button
            onClick={()=>{setWarningModal(false); setSuccessModal(false)}}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

