import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineNumber } from "react-icons/ai";
import { CiCalendarDate } from "react-icons/ci";
import { RiHistoryLine } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import { useParams } from "react-router-dom";

function BusinessTransaction() {
  const [transactions, setTransactions] = useState([]);
  const params = useParams();
  const companyId = params.id13;
  const influencerId = params.id9;

  useEffect(() => {
    const fetchTransactions = async () => {
      let Url = `https://api.cloutin.co/api/businesswallet/getBusinessTransaction/${companyId}`;
      if (influencerId) {
        Url = `https://api.cloutin.co/api/influencerWallets/getInfluencerTransaction/${influencerId}`;
      }

      const transactionsResponse = await axios.get(Url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      setTransactions(transactionsResponse.data);
    };
    fetchTransactions();
  }, []);

  return (
    <div className=" flex justify-center flex-col w-full items-center mt-5">
      <p className=" text-3xl font-bold mt-2">
        {influencerId == null
          ? "Business Transactions"
          : "Influencer Transactions"}
      </p>
      <div className="w-full overflow-x-auto p-8">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">
                <AiOutlineNumber className="inline-block mr-2" />
                Order No.
              </th>
              <th className="py-3 px-6 text-left">
                <CiCalendarDate className="inline-block mr-2" />
                Date
              </th>
              <th className="py-3 px-6 text-left">
                <RiHistoryLine className="inline-block mr-2" />
                State
              </th>
              <th className="py-3 px-6 text-left">
                <GrTransaction className="inline-block mr-2" />
                Trx. Type
              </th>
              <th className="py-3 px-6 text-left">
                {/* <MdAttachMoney className="inline-block mr-2" /> */}
                Total Price
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {transactions && transactions.length > 0 ? (
              transactions.map((transaction) => (
                <tr
                  key={transaction._id}
                  className="border-b border-gray-200 hover:bg-gray-100 text-center"
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    Wl-{transaction._id.slice(-4)}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {new Date(transaction.createdAt).toLocaleDateString(
                      "en-GB"
                    )}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap uppercase font-semibold">
                    <span
                      className={`px-2 py-1 rounded-full ${
                        transaction.creditorDebit === "credit"
                          ? "bg-green-200 text-green-700"
                          : "bg-red-200 text-red-700"
                      }`}
                    >
                      {transaction.creditorDebit}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-center whitespace-nowrap capitalize">
                    {transaction.txnType}
                  </td>
                  <td className="py-3 px-6 text-center whitespace-nowrap">
                    &#x20B9;{transaction.amount}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="py-3 px-6 text-center">
                  No Transactions Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BusinessTransaction;
