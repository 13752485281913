import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  currentUser: Cookies.get("token")
    ? { username: Cookies.get("username") }
    : null,
  isAdmin: false,
  loading: false,
  error: false,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    loginError: (state) => {
      state.loading = false;
      state.error = true;
    },
    logout: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = false;
      Cookies.remove("token");
      Cookies.remove("data");

      // Clear all cookies
      const cookies = Cookies.get();
      for (const cookie in cookies) {
        Cookies.remove(cookie);
      }
    },

    //update currset user status isADmin to true
    setAdminStatus: (state) => {
      state.currentUser.isAdmin = true;
    },
  },
});

export const { loginStart, loginSuccess, loginError, logout, setAdminStatus } =
  UserSlice.actions;

export default UserSlice.reducer;
