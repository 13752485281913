import React from "react";
import Header from "../header/Header";
import DynamicSliding from "../dynamic_camp/DynamicSliding";
import Misson from "../mission/Misson";
import Faq from "../faq/Faq";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import "./homemain.css";
import ScrollToTopButton from "../ScrollToTopButton";
import DynamicConnectionSection from "../dynamic_camp/DynamicConnectionSection";
import ExploreApp from "../appsection/ExploreApp";

const HomeMain = () => {
  return (
    <div className="bg-white homemain">
      <Navbar />
      <Header />
      <div className="dynamicsection">
        <DynamicConnectionSection />
      </div>
      <div className="exploreapp">
        <ExploreApp />
      </div>
      <div>
        <DynamicSliding />
      </div>

      <div className="max-w-[880px] mx-auto py-10 empowering-sec">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between gap-8">
          <div className="mobilescreen h-[300px] md:h-[600px] border border-black border-b-4 border-r-4 rounded-md w-full md:w-[400px]"></div>
          <div className="max-w-md">
            <h1 className="text-2xl md:text-3xl font-semibold text-[#333] mb-4">
              Introducing our new App for Empowering Influencers.
            </h1>
            <p className="text-lg md:text-xl text-[#666] mb-6">
              Effortlessly forge impactful brand-influencer connections through
              our strategic approach, amplifying your brand's story with
              authentic engagements that matter.
            </p>

            <div>
              <ol className="list-decimal pl-6">
                {/* Step 01 */}
                <li className="mb-6">
                  <h2 className="text-lg font-semibold text-[#333] mb-2">
                    Invite Hand
                  </h2>
                  <p className="text-[#666]">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Minima quisquam eum tempore.
                  </p>
                </li>

                {/* Step 02 */}
                <li className="mb-6">
                  <h2 className="text-lg font-semibold text-[#333] mb-2">
                    Font Hand
                  </h2>
                  <p className="text-[#666]">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Minima quisquam eum tempore.
                  </p>
                </li>

                {/* Step 03 */}
                <li className="mb-6">
                  <h2 className="text-lg font-semibold text-[#333] mb-2">
                    Font Vertical
                  </h2>
                  <p className="text-[#666]">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Minima quisquam eum tempore.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Misson />
      </div>
      <div className="px-3 py-2">
        <Faq />
      </div>
      <div>
        <Footer />
      </div>
      <ScrollToTopButton />
    </div>
  );
};

export default HomeMain;
