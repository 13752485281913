import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const Loader = ({ loadingText }) => {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  p-8 rounded-md flex flex-col items-center justify-center z-50">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
        <p className="text-gray-800 font-semibold text-lg">{`Loading......${loadingText}`}</p>
      </div>
  );
};

export default Loader;

export const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex space-x-2 mb-4">
        <div className="w-6 h-6 bg-blue-500 rounded-full animate-bounce"></div>
        <div className="w-6 h-6 bg-green-500 rounded-full animate-bounce"></div>
        <div className="w-6 h-6 bg-yellow-500 rounded-full animate-bounce"></div>
      </div>
      <p className="text-lg font-semibold text-gray-800"> Loading....</p>
    </div>
  );
};

export const LoaderMarketplace = () => {
  const [quote, setQuote] = useState("Loading marketplace....");
  const getRandomQuote = () => {
    try {
      const quotes = [
        "Market insights incoming.",
        "Marketplace magic loading...",
        "Gathering market trends...",
        "Unveiling marketplace wisdom.",
        "Market data on its way.",
        "Revving up for analysis.",
        "Stay tuned for insights.",
        "Loading market brilliance...",
        "Marketplace insights loading...",
        "Stay curious!",
      ];

      const randomIndex = Math.floor(Math.random() * quotes.length);
      setQuote(quotes[randomIndex]);
    } catch (error) {
      toast.error("Failed to fetch a random quote.");
    }
  };

  useEffect(() => {
    // getRandomQuote();
    //change quote every 5 seconds
    const interval = setInterval(() => {
      getRandomQuote();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  p-8 rounded-md flex flex-col items-center justify-center z-50">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
      <p className="mt-4 text-gray-800">{quote}</p>
    </div>
  );
};
