import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Review from "./Component/MarketPlace/Review";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

export default function BasicModal({
  name,
  comments,
  likes,
  spent,
  enganmentRate,
  influencerId,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button className="text-green-600 font-bold" onClick={handleOpen}>{name}</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className=" flex justify-evenly mb-2">
            <div className="shadow-lg border-[1px] rounded-md border-[#cecece] w-[40%]  py-4 df flex-col">
              <h1 className="text-2xl text-gray-800 font-semibold">Engagement Rate</h1>
              <h3 className="text-green-500 font-semibold text-xl">{enganmentRate}</h3>
            </div>
            <div className="shadow-lg border-[1px] rounded-md border-[#cecece] w-[40%]  py-4 df flex-col">
              <h1 className="text-2xl text-gray-800 font-semibold">Spent</h1>
              <h3 className="text-green-400 font-semibold text-xl" >{spent}</h3>
            </div>
          </div>
          <div className=" flex justify-evenly mb-4">
            <div className="shadow-lg border border-[#cecece] w-[40%]  py-4 df flex-col">
              <h1 className="text-2xl text-gray-800 font-semibold">Likes</h1>
              <h3 className="text-green-400 font-semibold text-xl">{likes}</h3>
            </div>
            <div className="shadow-lg border border-[#cecece] w-[40%]  py-4 df flex-col">
              <h1 className="text-2xl text-gray-800 font-semibold">Comments</h1>
              <h3 className="text-green-400 font-semibold text-xl">{comments}</h3>
            </div>
          </div>
          <Review influencerId={influencerId} />
        </Box>
      </Modal>
    </div>
  );
}
